import {useMemo} from 'react'
import {PieChartDatum} from '../../../../components/charts/PieChart/PieChartSegment'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {
  PoralProductWidgetModel,
  PortalTicketsAliasProps,
} from '../../../../models/customer-portal/PortalWidgetModal'
import {PieChart} from './PieChart'
import {ProductPertotalTooltip} from './ProductPertotalTooltip'

interface DashboardProductChartProps {
  widgettcp03: PortalTicketsAliasProps[]
  products: PoralProductWidgetModel[]
}

export const DashboardProductChart = ({widgettcp03, products}: DashboardProductChartProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const pieChartDatum = useMemo((): PieChartDatum[] => {
    if (widgettcp03) {
      const items: PieChartDatum[] = products.map((item) => {
        return {
          key: item.name,
          label: item.name,
          value: item.total,
        }
      })

      return items
    }

    return []
  }, [products, widgettcp03])

  return pieChartDatum && pieChartDatum?.length > 0 ? (
    <div
      className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 px-3 pt-5'
      style={{
        height: isMobile ? '470px' : '357px',
      }}
    >
      <div className='bg-primary h-100 d-flex flex-column justify-content-center'>
        <div
          className='rounded-3 p-4 mx-auto'
          style={{
            backgroundColor: '#FFFFFFC4',
            height: isMobile ? '96%' : '90%',
            width: isMobile ? '95%' : '93%',
          }}
        >
          <div className='text-light fs-5 fw-bolder text-center mb-4'>
            A view of all of your products
          </div>
          <PieChart datum={pieChartDatum} tooltip={<ProductPertotalTooltip data={products} />} />
        </div>
      </div>
    </div>
  ) : null
}
