import {useCallback, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import PortalHeader from '../../components/Headers/PortalHeader'
import {LinksTypes} from './EventPortalDetailTickets'
import {RouteParams} from '../PortalPage'
import {PortalTicketPage} from '../PortalTicketPage'
import {DownloadTicket, GetTicketList} from '../../redux/CustomerPortalCRUD'
import {PortalTicketSeatMaps} from '../../components/SeatMap/PortalTicketSeatMap'
import {useDispatch, shallowEqual, useSelector} from 'react-redux'
import {actions} from '../../redux/CustomerPortalRedux'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {NavHeader} from '../../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {AuthNavigationSectionMenuItem} from '../../../../../config/drawer'
import {usePoratlTabs} from '../../hooks/usePortalTabs'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {ProductCategoryModel} from '../../../../models/ems/ProductCategoryModel'
import {RootState} from '../../../../../setup'
import {BannerImage} from '../../components/BannerImage'

export const PortalPageTicketDetails = () => {
  const match = useRouteMatch<RouteParams>()
  const {refreshTicketProductCategoryTabs, subTabs} = usePoratlTabs()
  const {ticketCode, productCategoryCode, tabCode} = match.params
  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]

  const history = useHistory()
  const dispatch = useDispatch()
  const {down} = useBreakpoint()
  const isiPad = useMemo(() => down('md'), [down])
  const [pdf, setPdf] = useState<string>()
  const [currentTicket, setCurrentTicket] = useState<TicketPortalModel>()

  const ticketLinks: LinksTypes = useMemo(() => {
    let links = [
      {
        title: 'Overview',
        to: `/product-category/${productCategoryCode}/${tabCode}/ticket/${ticketCode}/overview`,
      },
      // {
      //   title: 'SeatMap',
      //   to: `/product-category/${productCategoryCode}/${tabCode}/ticket/${ticketCode}/seat`,
      // },
    ]

    // if (currentTicket && !currentTicket.locationCode) {
    //   links.pop()
    // }

    return {
      links,
    }
  }, [productCategoryCode, tabCode, ticketCode])

  const getCurrentTicket = useCallback(async () => {
    const {data} = await GetTicketList({
      filters: {
        search: ticketCode,
      },
    })
    if (data && data.data && data.data.length) setCurrentTicket(data.data[0])
  }, [ticketCode])

  useEffect(() => {
    getCurrentTicket()
  }, [getCurrentTicket])

  useEffect(() => {
    dispatch(actions.tickets.search())
  }, [dispatch])

  const handleSplitPaneClose = useCallback(() => {
    history.push(`/product-category/${productCategoryCode}/${tabCode}`)
  }, [history, productCategoryCode, tabCode])

  const getPdfFile = useCallback(async () => {
    if (currentTicket) {
      const {data} = await DownloadTicket({codes: [currentTicket.code], isView: true})
      if (data) {
        const blobToUrl = URL.createObjectURL(data)
        setPdf(blobToUrl)
      }
    }
  }, [currentTicket])

  useEffect(() => {
    getPdfFile()
  }, [getPdfFile])

  const productCategorySlug = useMemo(() => {
    if (menuesRDX && menuesRDX.length > 0) {
      const found = menuesRDX.find((item) => item.code === productCategoryCode)
      return found?.slug
    }
    return null
  }, [menuesRDX, productCategoryCode])

  useOnChange(productCategorySlug, () => {
    if (productCategorySlug) refreshTicketProductCategoryTabs(productCategorySlug)
  })

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `/product-category/${productCategoryCode}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [productCategoryCode, subTabs])

  const overview = useMemo(() => {
    return isTicketOverViewShow(match) && pdf ? (
      <div
        style={{
          height: '70vh',
        }}
      >
        <embed type='application/pdf' src={`${pdf}#toolbar=0`} width='100%' height='100%' />
      </div>
    ) : null
  }, [match, pdf])

  const seats = useMemo(() => {
    return isTicketSeatShow(match) ? (
      <div
        style={{
          height: '70vh',
        }}
      >
        {<PortalTicketSeatMaps ticket={currentTicket} />}
      </div>
    ) : null
  }, [currentTicket, match])

  const innerPages = useMemo(() => {
    return currentTicket ? (
      <>
        <PortalHeader links={ticketLinks.links} />
        {seats}
        {overview}
      </>
    ) : null
  }, [currentTicket, overview, seats, ticketLinks.links])

  const ticketPage = useMemo(() => {
    return productCategoryCode && tabCode && productCategorySlug ? (
      <PortalTicketPage
        productCategorySlug={productCategorySlug}
        productCategoryCode={productCategoryCode}
        tabCode={tabCode}
      />
    ) : null
  }, [productCategoryCode, productCategorySlug, tabCode])

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <>
            <Toolbar>
              <NavHeader className='text-uppercase fs-5' menus={subMenus} />
            </Toolbar>
            <BannerImage />

            <PaneContainer className='flex-grow-1' direction='horizontal'>
              <Pane minimumWidth={isiPad ? undefined : 200} flexBasis={isiPad ? '0%' : '35%'}>
                {ticketPage}
              </Pane>
              <Pane minimumWidth={isiPad ? undefined : 200} flexBasis={isiPad ? '100%' : '65%'}>
                <MetronicIconButton
                  size='sm'
                  iconType='Navigation'
                  iconName='Arrow-from-left'
                  onClick={handleSplitPaneClose}
                  variant='primary'
                  tooltip='Close Pane'
                  className='mb-1'
                />
                {innerPages}
              </Pane>
            </PaneContainer>
          </>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export const isTicketSeatShow = (match: any): boolean => {
  return match.path.includes('/ticket/:ticketCode/seat')
}

export const isTicketOverViewShow = (match: any): boolean => {
  return match.path.includes('/ticket/:ticketCode/overview')
}
