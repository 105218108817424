import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {TableRowId} from '../../../components/tables/TableRow'
import {FilterModel} from '../../../models/FilterModel'
import {EVENT_CODE} from '../../../../config/env'
import {VoucherPortalTable} from '../components/tables/VoucherTable/VoucherPortalTable'
import {actions} from '../redux/CustomerPortalRedux'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {VoucherCardGrid} from '../components/VoucherCard.tsx/VoucherCardGrid'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {MultiSelectToggleFilterInput} from '../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'

interface PortalVoucherPageProps {
  voucherTabCode: string
  tabCode: string
  refreshCallBack?: () => void
}

export const PortalVoucherPage = ({
  voucherTabCode,
  tabCode,
  refreshCallBack,
}: PortalVoucherPageProps) => {
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {setFilter: setFilterVoucher, filters: voucherFilters} =
    useEntityFilter('customer-portal-voucher')

  const dispatch = useDispatch()
  const refreshDebounced = useDebounce(300)
  const vouchers = useRootStateSelector((state) => state.customerPortal.vouchers)
  const voucherFilterMemo = useMemo(() => voucherFilters || {}, [voucherFilters])
  const {down} = useBreakpoint()

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const refresh = useCallback(() => {
    dispatch(actions.vouchers.search())
  }, [dispatch])

  const handleTableRefresh = useCallback(() => {
    refreshCallBack?.()
    refreshDebounced(() => refresh())
  }, [refreshCallBack, refreshDebounced, refresh])

  const onFilterVoucherHandler = useCallback(
    (filter: FilterModel) => {
      setFilterVoucher({
        ...voucherFilters,
        ...filter,
        filters: {...filter?.filters},
      })
      refreshDebounced(() => refresh())
    },
    [setFilterVoucher, voucherFilters, refreshDebounced, refresh]
  )

  useOnChange(tabCode, () => {
    setFilterVoucher({
      filters: {statusPortal: tabCode, event: EVENT_CODE},
      sortOrder: 'ASC',
      limit: isMobile ? 10 : 12,
    })
    refreshDebounced(() => refresh())
  })

  const initialFilters = useMemo(() => {
    return {filters: {statusPortal: tabCode, event: EVENT_CODE}, limit: isMobile ? 10 : 12}
  }, [isMobile, tabCode])

  const advancedFilters = useMemo(() => {
    return (
      <div className='d-flex flex-wrap flex-row w-100 gap-3'>
        <MultiSelectToggleFilterInput
          className='w-200px'
          endAdornment={
            <MetronicIcon size='md' color='white' iconType='Text' iconName='Align-justify' />
          }
          field='status'
          items={VOUCHER_STATUS}
          label='Status'
          inputClassName='search-cp-field'
        />
      </div>
    )
  }, [])

  useOnChange(vouchers, () => {
    if (vouchers && !Array.isArray(vouchers)) {
      const firstRow = Object.keys(vouchers.data)[0]
      setExpandedGroups([firstRow])
    }
  })

  return (
    <>
      {down('sm') ? (
        <div className='d-sm-block d-xs-block d-md-none'>
          <VoucherCardGrid
            className='d-md-none'
            onRefresh={handleTableRefresh}
            data={vouchers}
            filters={voucherFilterMemo}
            onFilter={onFilterVoucherHandler}
            setFilter={setFilterVoucher}
            tabCode={tabCode}
            voucherTabCode={voucherTabCode}
          />
        </div>
      ) : (
        <div className='d-none d-md-block'>
          {voucherTabCode && (
            <VoucherPortalTable
              initialFilters={initialFilters}
              expandedGroups={expandedGroups}
              onExpandedGroupChange={setExpandedGroups}
              filters={voucherFilterMemo}
              data={vouchers}
              voucherTabCode={voucherTabCode}
              onFilter={onFilterVoucherHandler}
              onRefresh={handleTableRefresh}
              tabCode={tabCode}
              advancedFilters={advancedFilters}
            />
          )}
        </div>
      )}
    </>
  )
}

const VOUCHER_STATUS: SelectInputItem[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Used',
    value: 'used',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
  {
    label: 'Unassigned',
    value: 'unassigned',
  },
]
