import {useCallback, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import PortalHeader from '../../components/Headers/PortalHeader'
import {LinksTypes} from './EventPortalDetailTickets'
import {RouteParams} from '../PortalPage'
import {GetBookingList} from '../../redux/CustomerPortalCRUD'
import {useDispatch} from 'react-redux'
import {actions} from '../../redux/CustomerPortalRedux'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {PortalShipmentsPage} from '../PortalShipmentsPage'
import {PortalShipmentsTicketTable} from './PortalShipmentsTicketTable'

export const PortalPageShipmentsDetails = () => {
  const match = useRouteMatch<RouteParams>()
  const {shipmentsTabCode, shipmentCode} = match.params

  const history = useHistory()
  const dispatch = useDispatch()
  const breakpoints = useBreakpoint()
  const isMobile = useMemo(() => breakpoints.down('md'), [breakpoints])
  const [currentShipment, setCurrentShipment] = useState<BookingModel>()

  const shipmentsLinks: LinksTypes = useMemo(() => {
    let links = [
      {
        title: 'Ticket',
        to: `/shipments-tab/${shipmentsTabCode}/shipment/${shipmentCode}/ticket`,
      },
    ]

    return {
      links,
    }
  }, [shipmentCode, shipmentsTabCode])

  const getCurrentShipment = useCallback(async () => {
    const {data} = await GetBookingList({
      filters: {
        search: shipmentCode,
      },
    })
    if (data && data.data && data.data.length) setCurrentShipment(data.data[0])
  }, [shipmentCode])

  useEffect(() => {
    getCurrentShipment()
  }, [getCurrentShipment])

  useEffect(() => {
    dispatch(actions.vouchers.search())
  }, [dispatch])

  const handleSplitPaneClose = useCallback(() => {
    history.push(`/shipments-tab/${shipmentsTabCode}`)
  }, [history, shipmentsTabCode])

  const overview = useMemo(() => {
    return isShipmentTicketShow(match) ? (
      <div
        style={{
          height: '70vh',
        }}
      >
        <PortalShipmentsTicketTable
          shipmentsTabCode={shipmentsTabCode}
          shipmentCode={shipmentCode}
        />
      </div>
    ) : null
  }, [match, shipmentCode, shipmentsTabCode])

  const innerPages = useMemo(() => {
    return currentShipment ? (
      <>
        <PortalHeader links={shipmentsLinks.links} />
        {overview}
      </>
    ) : null
  }, [currentShipment, overview, shipmentsLinks.links])

  const shipmentsPage = useMemo(() => {
    return shipmentsTabCode ? <PortalShipmentsPage shipmentsTabCode={shipmentsTabCode} /> : null
  }, [shipmentsTabCode])

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <PaneContainer className='flex-grow-1' direction='horizontal'>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '0%' : '35%'}>
              {shipmentsPage}
            </Pane>
            <Pane minimumWidth={isMobile ? undefined : 200} flexBasis={isMobile ? '100%' : '65%'}>
              <MetronicIconButton
                size='sm'
                iconType='Navigation'
                iconName='Arrow-from-left'
                onClick={handleSplitPaneClose}
                variant='primary'
                tooltip='Close Pane'
                className='mb-1'
              />
              {innerPages}
            </Pane>
          </PaneContainer>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export const isShipmentTicketShow = (match: any): boolean => {
  return match.path.includes('/shipment/:shipmentCode/ticket')
}
