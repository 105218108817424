import {ChangeEvent, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../../_metronic/helpers'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {FilterModel} from '../../../models/FilterModel'
import {FilterSearchableSelectInput} from '../SearchableSelect/FilterSearchableSelectInput'
import {selectItemMapper} from '../../../utils/idExtractor'

export interface VoucherInputItemValue {
  voucher: VoucherModel | null
  count: number
  id: number | string
}

export interface VoucherInputItemProps {
  className?: string
  value: VoucherInputItemValue
  onChange: (value: VoucherInputItemValue) => void
  onSearch: (filter?: FilterModel) => void
  searchResult?: GlobalSearchModel<VoucherModel>
  selectedItems: VoucherInputItemValue[]
  placeholder: string
  label: string
  onRemove: (value: VoucherInputItemValue) => void
}

export const VoucherInputItem = ({
  searchResult,
  onChange,
  onSearch,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
}: VoucherInputItemProps) => {
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: VoucherInputItemValue = {...value, count: Number(count)}
      onChange(newValue)
    },
    [onChange, value]
  )

  const handleSelectionChange = useCallback(
    (item: VoucherModel | null) => {
      if (item) {
        onChange({...value, voucher: item})
      }
    },
    [value, onChange]
  )

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const searchInputValue = useMemo(() => {
    if (value) {
      return value.voucher
    }
    return null
  }, [value])

  const filteredSearchResult = useMemo(() => {
    if (searchResult) {
      return {
        ...searchResult,
        data: searchResult.data.filter(
          (voucher) =>
            !selectedItems.some((selectedItem) => selectedItem.voucher?.code === voucher.code)
        ),
      }
    }
  }, [searchResult, selectedItems])

  return (
    <div className={clsx('product-input-item', className)}>
      <label className='form-label'>{label}</label>
      <div className='product-input-item-input-container'>
        <div className='flex-grow-1' style={{minWidth: 0}}>
          <FilterSearchableSelectInput
            value={searchInputValue}
            itemMapper={selectItemMapper}
            searchResult={filteredSearchResult}
            placeholder={placeholder}
            onChange={handleSelectionChange}
            onFilter={onSearch}
            noMargin
          />
        </div>
        <input
          onChange={handleCountChange}
          className='product-input-item-input-container__number-input form-control form-control-solid'
          type='number'
          disabled={!value.voucher}
          value={value.count}
          min={0}
        />
        <button
          type='button'
          className='btn btn-sm btn-icon btn-active-light-primary'
          onClick={handleRemove}
        >
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </button>
      </div>
    </div>
  )
}
