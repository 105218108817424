import {useCallback, useMemo} from 'react'
import {Button} from '../Button'
import {SeatMapCanvasInputProps} from './SeatMapCanvasInput'
import {SeatMapSelectionInput} from './SeatMapSelectionInput'
import {SeatMapValue} from './SeatMapValue'
import {TreeNodeItem} from '../TreeSelect/TreeSelect'
import {ResponsiveModal} from '../../modals/ResponsiveModal'
import {MetronicIconButton} from '../MetronicIconButton'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {DateRange} from '../../../utils/DateRange'
import {DateTimePickerInput} from '../datepickers/DateTimePickerInput'

export interface SeatMapSelectionModalInputProps extends SeatMapCanvasInputProps {
  open: boolean
  onHide: () => void
  count: number
  onSubmit: () => void
  locationItems?: TreeNodeItem[]
  onLocationChange?: (value: string) => void
  locationCode?: string
  disableSubmit?: boolean
  modalTitle?: string | ((remaining: number) => string)
  loading?: boolean
  disableSelection?: boolean
  dateRange?: DateRange
  minDate?: Date
  maxDate?: Date
  onDateRangeChange?: (dateRange: DateRange) => void
}

export const SeatMapSelectionModalInput = ({
  className,
  open,
  onHide,
  count,
  value,
  onChange,
  onSubmit,
  disableSubmit,
  modalTitle,
  disableSelection,
  loading = false,
  dateRange,
  onDateRangeChange,
  minDate,
  maxDate,
  ...props
}: SeatMapSelectionModalInputProps) => {
  const remaining = useMemo(() => {
    const selectCount = value?.getValueCount() || 0
    return count - selectCount
  }, [value, count])

  const handleOnChange = useCallback(
    (values: SeatMapValue) => {
      if (!disableSelection && !loading) {
        const remaining = count - values.getValueCount()
        if (remaining >= 0) {
          onChange && onChange(values)
        }
      }
    },
    [disableSelection, loading, count, onChange]
  )

  const header = useMemo(() => {
    const title = `Please select seats: ${remaining} remaining.`
    let subtitle: string = ''
    if (modalTitle) {
      if (typeof modalTitle === 'string') {
        subtitle = modalTitle
      } else {
        subtitle = modalTitle(remaining)
      }
    }
    return (
      <div className='w-100 d-flex justify-content-between'>
        <h2>{title}</h2>
        <h2>{subtitle}</h2>
      </div>
    )
  }, [modalTitle, remaining])

  const handleDateRangeChange = useCallback(
    (startOrEnd: 'start' | 'end') => (date: Date | null) => {
      if (dateRange && onDateRangeChange) {
        const newDateRange = dateRange.clone()
        if (startOrEnd === 'start') {
          newDateRange.setStart(date)
          if (!newDateRange.getEnd()) {
            newDateRange.setEnd(date)
          }
        } else {
          newDateRange.setEnd(date)
        }
        onDateRangeChange(newDateRange)
      }
    },
    [dateRange, onDateRangeChange]
  )

  return (
    <ResponsiveModal noShowAnimation noPadding size='big' onClose={onHide} open={open}>
      <div className='h-100 p-5 d-flex flex-column'>
        <div className='d-flex'>
          <div className='px-5 pt-5 flex-grow-1'>{header}</div>
          <MetronicIconButton
            variant='text'
            size='sm'
            iconType='Navigation'
            iconName='Close'
            tooltip='Close Modal'
            onClick={onHide}
          />
        </div>
        <div className='flex-grow-1 position-relative'>
          {dateRange && (
            <div
              style={{zIndex: 1}}
              className='d-flex justify-content-end position-absolute top-0 end-0 text-start flex-wrap'
            >
              <DateTimePickerInput
                noClear
                label='Start'
                onChange={handleDateRangeChange('start')}
                value={dateRange?.getStart() || null}
                minDate={minDate}
                maxDate={maxDate}
              />
              <DateTimePickerInput
                noClear
                className='ms-1'
                label='End'
                onChange={handleDateRangeChange('end')}
                value={dateRange?.getEnd() || null}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          )}
          <SeatMapSelectionInput
            selected={value}
            className={className}
            onSelectedChange={handleOnChange}
            maxSelected={count}
            {...props}
          />
        </div>
        <div className='pt-2 d-flex flex-row-reverse'>
          <Button
            type='button'
            className='px-12'
            disabled={
              disableSubmit || loading || remaining !== 0 || (dateRange && !dateRange.hasValues())
            }
            variant='primary'
            onClick={onSubmit}
          >
            <LoadingSpinner loading={loading}>OK</LoadingSpinner>
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}
