import clsx from 'clsx'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {Paper} from '../../../../components/utils/Paper'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {PortalShareStatusColumn} from '../tables/PortalShareStatusColumn'

export interface ShipmentsTicketCardProps {
  data: TicketPortalModel
  className?: string
}

export const ShipmentsTicketCard = ({data, className}: ShipmentsTicketCardProps) => {
  return (
    <Paper className={clsx('p-5 d-flex flex-column justify-content-between', className)} rounded>
      <div className='d-flex justify-content-between'>
        <div className='d-flex flex-column'>
          <div>
            <div className='fw-bolder'>Product:</div>
            <div className='fs-6'>{data.productName}</div>
          </div>
        </div>
        <div className='d-flex flex-column align-items-end'>
          {data.status && (
            <div>
              <div className='fw-bolder'>Status:</div>
              <PortalShareStatusColumn data={data} />
            </div>
          )}
        </div>
      </div>
      <div className='mt-5 d-flex justify-content-between align-items-end'>
        <div>
          {data.share && (
            <div>
              <div className='fw-bolder'>Shared To:</div>
              <div>
                <MetronicIcon iconType='General' iconName='User' />
                {data.share.name}
              </div>
              <div>
                <MetronicIcon iconType='Communication' iconName='Mail-at' />
                {data.share.email}
              </div>
            </div>
          )}
        </div>
      </div>
    </Paper>
  )
}
