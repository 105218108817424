import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {EVENT_CODE} from '../../../../../config/env'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {DateRangeInput} from '../../../../components/inputs/DateRangeInput'
import {SelectInputItem} from '../../../../components/inputs/SelectInput'
import {QrCodeTableFilterAction} from '../../../../components/tables/actions/QrCodeTableFilterAction'
import {FilterInputContainer} from '../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {ColumnStyle} from '../../../../components/tables/constants/ColumnStyle'
import {ControlledFilterTable} from '../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {TableColumnOptions} from '../../../../components/tables/TableColumn'
import {TableRowId} from '../../../../components/tables/TableRow'
import {useTableOptions} from '../../../../components/tables/useTableOptions'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../models/FilterModel'
import {DateRange} from '../../../../utils/DateRange'
import {idExtractor} from '../../../../utils/idExtractor'
import {TimeSlotFormatter} from '../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {TicketTableGroupByFilterInput} from '../../../default/ems/components/tables/TicketTableGroupByFilterInput'
import {ShipmentsTicketCardGrid} from '../../components/ShipmentsTicketCard/ShipmentsTicketCardGrid'
import {PortalShareStatusColumn} from '../../components/tables/PortalShareStatusColumn'
import {PortalTicketProductType} from '../../components/tables/PortalTicketProductType'
import {actions} from '../../redux/CustomerPortalRedux'

interface PortalShipmentsTicketTableProps {
  shipmentsTabCode?: string
  shipmentCode?: string
}
export const PortalShipmentsTicketTable = ({shipmentCode}: PortalShipmentsTicketTableProps) => {
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {setFilter: setFilterTickets, filters: ticketFilters} =
    useEntityFilter('customer-portal-ticket')
  const refreshDebounced = useDebounce(300)
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const ticketFilterMemo = useMemo(() => ticketFilters || {}, [ticketFilters])
  const [dateRange, setDateRange] = useState<DateRange>(new DateRange())
  const dispatch = useDispatch()
  const {down} = useBreakpoint()
  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: 'ticket',
    defaults: {
      hiddenColumns: [
        'batchCode',
        'bookingCode',
        'customerName',
        'productType',
        'startedAt',
        'endedAt',
      ],
    },
  })

  const refresh = useCallback(() => {
    return dispatch(actions.tickets.search())
  }, [dispatch])

  const onFilterTicketHandler = useCallback(
    (filter: FilterModel) => {
      setFilterTickets({
        ...ticketFilters,
        ...filter,
        filters: {
          ...filter?.filters,
        },
      })
      refreshDebounced(() => refresh())
    },
    [setFilterTickets, ticketFilters, refreshDebounced, refresh]
  )

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      setDateRange(dateRange)
      onFilterTicketHandler({filters: {...ticketFilters?.filters, ...dateRange}})
    },
    [onFilterTicketHandler, ticketFilters?.filters]
  )

  useOnChange(shipmentCode, () => {
    setFilterTickets({
      filters: {
        bookingCode: shipmentCode,
        event: EVENT_CODE,
        productType: 'card',
      },
    })
    refreshDebounced(() => refresh())
  })

  const columnsMemo = useMemo(() => {
    const columns: TableColumnOptions<TicketPortalModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.code,
      },
      {
        field: 'batchCode',
        label: 'Batch',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.batchCode,
      },
      {
        field: 'bookingCode',
        label: 'Booking',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.bookingCode,
      },
      {
        field: 'customerName',
        label: 'Customer',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.customerName,
      },

      {
        field: 'locationName',
        label: 'Location',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.locationName,
      },

      {
        field: 'seatRow',
        label: 'Row',
        hideable: true,
        sortable: true,
      },
      {
        field: 'seatNo',
        label: 'Seat',
        hideable: true,
        sortable: true,
      },

      {
        field: 'productName',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productName,
      },

      {
        field: 'productType',
        label: 'Type',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalTicketProductType data={data} />,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter isTimeslot={data.isTimeslot}>{data.startedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter isTimeslot={data.isTimeslot}>{data.endedAt}</TimeSlotFormatter>
        ),
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalShareStatusColumn data={data} />,
      },
    ]
    return columns
  }, [])

  const ticketTable = useMemo(() => {
    return (
      <ControlledFilterTable<TicketPortalModel>
        onHiddenColumnsChange={setHiddenColumns}
        hiddenColumns={hiddenColumns}
        advancedFilters={
          <FilterInputContainer>
            <TicketTableGroupByFilterInput groupItems={TICKET_GROUP_ITEMS} />
            <div
              className='gap-3'
              style={{
                display: '-webkit-inline-box',
              }}
            >
              <DateRangeInput onChange={handleDateRangeChange} value={dateRange} />
            </div>
          </FilterInputContainer>
        }
        leftToolbar={<QrCodeTableFilterAction />}
        onFilter={onFilterTicketHandler}
        idExtractor={idExtractor}
        columns={columnsMemo}
        filters={ticketFilterMemo}
        searchResults={tickets}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={setExpandedGroups}
      />
    )
  }, [
    columnsMemo,
    dateRange,
    expandedGroups,
    handleDateRangeChange,
    hiddenColumns,
    onFilterTicketHandler,
    setHiddenColumns,
    ticketFilterMemo,
    tickets,
  ])

  return (
    <>
      {down('sm') ? (
        <div className='d-sm-block d-xs-block d-md-none'>
          <ShipmentsTicketCardGrid
            className='d-md-none'
            onRefresh={refresh}
            data={tickets}
            filters={ticketFilterMemo}
            onFilter={onFilterTicketHandler}
            groupedItems={TICKET_GROUP_ITEMS}
            dateOnChange={handleDateRangeChange}
            dateValue={dateRange}
            setFilter={setFilterTickets}
          />
        </div>
      ) : (
        <div className='d-none d-md-block'>{ticketTable}</div>
      )}
    </>
  )
}

const TICKET_GROUP_ITEMS: SelectInputItem[] = [
  {
    label: 'Product name',
    value: 'productName',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Ticket',
    value: 'batchId',
  },
]
