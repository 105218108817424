import {useCustomerAuth} from './useCustomerAuth'
import {useCallback, useEffect, useState} from 'react'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'
import {GetPoratlWidgets} from '../redux/CustomerPortalCRUD'

import {PortalWidgetModal} from '../../../models/customer-portal/PortalWidgetModal'

export interface useCustomerPortalWidgetOptions {
  widgets: (keyof PortalWidgetModal)[]
  eventCode?: string
}

export const useCustomerPortalWidget = ({eventCode, widgets}: useCustomerPortalWidgetOptions) => {
  const [widgetData, setWidgetData] = useState<PortalWidgetModal>({})
  const {socket, startSocket} = useWebSocket()
  const auth = useCustomerAuth()

  const resetWidgetData = useCallback(async () => {
    if (eventCode) {
      const {data} = await GetPoratlWidgets(widgets, eventCode)
      setWidgetData(data)
    }
  }, [eventCode, widgets])

  useEffect(() => {
    if (eventCode && auth) {
      const handlers = widgets.map((widget) => {
        const event = `${widget}:${auth.getUser().code}:${eventCode}`

        return {
          event,
          callback: (data: {data: PortalWidgetModal[keyof PortalWidgetModal]}) => {
            setWidgetData((widgetData) => ({...widgetData, [widget]: data.data}))
          },
        }
      })

      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })

      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [auth, eventCode, socket, widgets])

  useEffect(() => {
    resetWidgetData()
  }, [resetWidgetData])

  useEffect(() => {
    const stopSockets = startSocket()
    return () => {
      stopSockets()
    }
  }, [startSocket])

  return {data: widgetData}
}
