import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {AuthNavigationSectionMenuItem} from '../../../../../config/drawer'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {NavHeader} from '../../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {usePoratlTabs} from '../../hooks/usePortalTabs'
import {RouteParams} from '../PortalPage'
import {actions} from '../../redux/CustomerPortalRedux'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {TicketTableRowActions} from '../../components/tables/TicketTable/TicketTableRowActions'
import {useTicketTableActions} from '../../hooks/useTicketTableActions'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {EmailModal, InitialValuesProps} from '../../components/modals/EmailModal'
import {WalletModal} from '../../components/modals/WalletModal'
import {ProductCategoryModel} from '../../../../models/ems/ProductCategoryModel'
import {RootState} from '../../../../../setup'
import {EVENT_CODE} from '../../../../../config/env'
import {PortaTicketStatusActivityColumn} from '../../components/tables/PortaTicketStatusActivityColumn'
import {WhatsAppInitialValuesProps, WhatsAppModal} from '../../components/modals/WhatsAppModal'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {QrCode} from '../../../../components/utils/QrCode'
import {dayColor} from '../../components/TicketCard/TicketCard'
import {useIsGSSSPP} from '../../hooks/useIsGSSSPP'
import {useDay} from '../../hooks/useDay'

export const PortalTicketDetailsCard = () => {
  const {
    params: {ticketCode, productCategoryCode, tabCode},
  } = useRouteMatch<RouteParams>()
  const {setFilter: setFilterTickets} = useEntityFilter('customer-portal-ticket')
  const refreshDebounced = useDebounce(300)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)
  const refreshTabsDebounced = useDebounce(300)
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const dispatch = useDispatch()
  const {refreshTicketProductCategoryTabs, subTabs, routeName} = usePoratlTabs()
  const history = useHistory()
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWalletModel, setOpenWalletModel] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState<TicketPortalModel[]>()

  const {getDayThreeLetters} = useDay()

  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]

  const productCategorySlug = useMemo(() => {
    if (menuesRDX && menuesRDX.length > 0) {
      const found = menuesRDX.find((item) => item.code === productCategoryCode)
      return found?.slug
    }
    return null
  }, [menuesRDX, productCategoryCode])

  const refresh = useCallback(async () => {
    dispatch(actions.tickets.search())
    if (productCategorySlug)
      refreshTabsDebounced(() => refreshTicketProductCategoryTabs(productCategorySlug))
  }, [dispatch, productCategorySlug, refreshTabsDebounced, refreshTicketProductCategoryTabs])

  useEffect(() => {
    refreshDebounced(() => refresh())
  }, [productCategorySlug, refresh, refreshDebounced, refreshTicketProductCategoryTabs])

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `${routeName}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [routeName, subTabs])

  useOnChange(tabCode, () => {
    setFilterTickets({
      filters: {
        search: ticketCode,
      },
    })
    refreshDebounced(() => refresh())
  })

  const handleGoBack = useCallback(() => {
    history.replace(`/product-category/${productCategoryCode}/${tabCode}`)
    setFilterTickets({})
  }, [history, productCategoryCode, setFilterTickets, tabCode])

  const {isKeyLoading, handleShareByEmailBulk, handleShareByWhatsAppBulk, addToWallet} =
    useTicketTableActions({onRefresh: refresh})

  // const handleOnView = useCallback(
  //   (ticket: TicketPortalModel) => {
  //     history.replace(
  //       `/product-category/${ticket?.productCategoryCode}/${tabCode}/ticket/${ticket?.code}/overview`
  //     )
  //   },
  //   [history, tabCode]
  // )

  const {isGCCSPP} = useIsGSSSPP({productCategoryCode})

  const handleOnWallet = useCallback((data: TicketPortalModel) => {
    setSelectedTickets([data])
    setOpenWalletModel(true)
  }, [])

  const handleOnShareByEmail = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenEmailModel(true)
  }, [])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (values) {
        if (selectedTickets) {
          const response = await handleShareByEmailBulk(values, selectedTickets)
          if (response) return response
        }
      }
    },
    [handleShareByEmailBulk, selectedTickets]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialValuesProps) => {
      if (values) {
        if (selectedTickets) {
          const response = await handleShareByWhatsAppBulk(values, selectedTickets)
          if (response) return response
        }
      }
    },
    [selectedTickets, handleShareByWhatsAppBulk]
  )

  const handleSubmitAddtoWallet = useCallback(
    async (ticket: TicketPortalModel) => {
      if (ticket && EVENT_CODE) {
        const response = await addToWallet(ticket, EVENT_CODE)
        if (response) {
          setOpenWalletModel(false)
        }
        history.push(`/product-category/${productCategoryCode}/added-to-wallet`)
      }
    },
    [addToWallet, history, productCategoryCode]
  )

  useOnChange(openEmailModel, () => {
    if (!openEmailModel) setSelectedTickets(undefined)
  })

  useOnChange(openWalletModel, () => {
    if (!openWalletModel) setSelectedTickets(undefined)
  })

  const day = useMemo(() => {
    if (tickets && Array.isArray(tickets.data) && tickets.data.length) {
      return getDayThreeLetters(tickets.data[0].startedAt, tickets.data[0].endedAt)
    }
    return null
  }, [getDayThreeLetters, tickets])

  const details = useMemo(() => {
    if (tickets && Array.isArray(tickets.data) && tickets.data.length) {
      const currentTicket = tickets.data[0]
      return (
        <div className='d-flex flex-column gap-5 my-5 fs-4 justify-content-between'>
          {currentTicket?.share?.name && (
            <div>
              <span className='text-white fw-bold me-2'>Shared With:</span>
              <span className='text-uppercase'>{currentTicket.share.name}</span>
            </div>
          )}
          {currentTicket?.share?.type && (
            <div className='d-flex'>
              <span className='text-white fw-bold me-2'>Shared By:</span>
              {currentTicket.share.type === 'whatsapp' ? (
                <div className='d-flex align-items-center text-uppercase'>
                  <MetronicIconButton
                    variant='text'
                    color='white'
                    disabled
                    size='sm'
                    iconType='Communication'
                    iconName='Call'
                    className='h-auto'
                  />
                  WhatApp
                </div>
              ) : currentTicket.share.type === 'email' ? (
                <div className='d-flex align-items-center text-uppercase'>
                  <MetronicIconButton
                    variant='text'
                    color='white'
                    size='sm'
                    disabled
                    iconType='Communication'
                    iconName='Mail'
                    className='h-auto'
                  />
                  Email
                </div>
              ) : null}
            </div>
          )}

          {(currentTicket?.share?.email || currentTicket?.share?.mobile) && (
            <div>
              <span className='text-white fw-bold me-2'>Email/Mobile No:</span>
              <span className='text-uppercase'>{`${
                currentTicket?.share?.email ? currentTicket.share.email : ''
              }${currentTicket?.share?.email && currentTicket?.share.mobile ? ' | ' : ''}${
                currentTicket?.share?.mobile ? currentTicket.share.mobile : ''
              }`}</span>
            </div>
          )}

          <div>
            <span className='text-white fw-bold me-2'>Product:</span>
            <span className='text-uppercase'>
              {!isGCCSPP && day === 'Thu' ? 'Pitlane Walk' : currentTicket?.productName}
            </span>
          </div>

          {currentTicket?.locationSlug && (
            <div>
              <span className='text-white fw-bold me-2'>Section:</span>
              <span className='text-uppercase'>{currentTicket.locationSlug}</span>
            </div>
          )}

          {currentTicket?.seatRow && (
            <div className='me-2'>
              <span className='text-white fw-bold me-2'>Seat Row:</span>
              <span className='text-uppercase'>{currentTicket.seatRow}</span>
            </div>
          )}

          {currentTicket?.seatNo && (
            <div>
              <span className='text-white fw-bold me-2'>Seat NO:</span>
              <span className='text-uppercase'>{currentTicket.seatNo}</span>
            </div>
          )}

          <div>
            <span className='text-white fw-bold me-2'>Reservation:</span>
            <span className='text-uppercase'>
              {currentTicket?.bookingReservationNo || currentTicket?.bookingCode}
            </span>
          </div>

          {currentTicket?.batchId && (
            <div>
              <span className='text-white fw-bold me-2'>Ticket ID:</span>
              <span className='text-uppercase'>{currentTicket.batchId}</span>
            </div>
          )}

          <div>
            <span className='text-white fw-bold me-2'>Active status:</span>
            <span className='text-uppercase'>
              {currentTicket && <PortaTicketStatusActivityColumn data={currentTicket} />}
            </span>
          </div>
        </div>
      )
    }
    return null
  }, [day, isGCCSPP, tickets])

  const handleOnShareWhatsApp = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenWhatsAppModel(true)
  }, [])

  return (
    <div>
      <Toolbar>
        <NavHeader className='text-uppercase fs-5' menus={subMenus} />
      </Toolbar>

      <div className='card p-5'>
        <div className='d-flex justify-content-between'>
          <Button
            size='sm'
            variant='default'
            color='white'
            onClick={handleGoBack}
            uppercase={false}
          >
            <MetronicIcon size='md' iconName='Arrow-left' iconType='Navigation' />
          </Button>
          <div
            className='text-uppercase ff-admm-bold'
            style={{
              color: day ? dayColor(day) : 'white',
              fontSize: '25px',
            }}
          >
            {day}
          </div>
        </div>
        <div className='mt-5 d-flex justify-content-between'>
          <div className='d-flex flex-column justify-content-center'>
            <p
              className='ff-admm-bold text-white mb-0'
              style={{
                fontSize: '30px',
              }}
            >
              Ticket
            </p>
          </div>
        </div>
        {details}
        <div className='my-5 text-center justify-content-center d-flex'>
          {tickets && Array.isArray(tickets.data) && tickets.data.length > 0 && (
            <div
              className='p-3 rounded'
              style={{
                backgroundColor: 'white',
              }}
            >
              <QrCode
                size={150}
                value={`https://bit.ly/3CrzOX9?q=${
                  tickets.data[0].barcode || tickets.data[0].code
                }`}
              />
            </div>
          )}
        </div>
        <div className='table-action-container mt-3 mb-3'>
          <div className='w-100'>
            <div className='d-flex gap-5 justify-content-center'>
              {tickets && Array.isArray(tickets.data) && tickets.data.length && (
                <TicketTableRowActions
                  loading={isKeyLoading(tickets.data[0].code)}
                  data={tickets.data[0]}
                  onShareByEmail={handleOnShareByEmail}
                  onWallet={handleOnWallet}
                  onShareByWahtsApp={handleOnShareWhatsApp}
                  iconWidth='33px'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedTickets && (
        <EmailModal
          data={selectedTickets}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}

      {selectedTickets && (
        <WhatsAppModal
          data={selectedTickets}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}

      {selectedTickets && (
        <WalletModal
          data={selectedTickets}
          setOpen={setOpenWalletModel}
          open={openWalletModel}
          onSubmit={handleSubmitAddtoWallet}
        />
      )}
    </div>
  )
}
