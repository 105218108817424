import {Link} from 'react-router-dom'
import {BookingModel} from '../../../../../models/ems/BookingModel'

interface ShipmentsCodeColumnProps {
  data: BookingModel
  shipmentsTabCode?: string
}
export const ShipmentsCodeColumn = ({data, shipmentsTabCode}: ShipmentsCodeColumnProps) => {
  if (shipmentsTabCode) {
    return (
      <Link to={`/shipments-tab/${shipmentsTabCode}/shipment/${data.code}/ticket`}>
        {data.code}
      </Link>
    )
  }

  return null
}
