import axios, {AxiosError, AxiosResponse} from 'axios'
import {ActivityModel, ActivityModelCreateParams} from '../../../../models/ems/ActivityModel'
import {
  BookingModel,
  BookingModelAddress,
  BookingModelCreateParams,
} from '../../../../models/ems/BookingModel'
import {BundleModel, BundleModelCreateParams} from '../../../../models/ems/BundleModel'
import {EventModel, EventModelType} from '../../../../models/ems/EventModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/ems/ProductCategoryModel'
import {
  BookingProductListModel,
  ProductModel,
  ProductModelCreateParams,
} from '../../../../models/ems/ProductModel'
import {TicketLogModel} from '../../../../models/acs/TicketLogModel'
import {
  TicketListModel,
  TicketModel,
  TicketModelChangeSeatParams,
  TicketModelFulfillParams,
  TicketModelUpdateParams,
} from '../../../../models/ems/TicketModel'
import {
  PollQuestionModel,
  PollQuestionModelCreateParams,
} from '../../../../models/eva/PollQuestionModel'
import {WidgetModel} from '../../../../models/ems/WidgetModel'
import {WidgetModel as WidgetFandBModel} from '../../../../models/fnb/WidgetModel'
import {
  ReservationItemModel,
  ReservationModel,
  ReservationModelCreateParams,
} from '../../../../models/ems/ReservationModel'
import {CustomerModel} from '../../../../models/CustomerModel'
import {AramexShipmentStatusModel} from '../../../../models/AramexShipmentStatusModel'
import {DigitalWaiverModel} from '../../../../models/ems/DigitalWaiverModel'
import {DigitalWaiverLogModel} from '../../../../models/ems/DigitalWaiverLogModel'
import {TicketWaiverModel} from '../../../../models/digital-waiver/TicketWaiverModel'

const CRUD_BOOKING = '/booking'
const CRUD_EVENT = '/event'
const CRUD_ACTIVITY = '/event/activity'
const CRUD_PRODUCT = '/product'
const CRUD_BUNDLE = '/bundle'
const CRUD_RESERVATION = '/reservation'
const CRUD_PRODUCT_CATEGORY = '/product-category'
const CRUD_TICKET = '/ticket'
const EMS_SEARCH_BOOKING = '/booking/list'
const EMS_SEARCH_BOOKING_PRODUCT = '/booking/product'
const EMS_SEARCH_BOOKING_BUNDLE = '/booking/bundles'
const EMS_SEARCH_EVENT = '/event/list'
const EMS_SEARCH_ACTIVITY = '/event/activity/list'
const EMS_SEARCH_BUNDLE = `${CRUD_BUNDLE}/list`
const EMS_SEARCH_PRODUCT = '/product/list'
const EMS_SEARCH_PRODUCT_TREE = '/product/tree'
const EMS_SEARCH_PRODUCT_CATEGORY = '/product-category/list'

const DeleteBookings = (codes: string[]) => axios.delete(CRUD_BOOKING, {data: {codes}})
const CancelBooking = (bookingCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_BOOKING}/cancel`, {reason, codes: bookingCodes})
const PrintBookingCard = (bookingCodes: string[]) =>
  axios.post<Blob>(
    `/ticket/booking/print-card`,
    {codes: bookingCodes},
    {
      responseType: 'blob',
    }
  )
const ShipBooking = (bookingCode: string) =>
  axios.post<Blob>(
    `/booking/ship`,
    {code: bookingCode},
    {
      responseType: 'blob',
    }
  )
const SendBooking = (bookingCodes: string[]) =>
  axios.post<unknown>(`/ticket/booking/email-ticket`, {codes: bookingCodes})
const DeleteEvents = (codes: string[]) => axios.delete(CRUD_EVENT, {data: {codes}})
const DeleteProducts = (codes: string[]) => axios.delete(CRUD_PRODUCT, {data: {codes}})
const DeleteBundles = (codes: string[]) => axios.delete(CRUD_BUNDLE, {data: {codes}})
const DeleteProductCategories = (codes: string[]) =>
  axios.delete(CRUD_PRODUCT_CATEGORY, {data: {codes}})
const DeleteActivities = (codes: string[]) => axios.delete(CRUD_ACTIVITY, {data: {codes}})

const ReleaseReservation = (reservationCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_RESERVATION}/release`, {reason, codes: reservationCodes})

const GetBookingAirwayBill = (airwayBill: string) =>
  axios.get<Blob>(`/file/${airwayBill}`, {responseType: 'blob'})
const GetBookings = (filter?: FilterModel) => axios.post(EMS_SEARCH_BOOKING, filter)
const BookingSendPrint = (bookingCode: string) =>
  axios.get<Blob>(`/ticket/booking/${bookingCode}/send-print`, {
    responseType: 'blob',
  })
const GetBookingByCode = (code: string) => axios.get<BookingModel>(`${CRUD_BOOKING}/${code}`)
const GetEventsTree = () => axios.get<EventModel[]>(`${CRUD_EVENT}/tree`)
const GetEventParents = () => axios.get<EventModel[]>(`${CRUD_EVENT}/parent`)
const GetBookingProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`${EMS_SEARCH_BOOKING_PRODUCT}`, filter)
const GetBookingDetails = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingProductListModel>>(`/booking/detail`, filter)
const GetBookingBundles = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BundleModel>>(`${EMS_SEARCH_BOOKING_BUNDLE}`, filter)
const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(EMS_SEARCH_EVENT, filter)
const GetEventTypes = () => axios.get<EventModelType[]>(`/event/type`)
const GetEventsAndActivities = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`${EMS_SEARCH_EVENT}/all`, filter)
const GetActivities = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ActivityModel>>(EMS_SEARCH_ACTIVITY, filter)
const GetBundles = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BundleModel>>(EMS_SEARCH_BUNDLE, filter)
const GetBundleByCode = (code: string) => axios.get<BundleModel>(`${CRUD_BUNDLE}/${code}`)
const GetEventByCode = (code: string) => axios.get<EventModel>(`${CRUD_EVENT}/${code}`)
const GetDefaultEvent = (): Promise<AxiosResponse<EventModel | null>> =>
  axios.get<EventModel>(`${CRUD_EVENT}/default`).catch((e: AxiosError<EventModel>) => {
    if (e.response?.status === 400) {
      const response: AxiosResponse<EventModel | null> = e.response
      response.data = null
      return response
    }
    throw e
  })
const GetEventProducts = (eventCode: string) =>
  axios.post<{products: ProductModel[]; bundles: BundleModel[]}>(
    `${CRUD_EVENT}/${eventCode}/product`
  )

const GetEventNonSeatedProducts = (eventCode: string, filter?: FilterModel) =>
  axios.post<{products: ProductModel[]}>(`${CRUD_EVENT}/${eventCode}${CRUD_PRODUCT}`, filter)
const GetActivityNonSeatedProducts = (eventCode: string, filter?: FilterModel) =>
  axios.post<{products: ProductModel[]}>(`${CRUD_ACTIVITY}/${eventCode}${CRUD_PRODUCT}`, filter)

const SetEventAsDefault = (code: string) => axios.post(`${CRUD_EVENT}/${code}`)

const GetActivityByCode = (code: string) => axios.get<ActivityModel>(`${CRUD_ACTIVITY}/${code}`)
const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(EMS_SEARCH_PRODUCT, filter)
const GetProductTree = (filter?: FilterModel) =>
  axios.post<ProductModel[]>(EMS_SEARCH_PRODUCT_TREE, filter)
const GetProductByCode = (code: string) => axios.get(`${CRUD_PRODUCT}/${code}`)
const GetProductCategories = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(EMS_SEARCH_PRODUCT_CATEGORY, filter)
const GetProductCategoryTree = (filter?: FilterModel) =>
  axios.post<ProductCategoryModel[]>(`${CRUD_PRODUCT_CATEGORY}/tree`, filter)
const GetParentProductsByProductCategory = (productCategoryCode: string) =>
  axios.get<ProductModel[]>(`${CRUD_PRODUCT_CATEGORY}/${productCategoryCode}/product`)
const GetProductCategoryByCode = (code: string) =>
  axios.get<ProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code}`)
const GetReservations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationModel>>(`reservation/list`, filter)
const GetReservationByCode = (code: string) => axios.get(`/reservation/${code}`)
const GetReservationItems = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationItemModel>>(`reservation/details`, filter)

const PostBooking = (data: BookingModelCreateParams) => axios.post(CRUD_BOOKING, data)
const PostEvent = (data: FormData) => axios.post<EventModel>(CRUD_EVENT, data)
const PostActivity = (data: ActivityModelCreateParams) =>
  axios.post<ActivityModel>(CRUD_ACTIVITY, data)
const PostProduct = (data: ProductModelCreateParams) => axios.post(`${CRUD_PRODUCT}`, data)
const PostBundle = (data: BundleModelCreateParams) => axios.post(`${CRUD_BUNDLE}`, data)
const PostProductCategory = (data: ProductCategoryModelCreateParams) =>
  axios.post(`${CRUD_PRODUCT_CATEGORY}`, data)
const PostReservation = (data: ReservationModelCreateParams) => axios.post(CRUD_RESERVATION, data)

const PutBooking = (data: BookingModelCreateParams, code: string) =>
  axios.put(`${CRUD_BOOKING}/${code}`, data)
const PutEvent = (data: FormData, code: string) =>
  axios.put<EventModel>(`${CRUD_EVENT}/${code}`, data)
const PutActivity = (data: ActivityModelCreateParams, code: string) =>
  axios.put<ActivityModel>(`${CRUD_ACTIVITY}/${code}`, data)
const PutProduct = (data: ProductModelCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT}/${code}`, data)
const PutBundle = (data: BundleModelCreateParams, code: string) =>
  axios.put(`${CRUD_BUNDLE}/${code}`, data)
const PutProductCategory = (data: ProductCategoryModelCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT_CATEGORY}/${code}`, data)

const GetTickets = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketListModel>>(`${CRUD_TICKET}/list`, filter)
const GetTicketByCode = (ticketCode: string) =>
  axios.get<TicketModel>(`${CRUD_TICKET}/${ticketCode}`)
const FulfillTicket = (data: TicketModelFulfillParams) =>
  axios.post<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/fulfill`, data)
const FulfillNonSeated = (
  bookingProductCode: string,
  timeslots?: Array<{startedAt: string; endedAt: string}>
) =>
  axios.post<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/fulfill/non-seated`, {
    productCode: bookingProductCode,
    timeslots,
  })
const FulfillVoucher = (bookingProductCode: string) =>
  axios.post(`/voucher/fulfill`, {bookingProductCode})
const PutTicket = (data: TicketModelUpdateParams, code: string) =>
  axios.put<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/${code}`, data)
const CancelTickets = (ticketCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_TICKET}/cancel`, {
    codes: ticketCodes,
    reason,
  })
const ChangeTicketSeat = (ticketCode: string, payload: TicketModelChangeSeatParams) =>
  axios.put<Blob>(`${CRUD_TICKET}/${ticketCode}/change-seat`, payload, {
    responseType: 'blob',
  })
const ReprintTickets = (ticketCodes: string[], reason: string) =>
  axios.post<Blob>(
    `/template/reprint/tickets`,
    {
      codes: ticketCodes,
      reason,
    },
    {
      responseType: 'blob',
    }
  )

const ReplaceTickets = (ticketCodes: string[], reason: string) =>
  axios.post<Blob>(
    `/template/replace/tickets`,
    {
      codes: ticketCodes,
      reason,
    },
    {
      responseType: 'blob',
    }
  )
const PrintTickets = (ticketCodes: string[]) =>
  axios.post<Blob>(
    `/template/print/tickets`,
    {
      codes: ticketCodes,
    },
    {
      responseType: 'blob',
    }
  )
const ActivateTicket = (code: string, rfid?: string) => {
  if (rfid) {
    return axios.post<unknown>(`${CRUD_TICKET}/activate-card`, {
      code,
      rfid,
    })
  }
  return axios.put<unknown>(`${CRUD_TICKET}/activate/${code}`, {
    code,
    rfid,
  })
}

const DownloadTicketQrCode = (ticketCode: string) =>
  axios.get<Blob>(`${CRUD_TICKET}/${ticketCode}/print-label`, {
    responseType: 'blob',
  })
const GetTicketLogs = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketLogModel>>(`/log/ticket`, filter)

const GetPollQuestionList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<PollQuestionModel>>('/poll-question/list', filters)
const GetPollQuestionByCode = (questionCode: string) =>
  axios.get<PollQuestionModel>(`/poll-question/${questionCode}`)
const CreatePollQuestion = (params: PollQuestionModelCreateParams) =>
  axios.post(`/poll-question`, params)
const UpdatePollQuestion = (questionCode: string, params: PollQuestionModelCreateParams) =>
  axios.put(`/poll-question/${questionCode}`, params)
const DeletePollQuestions = (questionCodes: string[]) =>
  axios.delete(`/poll-question`, {
    data: {
      codes: questionCodes,
    },
  })
const GetWidgets = async ({
  date,
  eventCode,
  widgetCodes,
  month,
  year,
  dateFrom,
  dateTo,
}: GetWidgetsParam) =>
  axios.post<WidgetModel>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
    date,
    month,
    year,
    dateFrom,
    dateTo,
  })
const GetDigitalWaiverWidgets = async ({eventCode, widgetCodes, filters}: GetDigitalWidgetsParam) =>
  axios.post<WidgetModel>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
    filters,
  })

const TriggerBadgeCollectionScanner = (eventCode: string, gateCode: string, isStart: boolean) =>
  axios.post(`/badge-collection`, {
    eventCode,
    gateCode,
    isStart,
  })
const ScanBadgeCollection = (eventCode: string, qrCode: string) =>
  axios.post<CustomerModel>(`/badge-collection/customer`, {
    customerCode: qrCode,
    eventCode,
  })
const ClaimBadge = (data: FormData) => axios.post(`/badge-collection/claim`, data)
const AssignGuestSlot = (guestCode: string, slot: string) =>
  axios.post(`/guest/assign-slot/${guestCode}`, {slot})

const AddConfigGroupToEvent = (
  eventCode: string,
  configGroupCode: string,
  isActive: boolean = false
) =>
  axios.post(`/event/config-group/${eventCode}`, {
    configGroupCode,
    isActive: isActive,
  })
const RemoveConfigGroupFromEvent = (eventCode: string, configGroupCode: string) =>
  axios.delete(`/event/config-group/${eventCode}`, {
    data: {
      configGroupCode,
    },
  })
const CheckEventHasActiveConfigGroupType = async (eventCode: string, configTypeCode: string) => {
  const {data} = await axios.post<{count: number}>(
    `/event/config-group/active/count/${eventCode}`,
    {
      typeCode: configTypeCode,
    }
  )
  return data.count > 0
}
const SyncRoyalGuard = (eventCode: string, tokens: string[]) =>
  axios.post(`/guest/royal-guard/refresh`, {
    eventCode,
    codes: tokens,
  })
const GetAirwayBillData = (awb: string) =>
  axios.get<AramexShipmentStatusModel[]>(`/booking/track/${awb}`)
const CreateOrUpdateBookingAddress = (bookingCode: string, address: BookingModelAddress) =>
  axios.post(`/booking/address/${bookingCode}`, address)

const ExportOutlet = (payload: ExportOutletPayload) =>
  axios.post(`/outlet/global-report`, payload, {responseType: 'blob'})

const SearchDigitalWaivers = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketWaiverModel>>(`/digital-waiver/list`, filter)
const DownloadDigitalWaiverPdf = (code: string) =>
  axios.get<Blob>(`/digital-waiver/download-pdf/${code}`, {responseType: 'blob'})
const GetDigitalWaiverByCode = (code: string) =>
  axios.get<DigitalWaiverModel>(`/digital-waiver/${code}`)
const GetDigitalWaiverViewByCode = (code: string) =>
  axios.get<TicketWaiverModel>(`/digital-waiver/view/${code}`)
const ApproveDigitalWaiver = (code: string) =>
  axios.post(`/digital-waiver/update-status`, {
    digitalWaiverCode: code,
    status: 'approved',
  })
const RejectDigitalWaiver = (code: string, reason: string) =>
  axios.post(`/digital-waiver/update-status`, {
    digitalWaiverCode: code,
    status: 'rejected',
    reason,
  })
const ReviewDigitalWaiver = (code: string, reason: string) =>
  axios.post(`/digital-waiver/update-status`, {
    digitalWaiverCode: code,
    status: 'review',
    reason,
  })
const UpdateTicketGarageOrCarNumber = (ticketCode: string, garageOrCarNumber: string) =>
  axios.put<TicketModel>(`/ticket/${ticketCode}`, {lotNo: garageOrCarNumber})
const UpdateTicketEbesRfid = (ticketCode: string, rfid: string | null) =>
  axios.put(`/ticket/${ticketCode}`, {rfid})
const ExportDigitalWaiver = (columns: string[], filters?: FilterModel) =>
  axios.post(`/digital-waiver/export`, {...filters, columns}, {responseType: 'blob'})
const ExportDigitalWaiverLicenses = (from: string, to: string) =>
  axios.post(
    `/digital-waiver/export-license`,
    {
      filters: {
        dateFrom: from,
        dateTo: to,
      },
    },
    {responseType: 'blob'}
  )
const SearchDigitalWaiverTicketLogs = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<DigitalWaiverLogModel>>(`/digital-waiver/dw-logs`, filter)

const GetDigitalWaiverLogs = (ticketCode?: string) =>
  axios.get<DigitalWaiverLogModel[]>(`/digital-waiver/dw-logs/${ticketCode}`)

export interface ExportOutletPayload {
  eventCode: string
  startedAt?: string
  endedAt?: string
}

interface GetDigitalWidgetsParam {
  widgetCodes: (keyof WidgetModel)[]
  eventCode?: string
  filters: {
    search?: string
    dateFrom?: string
    dateTo?: string
    productCode?: string
    status?: string[]
    productCategorySlug?: string
    productDigitalWaiverType?: string
  }
}

interface GetWidgetsParam {
  widgetCodes: (keyof WidgetModel)[]
  eventCode?: string
  dateFrom?: string
  dateTo?: string
  date?: string
  month?: number
  year?: number
}

const GetFandBWidgetData = ({
  widgets,
  eventCode,
  date,
  outletCode,
}: {
  widgets: string[]
  eventCode: string
  date: string
  outletCode?: string
}) =>
  axios.post<WidgetFandBModel>('/widget/fnb', {
    event: eventCode,
    outlet: outletCode,
    date,
    widgets,
  })

export {
  ShipBooking,
  DeleteBookings,
  DeleteEvents,
  DeleteProducts,
  DeleteProductCategories,
  DeleteBundles,
  SendBooking,
  PrintBookingCard as PrintBooking,
  GetBookings,
  GetBookingByCode,
  GetBookingProducts,
  GetBookingDetails,
  GetBookingBundles,
  GetBundles,
  GetBundleByCode,
  GetEventsAndActivities,
  GetEvents,
  GetEventsTree,
  GetEventParents,
  GetEventProducts,
  GetEventNonSeatedProducts,
  GetActivityNonSeatedProducts,
  GetEventByCode,
  GetEventTypes,
  GetDefaultEvent,
  SetEventAsDefault,
  GetProducts,
  GetProductTree,
  GetProductByCode,
  GetProductCategoryTree,
  GetProductCategories,
  GetProductCategoryByCode,
  GetParentProductsByProductCategory,
  GetReservations,
  GetReservationByCode,
  GetReservationItems,
  PostBooking,
  PostEvent,
  PostProduct,
  PostProductCategory,
  PostBundle,
  PostReservation,
  PutBooking,
  PutEvent,
  PutProduct,
  PutProductCategory,
  PutBundle,
  GetActivities,
  GetActivityByCode,
  PostActivity,
  PutActivity,
  DeleteActivities,
  GetTickets,
  GetTicketByCode,
  FulfillTicket,
  ActivateTicket,
  DownloadTicketQrCode,
  ChangeTicketSeat,
  PutTicket,
  CancelTickets,
  ReprintTickets,
  PrintTickets,
  BookingSendPrint,
  FulfillNonSeated,
  FulfillVoucher,
  GetTicketLogs,
  CancelBooking,
  GetBookingAirwayBill,
  GetPollQuestionByCode,
  GetPollQuestionList,
  CreatePollQuestion,
  UpdatePollQuestion,
  DeletePollQuestions,
  GetWidgets,
  GetFandBWidgetData,
  ReleaseReservation,
  TriggerBadgeCollectionScanner,
  ClaimBadge,
  AssignGuestSlot,
  AddConfigGroupToEvent,
  RemoveConfigGroupFromEvent,
  CheckEventHasActiveConfigGroupType,
  SyncRoyalGuard,
  ScanBadgeCollection,
  GetAirwayBillData,
  CreateOrUpdateBookingAddress,
  ReplaceTickets,
  ExportOutlet,
  SearchDigitalWaivers,
  GetDigitalWaiverByCode,
  ApproveDigitalWaiver,
  RejectDigitalWaiver,
  UpdateTicketGarageOrCarNumber,
  UpdateTicketEbesRfid,
  ExportDigitalWaiver,
  ExportDigitalWaiverLicenses,
  SearchDigitalWaiverTicketLogs,
  DownloadDigitalWaiverPdf,
  ReviewDigitalWaiver,
  GetDigitalWaiverWidgets,
  GetDigitalWaiverLogs,
  GetDigitalWaiverViewByCode,
}
