import {ReactNode, useCallback, useMemo, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useCustomerAuth} from '../../modules/customer-portal/hooks/useCustomerAuth'
import {AuthPage} from '../../modules/customer-portal/pages/AuthPage'
import {FullPage403} from '../common/FullPage403'
import {FullPage404} from '../common/FullPage404'
import {Logout} from '../common/Logout'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthNavigationSectionMenuItem} from '../../../config/portalDrawer'
import {AuthPortalHeaderThumbnail} from '../../modules/customer-portal/components/AuthPortalHeaderThumbnail'
import {HeaderControls} from '../../components/layouts/DefaultLayout/Header/HeaderControls'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {actions} from '../../modules/customer-portal/redux/CustomerPortalTabsRedux'
import {RootState} from '../../../setup'
import {ProductCategoryModel} from '../../models/ems/ProductCategoryModel'
import {useEntityFilter} from '../../components/hooks/useEntityFilter'
import {EVENT_CODE} from '../../../config/env'
import {useLocation} from 'react-router'
import {useLayoutHelpers} from '../../components/layouts/DefaultLayout/useLayoutHelpers'
import {CustomerPortalLayout} from '../../components/layouts/DefaultLayout/CustomerPortalLayout'
import {PortalDownloadTicketByWhatsApp} from '../../modules/customer-portal/pages/PortalDownloadTicketByWhatsApp'
import {PortalDownloadVoucherByWhatsApp} from '../../modules/customer-portal/pages/PortalDownloadVoucherByWhatsApp'
export const Routes = () => {
  const auth = useCustomerAuth()
  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const {setFilter} = useEntityFilter('customer-portal-product-catergories-tabs')

  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]

  useEffect(() => {
    setFilter({
      filters: {eventCode: EVENT_CODE},
    })
  }, [setFilter])

  const getProductsAndVouchers = useCallback(async () => {
    dispatch(actions.topTabs.search())
  }, [dispatch])
  const {isRouteActive} = useLayoutHelpers()

  const menus = useMemo(() => {
    if (menuesRDX) {
      const products_Cateory: AuthNavigationSectionMenuItem[] = [
        {
          title: 'Dashboard',
          to: '/dashboard',
          icon: `/media/svg/portal-tabs/${
            isRouteActive('/dashboard') ? 'dashboard-white.png' : 'dashboard-white.png'
          }`,
        },
      ]

      menuesRDX.forEach((productCategory) => {
        if (productCategory.code === 'VOUCHERS') {
          const to = new RegExp(`^\\/voucher-tab\\/${productCategory.code}\\/[\\w-]+$`).test(
            pathname
          )
            ? pathname
            : `/voucher-tab/${productCategory.code}`

          products_Cateory.push({
            title: productCategory.name,
            to,
            icon: `/media/svg/portal-tabs/${
              isRouteActive(to) ? 'voucher-white.png' : 'voucher-white.png'
            }`,
          })
        } else if (productCategory.code === 'SHIPMENTS') {
          const to = new RegExp(`^\\/voucher-tab\\/${productCategory.code}\\/[\\w-]+$`).test(
            pathname
          )
            ? pathname
            : `/shipments-tab/${productCategory.code}`
          products_Cateory.push({
            title: productCategory.slug ? productCategory.slug : '',
            to,
          })
        } else {
          if (productCategory.slug) {
            const to = new RegExp(`^\\/product-category\\/${productCategory.code}\\/[\\w-]+$`).test(
              pathname
            )
              ? pathname
              : `/product-category/${productCategory.code}`
            let icon: string
            switch (productCategory.slug) {
              case 'F1 tickets':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'F1 Tickets':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'F1 TICKETS':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'race tickets':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'Race tickets':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'Race Tickets':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'RACE TICKETS':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'ticket-white.png' : 'ticket-white.png'
                }`
                break

              case 'GCC':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'GC':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'Golden Circle':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'Golden circle':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'golden circle':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'GOLDEN CIRCLE':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'GOLDEN CIRCLE CONCERT':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'GC & SPP':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'GCC & SPP':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'gc-white.png' : 'gc-white.png'
                }`
                break

              case 'SPP':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'spp-white.png' : 'spp-white.png'
                }`
                break

              case 'super park pass':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'spp-white.png' : 'spp-white.png'
                }`
                break

              case 'Super Park Pass':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'spp-white.png' : 'spp-white.png'
                }`
                break

              case 'SUPER PARK PASS':
                icon = `/media/svg/portal-tabs/${
                  isRouteActive(to) ? 'spp-white.png' : 'spp-white.png'
                }`
                break

              default:
                icon = '/media/svg/portal-tabs/ticket-white.png'
                break
            }
            products_Cateory.push({
              title: productCategory.slug ? productCategory.slug : '',
              to,
              icon,
            })
          }
        }
      })

      products_Cateory.push({
        title: 'User Guide',
        to: '/user-guide',
        icon: `/media/svg/portal-tabs/${
          isRouteActive('/user-guide') ? 'user-guide-white.png' : 'user-guide-white.png'
        }`,
      })
      return products_Cateory
    }
    return []
  }, [isRouteActive, menuesRDX, pathname])

  useEffect(() => {
    if (auth) getProductsAndVouchers()
  }, [auth, getProductsAndVouchers])

  const authenticatedRoutes = useMemo(() => {
    if (!auth) {
      return <Redirect to='/auth/login' />
    } else if (auth.isFirstLogin()) {
      return <Redirect to='/auth/password-reset' />
    }
    return (
      <CustomerPortalLayout menus={menus}>
        <HeaderControls>
          <AuthPortalHeaderThumbnail />
        </HeaderControls>
        <PrivateRoutes />
      </CustomerPortalLayout>
    )
  }, [auth, menus])

  const unauthenticatedRoutes = useMemo(() => {
    let redirectFirstLogin: ReactNode
    if (auth && auth.isFirstLogin()) {
      redirectFirstLogin = <Redirect to='/auth/password-reset' />
    }

    if (!auth || auth?.isFirstLogin()) {
      return (
        <Route>
          {redirectFirstLogin}
          <AuthPage />
        </Route>
      )
    }

    if (pathname.includes('/print/voucher')) {
      return <Redirect from='/auth' to={pathname} />
    }

    if (pathname.includes('/print/ticket')) {
      return <Redirect from='/auth' to={pathname} />
    }

    return <Redirect from='/auth' to='/' />
  }, [auth, pathname])

  return (
    <Switch>
      <Route path='/print/ticket/:ticketCode'>
        <PortalDownloadTicketByWhatsApp />
      </Route>

      <Route path='/print/voucher/:voucherCode'>
        <PortalDownloadVoucherByWhatsApp />
      </Route>
      <Route path='/logout' component={Logout} />
      <Route path='/auth/login/:hash' component={AuthPage} />
      <Route path='/auth/login' component={AuthPage} />
      <Route path='/error/404'>
        <FullPage404 link='/' />
      </Route>
      <Route path='/error/403'>
        <FullPage403 link='https://webntech.ae' />
      </Route>
      {unauthenticatedRoutes}
      {authenticatedRoutes}
    </Switch>
  )
}
