import {ReactNode} from 'react'
import {NavigationSectionMenuItem} from '../../../../config/drawer'
import {Content} from '../../../../_metronic/layout/components/Content'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {ScrollTop} from '../../../../_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {MasterInit} from '../../../../_metronic/layout/MasterInit'
import {Main} from '../../../../_metronic/partials'
import {Header} from './Header/Header'
import {LayoutPortalProvider} from './LayoutPortalProvider'
import {ADMMCustomerPortalMenuHeaderLogo} from '../../../../config/logos'
import {Helmet} from 'react-helmet'
export interface CustomerPortalLayoutProps {
  children?: ReactNode
  menus: NavigationSectionMenuItem[]
  controls?: ReactNode
}

export const CustomerPortalLayout = ({children, menus}: CustomerPortalLayoutProps) => {
  return (
    <PageDataProvider>
      <LayoutPortalProvider>
        <Helmet>
          <title>Yas Marina Circuit</title>
          <link rel='icon' href='/portal.ico' />
        </Helmet>
        <div className='d-flex flex-column flex-root customer-portal-container'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <Header logo={ADMMCustomerPortalMenuHeaderLogo} type='portal' menus={menus} />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer copywrite='&copy; Yas Marina Circuit 2022' />
            </div>
          </div>
        </div>
        <Main />
        <MasterInit />
        <ScrollTop />
      </LayoutPortalProvider>
    </PageDataProvider>
  )
}
