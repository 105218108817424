import {useDay} from './useDay'
import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {useDispatch} from 'react-redux'
import {TicketPortalModel} from '../../../models/ems/TicketModel'
import {actions} from '../redux/CustomerPortalRedux'
import {
  ChangeTicketSeat,
  CancelTicket,
  DownloadTicket,
  ShareTicketProps,
  ShareTicket,
  PostAddToWallet,
} from '../redux/CustomerPortalCRUD'
import {SwalUtils} from '../../../utils/SwalUtils'
import {TicketSeatReselectionModalFormValues} from '../../../components/forms/TicketSeatReselectionModalForm'
import {BlobUtils} from '../../../utils/BlobUtils'
import {InitialValuesProps} from '../components/modals/EmailModal'
import {DeviceUtils} from '../../../utils/DeviceUtils'
import {WhatsAppInitialValuesProps} from '../components/modals/WhatsAppModal'

interface Props {
  onRefresh?: () => void
  onRefreshCallback?: () => void
  clearSelected?: () => void
}

export const useTicketTableActions = ({onRefresh, onRefreshCallback, clearSelected}: Props) => {
  const {pushError, push} = useAlerts()
  const {isLoading, isKeyLoading, setIsLoading} = useLoadingState()
  const dispatch = useDispatch()

  const {getDayFulllName} = useDay()

  const reassignSeat = useCallback(
    async (values: Required<TicketSeatReselectionModalFormValues>) => {
      const doneLoading = setIsLoading(values.ticketCode)
      try {
        const {data} = await ChangeTicketSeat(values.ticketCode, {
          locationCode: values.locationCode,
          reason: values.reason,
          seatNo: values.column,
          seatRow: values.row,
        })

        BlobUtils.downloadBlob(data, `${Date.now()}.pdf`)
        dispatch(actions.bookingProducts.search())
      } catch (e) {
        pushError(e)
      } finally {
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        clearSelected && clearSelected()
        doneLoading()
      }
    },
    [setIsLoading, dispatch, pushError, onRefresh, onRefreshCallback, clearSelected]
  )

  const cancel = useCallback(
    async (codes: string[]) => {
      await SwalUtils.asyncSpecifyReason(
        async (reason) => {
          const doneLoading = setIsLoading(codes)
          try {
            await CancelTicket(codes, reason)
            push({
              message: `Successfully cancelled.`,
              timeout: 5000,
              variant: 'success',
            })
            onRefresh && onRefresh()
            onRefreshCallback && onRefreshCallback()
            clearSelected && clearSelected()
          } catch (e) {
            pushError(e)
          } finally {
            doneLoading()
          }
        },
        {
          titleText: 'Cancel Ticket',
          text: 'Please specify a reason.',
          confirmButtonText: 'Cancel!',
          icon: 'question',
          cancelButtonText: "Don't Cancel!",
        }
      )
    },
    [setIsLoading, push, onRefresh, onRefreshCallback, clearSelected, pushError]
  )

  const download = useCallback(
    async (codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        const {data, headers} = await DownloadTicket({codes})
        let name = headers['content-disposition']
        name = name
          .replace('attachment;', '')
          .replace('filename=', '')
          .split('(')[0]
          .replaceAll('"', '')
          .trim()
        BlobUtils.downloadBlob(data, name)
        push({
          message: `Successfully downloaded.`,
          timeout: 5000,
          variant: 'success',
        })
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        clearSelected && clearSelected()
      } catch (err) {
        pushError(err)
      } finally {
        doneLoading()
      }
    },
    [setIsLoading, push, onRefresh, onRefreshCallback, clearSelected, pushError]
  )

  const shareByEmail = useCallback(
    async (values: InitialValuesProps, codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        if (values.email) {
          const payload: ShareTicketProps = {
            email: values.email,
            codes: codes,
            type: 'email',
            name: values.name,
          }
          const {data} = await ShareTicket(payload)
          push({
            message: `Successfully shared.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          clearSelected && clearSelected()
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        doneLoading()
      }
    },
    [clearSelected, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const shareByWhatsApp = useCallback(
    async (values: WhatsAppInitialValuesProps, codes: string[], tickets: TicketPortalModel[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        if (values.name) {
          const payload: ShareTicketProps = {
            codes: codes,
            type: 'whatsapp',
            name: values.name,
            mobile: values.mobile,
          }
          const {data} = await ShareTicket(payload)
          push({
            message: `Successfully shared.`,
            timeout: 5000,
            variant: 'success',
          })

          const text = data.links.map((item, index) => {
            const day = getDayFulllName(tickets[index].startedAt, tickets[index].endedAt)
            const product =
              day === 'Thursday'
                ? 'F1 Fanzone/Pitlane Walk'
                : tickets[index]?.productName?.replace('&', '%26') || null

            const section = tickets[index]?.locationSlug || null

            const seat =
              day !== 'Thursday' && tickets[index].seatRow && tickets[index].seatNo
                ? 'Row: ' + tickets[index].seatRow + ' | Seat: ' + tickets[index].seatNo
                : null

            return `${product ? '*' + product + '*%0A' : ''}${day ? day + '%0A' : ''}${
              section ? 'Section: ' + section + '%0A' : ''
            }${seat ? seat + '%0A' : ''}${item.link}%0A`
          })
          window.open(`https://api.whatsapp.com/send?phone=${values.mobile}&text=${text}`)
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          clearSelected && clearSelected()
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        doneLoading()
      }
    },

    [clearSelected, getDayFulllName, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const addToWallet = useCallback(
    async (ticket: TicketPortalModel, eventCode) => {
      try {
        if (DeviceUtils.isApple()) {
          const {data, headers} = await PostAddToWallet({
            eventCode: eventCode,
            walletCode: ticket.code,
            responseType: 'blob',
          })
          DeviceUtils.downloadPkpass(data, headers['content-disposition'])
          push({
            message: `Successfully added.`,
            timeout: 5000,
            variant: 'success',
          })
          return data
        } else {
          const {data} = await PostAddToWallet({
            eventCode: eventCode,
            walletCode: ticket.code,
            responseType: 'json',
          })
          window.open(data.url, '_blank')
          push({
            message: `Successfully added.`,
            timeout: 5000,
            variant: 'success',
          })
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        clearSelected && clearSelected()
      }
    },
    [clearSelected, onRefresh, onRefreshCallback, push, pushError]
  )

  const handleCancelBulk = useCallback(
    (data: TicketPortalModel[]) => {
      cancel(data.map((bookings) => bookings.code))
    },
    [cancel]
  )

  const handleCancelSingle = useCallback(
    (data: TicketPortalModel) => {
      cancel([data.code])
    },
    [cancel]
  )

  const handleDownloadBulk = useCallback(
    async (data: TicketPortalModel[]) => {
      for (let item of data) {
        await download([item.code])
      }
    },
    [download]
  )

  const handleShareByEmailBulk = useCallback(
    (values: InitialValuesProps, data: TicketPortalModel[]) => {
      return shareByEmail(
        values,
        data.map((item) => item.code)
      )
    },
    [shareByEmail]
  )

  const handleShareByEmailSingle = useCallback(
    (values: InitialValuesProps, data: TicketPortalModel) => {
      return shareByEmail(values, [data.code])
    },
    [shareByEmail]
  )

  const handleShareByWhatsAppBulk = useCallback(
    (values: WhatsAppInitialValuesProps, data: TicketPortalModel[]) => {
      return shareByWhatsApp(
        values,
        data.map((item) => item.code),
        data
      )
    },
    [shareByWhatsApp]
  )

  const handleShareByWhatsAppSingle = useCallback(
    (values: WhatsAppInitialValuesProps, data: TicketPortalModel) => {
      shareByWhatsApp(values, [data.code], [data])
    },
    [shareByWhatsApp]
  )

  return useMemo(
    () => ({
      isLoading,
      isKeyLoading,
      handleCancelBulk,
      cancelSingle: handleCancelSingle,
      handleDownloadBulk,
      handleShareByEmailSingle,
      handleShareByEmailBulk,
      reassignSeat,
      handleShareByWhatsAppBulk,
      handleShareByWhatsAppSingle,
      addToWallet,
      download,
    }),
    [
      isLoading,
      isKeyLoading,
      handleCancelBulk,
      handleCancelSingle,
      handleDownloadBulk,
      handleShareByEmailSingle,
      handleShareByEmailBulk,
      reassignSeat,
      handleShareByWhatsAppBulk,
      handleShareByWhatsAppSingle,
      addToWallet,
      download,
    ]
  )
}

export const isTicketCancel = (ticket: TicketPortalModel) => {
  return ticket.status === 'active' || ticket.status === 'sent'
}

export const isTicketIsReassignable = (ticket: TicketPortalModel) => {
  return ticket.status === 'active' && ticket.isSeated
}

export const isTicketIsAddToWallet = (ticket: TicketPortalModel) => {
  return ticket.status !== 'cancelled'
}
