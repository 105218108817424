import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {ShipmentCard} from './ShipmentCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../models/GlobalSearchModel'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SelectInput, SelectInputItem} from '../../../../components/inputs/SelectInput'
import {FilterInputContainer} from '../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {useDebounce} from '../../../../components/hooks/useDebounce'

export interface ShipmentCardGridProps {
  data?: GlobalSearchModel<BookingModel> | GroupedSearchModel<BookingModel>
  onFilter: (filter: FilterModel) => void
  className?: string
  initialFilters?: FilterModel
  filters?: FilterModel
  groupedItems?: SelectInputItem[]
  onRefresh: () => void
  setFilter?: (filter: FilterModel) => void
  tabCode?: string
  shipmentsTabCode?: string
}

export const ShipmentCardGrid = ({
  data,
  onFilter,
  className,
  initialFilters,
  filters,
  groupedItems,
  onRefresh,
  setFilter,
  tabCode,
  shipmentsTabCode,
}: ShipmentCardGridProps) => {
  const [isCollapsed, setIsCollapse] = useState<{key: string; value: boolean}>()
  const {setSearch, search, pageNumber, pageSize, setPageNumber, setPageSize} = useFilterState(
    onFilter,
    {
      initialFilters,
      filters,
    }
  )
  const refreshVoucherDebounced = useDebounce(300)

  const toggleCollapse = useCallback(
    (key: string) => {
      setIsCollapse({
        key: key,
        value: !isCollapsed?.value,
      })
    },
    [isCollapsed]
  )

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className='row'>
          {Array.isArray(data.data)
            ? data.data &&
              data.data.length > 0 &&
              data.data.map((booking) => {
                return (
                  <div key={booking.code} className='mb-3 col-xs-12 col-sm-6'>
                    <ShipmentCard
                      className='h-100'
                      data={booking}
                      tabCode={tabCode}
                      shipmentsTabCode={shipmentsTabCode}
                    />
                  </div>
                )
              })
            : Object.entries(data.data).map(([key, tickets]) => {
                return (
                  <>
                    <div className='col-12'>
                      <div
                        className='mb-0 card p-3 mb-5 w-100 d-flex flex-row justify-content-between'
                        onClick={() => toggleCollapse(key)}
                      >
                        <div className='d-flex flex-column justify-content-center'>{key}</div>
                        <MetronicIconButton
                          className='flex-shrink-0'
                          size='sm'
                          type='button'
                          iconType='Navigation'
                          iconName={
                            isCollapsed?.value && isCollapsed.key === key
                              ? 'Angle-down'
                              : 'Angle-right'
                          }
                        />
                      </div>
                    </div>
                    {isCollapsed?.value && isCollapsed.key === key && (
                      <div>
                        {tickets &&
                          tickets.length > 0 &&
                          tickets.map((voucher) => {
                            return (
                              <div key={voucher.code} className='mb-3 col-xs-12 col-sm-6'>
                                <ShipmentCard
                                  className='h-100'
                                  data={voucher}
                                  tabCode={tabCode}
                                  shipmentsTabCode={shipmentsTabCode}
                                />
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </>
                )
              })}
        </div>
      )
    }
    return null
  }, [data, isCollapsed, shipmentsTabCode, tabCode, toggleCollapse])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  const handleGroupByOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (setFilter)
        setFilter({
          ...filters,
          groupBy: e.target.value,
        })
      refreshVoucherDebounced(() => onRefresh())
    },
    [filters, onRefresh, refreshVoucherDebounced, setFilter]
  )

  return (
    <div className={className}>
      <TextInput
        className='mb-3'
        noMargin
        placeholder='Search'
        value={search}
        onChange={handleSearchChange}
      />
      <FilterInputContainer>
        <SelectInput
          items={groupedItems ? groupedItems : []}
          value={filters?.groupBy || ''}
          label='Group By'
          placeholder={filters?.groupBy ? 'None' : 'Group'}
          onChange={handleGroupByOnChange}
        />
      </FilterInputContainer>
      <div>{cards}</div>
      <PaginationHelper
        currentPageNumber={pageNumber}
        currentPageSize={pageSize}
        onChangePageNumber={setPageNumber}
        onChangePageSize={setPageSize}
        total={data?.total}
      />
    </div>
  )
}
