import {useCallback, useMemo} from 'react'
import {ControlledFilterTableColumnOptions} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {MetronicIconButton} from '../../../../../components/inputs/MetronicIconButton'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {PortalShareStatusColumn} from '../PortalShareStatusColumn'
interface usePortalVoucherTableColumnsProps {
  tabCode?: string
}

export const usePortalVoucherTableColumns = ({tabCode}: usePortalVoucherTableColumnsProps) => {
  const columnMap = useMemo((): Record<
    string,
    ControlledFilterTableColumnOptions<VoucherModel>
  > => {
    return {
      name: {
        field: 'name',
        label: 'Product',
        sortable: true,
        hideable: false,
        cellStyle: ColumnStyle.NAME,
      },
      reservationNo: {
        field: 'booking.reservationNo',
        label: 'Reservation',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },

      status: {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalShareStatusColumn data={data} />,
      },
      shareName: {
        field: 'share.name',
        label: 'Shared With',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return <div>{data.share?.name}</div>
        },
      },
      shareEmailMobile: {
        field: 'share.email',
        label: 'Email/Mobile NO',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return (
            <div>{`${data.share?.email ? data.share.email : ''}${
              data.share?.mobile && data.share?.email ? '|' : ''
            }${data.share?.mobile ? data.share.mobile : ''}`}</div>
          )
        },
      },
      shareBy: {
        field: 'share',
        label: 'Shared By',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return (
            <div>
              {data.share?.type === 'whatsapp' ? (
                <MetronicIconButton
                  variant='text'
                  color='white'
                  disabled
                  size='sm'
                  iconType='Communication'
                  iconName='Call'
                />
              ) : data.share?.type === 'email' ? (
                <MetronicIconButton
                  variant='text'
                  color='white'
                  size='sm'
                  disabled
                  iconType='Communication'
                  iconName='Mail'
                />
              ) : null}
            </div>
          )
        },
      },
    }
  }, [])

  const getDefaultColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<VoucherModel>[] = []

    columns.push(columnMap.name, columnMap.reservationNo, columnMap.status)
    return columns
  }, [columnMap])

  const getSharedColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<VoucherModel>[] = []

    columns.push(
      columnMap.shareName,
      columnMap.shareEmailMobile,
      columnMap.name,
      columnMap.reservationNo,
      columnMap.shareBy,
      columnMap.status
    )
    return columns
  }, [
    columnMap.name,
    columnMap.reservationNo,
    columnMap.shareBy,
    columnMap.shareEmailMobile,
    columnMap.shareName,
    columnMap.status,
  ])

  const getNotSharedColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<VoucherModel>[] = []

    columns.push(columnMap.name, columnMap.reservationNo, columnMap.status)
    return columns
  }, [columnMap.name, columnMap.reservationNo, columnMap.status])

  const columns = useMemo(() => {
    if (tabCode) {
      switch (tabCode) {
        case 'shared':
          return getSharedColumns()
        default:
          return getNotSharedColumns()
      }
    }

    return getDefaultColumns()
  }, [getDefaultColumns, getNotSharedColumns, getSharedColumns, tabCode])

  return columns
}
