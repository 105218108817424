import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {AuthNavigationSectionMenuItem} from '../../../../../config/drawer'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {NavHeader} from '../../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {PortalShareStatusColumn} from '../../components/tables/PortalShareStatusColumn'
import {usePoratlTabs} from '../../hooks/usePortalTabs'
import {RouteParams} from '../PortalPage'
import {actions} from '../../redux/CustomerPortalRedux'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {InitialValuesProps} from '../../components/modals/EmailModal'
import {useVoucherTableActions} from '../../hooks/useVoucherTableActions'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {EmailVoucherModal} from '../../components/modals/EmailVoucherModal'
import {WalletVoucherModal} from '../../components/modals/WalletVoucherModal'
import {VoucherTableRowActions} from '../../components/tables/VoucherTable/VoucherTableRowActions'
import {EVENT_CODE} from '../../../../../config/env'
import {
  WhatsAppInitialVoucherValuesProps,
  WhatsAppVoucherModal,
} from '../../components/modals/WhatsAppVoucherModal'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {QrCode} from '../../../../components/utils/QrCode'

export const PortalVoucherDetailsCard = () => {
  const {
    params: {voucherCode, voucherTabCode, tabCode},
  } = useRouteMatch<RouteParams>()
  const {setFilter: setFilterVouchers} = useEntityFilter('customer-portal-voucher')
  const refreshDebounced = useDebounce(300)
  const refreshTabsDebounced = useDebounce(300)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)
  const vouchers = useRootStateSelector((state) => state.customerPortal.vouchers)
  const dispatch = useDispatch()
  const {refreshVouchertabs, subTabs, routeName} = usePoratlTabs()
  const history = useHistory()
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWalletModel, setOpenWalletModel] = useState(false)
  const [selectedVouchers, setSelectedVouchers] = useState<VoucherModel[]>()

  const refresh = useCallback(async () => {
    dispatch(actions.vouchers.search())
    if (voucherTabCode) refreshTabsDebounced(() => refreshVouchertabs())
  }, [dispatch, refreshTabsDebounced, refreshVouchertabs, voucherTabCode])

  useEffect(() => {
    refreshDebounced(() => refresh())
  }, [voucherTabCode, refresh, refreshVouchertabs, refreshDebounced, refreshTabsDebounced])

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `${routeName}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [routeName, subTabs])

  useOnChange(tabCode, () => {
    setFilterVouchers({
      filters: {
        search: voucherCode,
      },
    })
    refreshDebounced(() => refresh())
  })

  const handleGoBack = useCallback(() => {
    history.replace(`/voucher-tab/${voucherTabCode}/${tabCode}`)
    setFilterVouchers({})
  }, [history, voucherTabCode, tabCode, setFilterVouchers])

  const {isKeyLoading, handleShareByEmailBulk, handleShareByWhatsAppBulk, addToWallet} =
    useVoucherTableActions({
      onRefresh: refresh,
    })

  // const handleOnView = useCallback(
  //   (voucher: VoucherModel) => {
  //     history.replace(`/voucher-tab/${voucherTabCode}/${tabCode}/voucher/${voucher?.code}/overview`)
  //   },
  //   [history, tabCode, voucherTabCode]
  // )

  const handleOnWallet = useCallback((data: VoucherModel) => {
    setSelectedVouchers([data])
    setOpenWalletModel(true)
  }, [])

  const handleOnShareByEmail = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (!Array.isArray(data)) setSelectedVouchers([data])
    else setSelectedVouchers(data)
    setOpenEmailModel(true)
  }, [])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (values) {
        if (selectedVouchers) {
          const response = await handleShareByEmailBulk(values, selectedVouchers)
          if (response) return response
        }
      }
    },
    [handleShareByEmailBulk, selectedVouchers]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialVoucherValuesProps) => {
      if (values) {
        if (selectedVouchers) {
          const response = await handleShareByWhatsAppBulk(values, selectedVouchers)
          if (response) return response
        }
      }
    },
    [selectedVouchers, handleShareByWhatsAppBulk]
  )

  const handleSubmitAddtoWallet = useCallback(
    async (voucher: VoucherModel) => {
      if (voucher && EVENT_CODE) {
        const response = await addToWallet(voucher, EVENT_CODE)
        if (response) setOpenWalletModel(false)
      }
    },
    [addToWallet]
  )

  // const handleDownloadVoucher = useCallback(
  //   (data: VoucherModel[]) => {
  //     handleDownloadBulk(data)
  //   },
  //   [handleDownloadBulk]
  // )

  const handleOnShareWhatsApp = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (!Array.isArray(data)) setSelectedVouchers([data])
    else setSelectedVouchers(data)
    setOpenWhatsAppModel(true)
  }, [])

  const details = useMemo(() => {
    if (vouchers && Array.isArray(vouchers.data) && vouchers.data.length) {
      return (
        <div className='d-flex flex-column gap-5 my-5 fs-4 justify-content-between'>
          {vouchers?.data[0].share?.name && (
            <div>
              <span className='text-white fw-bold me-2'>Shared With:</span>
              <span className='text-uppercase'>{vouchers?.data[0].share?.name}</span>
            </div>
          )}

          {vouchers?.data[0]?.share?.type && (
            <div className='d-flex'>
              <span className='text-white fw-bold me-2'>Shared By:</span>
              {vouchers.data[0].share.type === 'whatsapp' ? (
                <div className='d-flex align-items-center text-uppercase'>
                  <MetronicIconButton
                    variant='text'
                    color='white'
                    disabled
                    size='sm'
                    iconType='Communication'
                    iconName='Call'
                    className='h-auto'
                  />
                  WhatApp
                </div>
              ) : vouchers.data[0].share.type === 'email' ? (
                <div className='d-flex align-items-center  text-uppercase'>
                  <MetronicIconButton
                    variant='text'
                    color='white'
                    size='sm'
                    disabled
                    iconType='Communication'
                    iconName='Mail'
                    className='h-auto'
                  />
                  Email
                </div>
              ) : null}
            </div>
          )}

          {(vouchers?.data[0].share?.email || vouchers?.data[0].share?.mobile) && (
            <div>
              <span className='text-white fw-bold me-2'>Email/Mobile No:</span>
              <span className='text-uppercase'>{`${
                vouchers.data[0]?.share?.email ? vouchers.data[0].share.email : ''
              }${vouchers.data[0]?.share?.email && vouchers.data[0]?.share.mobile ? ' | ' : ''}${
                vouchers.data[0]?.share?.mobile ? vouchers.data[0].share.mobile : ''
              }`}</span>
            </div>
          )}
          <div>
            <span className='text-white fw-bold me-2'>Product:</span>
            <span className='text-uppercase'>{vouchers?.data[0]?.name}</span>
          </div>

          <div>
            <span className='text-white fw-bold me-2'>Reservation:</span>
            <span className='text-uppercase'>{vouchers?.data[0].booking?.reservationNo}</span>
          </div>

          <div>
            <span className='text-white fw-bold me-2'>Active status:</span>
            <span className='text-uppercase'>
              {vouchers?.data[0] && <PortalShareStatusColumn data={vouchers?.data[0]} />}
            </span>
          </div>
        </div>
      )
    }
    return null
  }, [vouchers])

  return (
    <div>
      <Toolbar>
        <NavHeader className='text-uppercase fs-5' menus={subMenus} />
      </Toolbar>

      <div className='card p-5'>
        <div className='d-flex'>
          <Button
            size='sm'
            variant='default'
            color='white'
            onClick={handleGoBack}
            uppercase={false}
          >
            <MetronicIcon size='md' iconName='Arrow-left' iconType='Navigation' />
          </Button>
        </div>
        <div className='mt-5 d-flex justify-content-between'>
          <div className='d-flex flex-column justify-content-center'>
            <p
              className='ff-admm-bold text-white mb-0'
              style={{
                fontSize: '30px',
              }}
            >
              Voucher
            </p>
          </div>
        </div>
        {details}
        <div className='my-5 text-center justify-content-center d-flex'>
          {vouchers && Array.isArray(vouchers.data) && vouchers.data.length > 0 && (
            <div
              className='p-3 rounded'
              style={{
                backgroundColor: 'white',
              }}
            >
              <QrCode
                size={150}
                value={`https://bit.ly/3CrzOX9?q=${
                  vouchers.data[0].barcode || vouchers.data[0].code
                }`}
              />
            </div>
          )}
        </div>
        <div className='table-action-container mt-3 mb-3'>
          <div className='w-100'>
            <div className='d-flex gap-5 justify-content-center'>
              {vouchers && Array.isArray(vouchers.data) && vouchers.data.length && (
                <VoucherTableRowActions
                  loading={isKeyLoading(vouchers.data[0].code)}
                  data={vouchers.data[0]}
                  onShareByEmail={handleOnShareByEmail}
                  onWallet={handleOnWallet}
                  onShareByWahtsApp={handleOnShareWhatsApp}
                  iconWidth='33px'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedVouchers && (
        <EmailVoucherModal
          data={selectedVouchers}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}
      {selectedVouchers && (
        <WhatsAppVoucherModal
          data={selectedVouchers}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}
      {selectedVouchers && (
        <WalletVoucherModal
          data={selectedVouchers}
          setOpen={setOpenWalletModel}
          open={openWalletModel}
          onSubmit={handleSubmitAddtoWallet}
        />
      )}
    </div>
  )
}
