import {EnhancedStore} from '@reduxjs/toolkit'
import {AxiosRequestConfig, AxiosStatic} from 'axios'
import {API_URL, getUrlData, LAYOUT_TYPE} from '../../config/env'
import {RootState} from '../redux/RootReducer'

export default function setupAxios(axios: AxiosStatic, store: EnhancedStore<RootState>) {
  axios.interceptors.request.use(
    (config) => {
      const _exception = config.url?.split(':')[0]
      const isToken = _exception === 'https' ? false : true
      if (isToken) {
        setBaseUrl(config)
        setAuthorizationHeader(config, store)
        setClientHeader(config)
      }

      return config
    },
    (err) => Promise.reject(err)
  )

  // axios.interceptors.response.use(
  //   (config) => {
  //     return config
  //   },
  //   (err) => {
  //     if (err.response && err.response.status === 403) {
  //       window.location.replace('/auth/login')
  //     }
  //     Promise.reject(err)
  //   }
  // )
}

const setBaseUrl = (config: AxiosRequestConfig) => {
  config.baseURL = API_URL
}

const setAuthorizationHeader = (config: AxiosRequestConfig, store: EnhancedStore<RootState>) => {
  const {auth, outlet, customerPortal, digitalWaiver} = store.getState()

  switch (LAYOUT_TYPE) {
    case 'outlet': {
      if (outlet.auth?.token) {
        config.headers.Authorization = `Bearer ${outlet.auth.token}`
      }
      break
    }
    case 'customer-delegate':
    case 'customer-portal': {
      if (customerPortal.auth?.token) {
        config.headers.Authorization = `Bearer ${customerPortal.auth.token}`
      }
      break
    }
    case 'digital-waiver': {
      if (digitalWaiver.auth?.token) {
        config.headers.Authorization = `Bearer ${digitalWaiver.auth.token}`
      }
      break
    }
    default: {
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }
    }
  }
}

const setClientHeader = (config: AxiosRequestConfig) => {
  const {organizationCode, outletCode, deviceId} = getUrlData()
  if (organizationCode) {
    config.headers['x-organization'] = organizationCode
  }
  if (outletCode) {
    config.headers['x-outlet'] = outletCode
  }
  if (deviceId) {
    config.headers['x-device-id'] = deviceId
  }
}
