import {KTSVG} from '../../../../../../_metronic/helpers'
import {Button} from '../../../../../components/inputs/Button'
import {Spinner} from '../../../../../components/utils/Spinner'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {isTicketIsAddToWallet} from '../../../hooks/useTicketTableActions'

export interface TicketTableRowActionsProps {
  onView?: (data: TicketPortalModel) => void
  onDownload?: (data: TicketPortalModel[]) => void
  onWallet?: (data: TicketPortalModel) => void
  onShareByWahtsApp?: (data: TicketPortalModel) => void
  onShareByEmail?: (data: TicketPortalModel) => void
  onReassignSeat?: (data: TicketPortalModel) => void
  onCancel?: (data: TicketPortalModel) => void
  data: TicketPortalModel
  loading?: boolean
  iconWidth?: string
}

export const TicketTableRowActions = ({
  onView,
  onDownload,
  onWallet,
  onShareByWahtsApp,
  onShareByEmail,
  data,
  loading,
  iconWidth,
}: TicketTableRowActionsProps) => {
  return (
    <div className='d-flex gap-3'>
      {onView && (
        <Button
          type='button'
          className='bg-transparent p-2'
          tooltip='View'
          size='sm'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/view.svg'
            onClick={() => onView(data)}
          />
        </Button>
      )}

      {onDownload && (
        <Button
          type='button'
          className='bg-transparent p-2'
          tooltip='Download'
          size='sm'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          {loading ? (
            <Spinner className='me-5' size='lg' />
          ) : (
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/download.svg'
              onClick={() => onDownload([data])}
            />
          )}
        </Button>
      )}

      {onWallet && isTicketIsAddToWallet(data) && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Add to wallet'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/wallet.svg'
            onClick={() => onWallet(data)}
          />
        </Button>
      )}
      {onShareByWahtsApp && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Share by whatsApp'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/whatsapp.svg'
            onClick={() => onShareByWahtsApp(data)}
          />
        </Button>
      )}

      {onShareByEmail && (
        <Button
          type='button'
          className='bg-transparent p-2'
          size='sm'
          tooltip='Share by email'
          style={{
            width: iconWidth ? iconWidth : '30px',
          }}
        >
          <KTSVG
            color='white'
            className='h-100 w-100'
            svgClassName='h-100 w-100'
            path='/media/svg/customer-poratl-actions/mail.svg'
            onClick={() => onShareByEmail(data)}
          />
        </Button>
      )}
    </div>
  )
}
