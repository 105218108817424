import {useCallback, useState, useRef, useEffect} from 'react'
import {v4 as uuidv4} from 'uuid'
import {AdvancedFilterTypes, FilterModel} from '../../../../../models/FilterModel'
import {GetLocationsBySlug} from '../../../redux/CustomerPortalCRUD'
import {useAlerts} from '../../../../../components/alerts/useAlerts'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {useTableFilterState} from '../../../../../components/tables/useTableFilterState'
import {SearchableSelectInput} from '../../../../../components/inputs/SearchableSelect'
import {CustomerSearchInputSelectItem} from '../../inputs/CustomerSearchInput'
import {MetronicIconPath} from '../../../../../components/inputs/MetronicIcon'
import {SearchableSelectInputClasses} from '../../../../../components/inputs/SearchableSelect'
export interface FilterLocationInputProps {
  field: string
  label: string
  placeholder?: string
  productCategorySlug: string
  icon?: MetronicIconPath
  noMargin?: boolean
  productCode?: AdvancedFilterTypes
  classes?: SearchableSelectInputClasses<any>
  className?: string
  filter?: FilterModel | null
}

export const FilterLocationInput = ({
  field,
  label,
  placeholder,
  productCategorySlug,
  icon,
  noMargin,
  productCode,
  classes,
  className,
  filter,
}: FilterLocationInputProps) => {
  const {pushError} = useAlerts()
  const {clearAdvancedFilterValue, setAdvancedFilterValue} = useTableFilterState()
  const searchDebounce = useDebounce(500)
  const [searchResult, setSearchResult] = useState<CustomerSearchInputSelectItem[]>()
  const [value, setValue] = useState<CustomerSearchInputSelectItem | null>(null)
  const id = useRef(uuidv4()).current

  useEffect(() => {
    if (!filter?.filters?.locationCode) setValue(null)
  }, [filter?.filters?.locationCode])

  const searchLocations = useCallback(
    async (filter?: FilterModel) => {
      try {
        const {data} = await GetLocationsBySlug(filter)
        return data
      } catch (e) {
        pushError(e)
      }
    },
    [pushError]
  )

  const getSearchableFilterFieldFilterHandler = useCallback(
    async (filter: FilterModel) => {
      searchDebounce(async () => {
        const data = await searchLocations(filter)
        if (data) {
          const newData: CustomerSearchInputSelectItem[] = data.map((item) => {
            return {
              code: item.code,
              label: item.name,
              value: item.code,
            }
          })
          setSearchResult(newData)
        }
      })
    },
    [searchDebounce, searchLocations]
  )

  const getSearchableFilterFieldChangeHandler = useCallback(
    (valueInput: CustomerSearchInputSelectItem | null) => {
      if (valueInput) {
        setAdvancedFilterValue(field, valueInput.code)
        setValue(valueInput)
      } else {
        clearAdvancedFilterValue(field)
        setValue(null)
      }
    },
    [clearAdvancedFilterValue, field, setAdvancedFilterValue]
  )

  return (
    <SearchableSelectInput
      allowClear
      noMargin={noMargin}
      itemMapper={itemMapper}
      label={label}
      placeholder={placeholder}
      onSearch={(value) =>
        getSearchableFilterFieldFilterHandler({
          filters: {
            search: value,
            productCategorySlug,
            productCode,
          },
        })
      }
      items={searchResult ? searchResult : []}
      id={id}
      value={value}
      onChange={getSearchableFilterFieldChangeHandler}
      filter={NO_FILTER}
      icon={icon}
      classes={classes}
      className={className}
    />
  )
}

const itemMapper = (item: CustomerSearchInputSelectItem) => ({label: item.label, value: item.value})

const NO_FILTER = () => true
