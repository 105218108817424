import {useMemo} from 'react'
import {Badge} from '../../../../../components/badge/Badge'
import {ColorVariant} from '../../../../../components/inputs/Button'
import {ShipmentsModelStatus} from '../../../../../models/ems/BookingModel'

export interface ShipmentsStatusColumnProps {
  data: {
    shipmentStatus?: ShipmentsModelStatus
  }
}

export const ShipmentsStatusColumn = ({data}: ShipmentsStatusColumnProps) => {
  const variant = useMemo(() => {
    return getStatusVariant(data.shipmentStatus)
  }, [data])

  return (
    <Badge uppercase variant={variant}>
      {data.shipmentStatus}
    </Badge>
  )
}

const getStatusVariant = (shipmentsStatus?: ShipmentsModelStatus): ColorVariant => {
  switch (shipmentsStatus) {
    case 'delivered':
      return 'success'
    case 'shipped':
      return 'warning'
    case 'in-transit':
      return 'dark'
    default:
      return 'dark'
  }
}
