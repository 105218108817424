import clsx from 'clsx'
import {CSSProperties, useMemo} from 'react'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'

interface SubProductBoxProps {
  title: string
  wrapperClasName?: string
  titleClassName?: string
  valueClassName?: string
  value?: number
  style?: CSSProperties
  isLast?: boolean
}

export const SubProductBox = ({
  title,
  value,
  wrapperClasName,
  titleClassName,
  valueClassName,
  style,
  isLast,
}: SubProductBoxProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  return (
    <div className={clsx('col-4 text-center py-2', wrapperClasName)}>
      <div className='d-flex flex-column justify-content-between'>
        <span
          className={clsx(
            'ff-admm-bold mt-2 mt-xs-2 mt-sm-2 mt-md-1',
            titleClassName,
            !isMobile && 'fs-7'
          )}
          style={{
            fontSize: isMobile ? '10px' : '',
          }}
        >
          {title}
        </span>
        <div className={clsx('text-primary ff-admm-bold mb-0', valueClassName)} style={style}>
          {value}
        </div>
      </div>
    </div>
  )
}
