import clsx from 'clsx'
import {ComponentType, ReactNode, useCallback, useMemo} from 'react'
import {MetronicIconButton} from '../inputs/MetronicIconButton'
import {GroupedTableData} from './Table'
import {TableColumn, TableColumnOptions} from './TableColumn'
import {TableRowCheckbox} from './TableRowCheckbox'
export type TableRowId = string | number

export interface TableActionProps<T> {
  data: T
}

export interface TableRowProps<T> {
  columns: TableColumnOptions<T>[]
  data: T
  actions?: (data: T) => ReactNode
  onSelect?: (id: TableRowId) => void
  checked?: boolean
  id: TableRowId
  hiddenColumns?: string[]
  expanded?: boolean
  expansion?: ComponentType<{data: T}>
  onExpandToggle?: (id: TableRowId, expanded: boolean) => void
  disableCheckbox?: boolean
  hideExpansion?: boolean
  paddingStart?: number
  groupData?: GroupedTableData<T>
  className?: string
}

export const TableRow = <T extends Record<string, any>>({
  columns,
  data,
  actions,
  onSelect,
  hiddenColumns,
  checked,
  id,
  expansion,
  expanded,
  disableCheckbox,
  onExpandToggle,
  hideExpansion,
  paddingStart = 0,
  groupData,
  className,
}: TableRowProps<T>) => {
  const handleOnSelect = useCallback(() => {
    if (onSelect) {
      onSelect(id)
    }
  }, [onSelect, id])

  const handleOnExpandToggle = useCallback(() => {
    if (onExpandToggle) {
      onExpandToggle(id, !expanded)
    }
  }, [onExpandToggle, id, expanded])

  const isColumnHidden = useCallback(
    (column: string) => {
      if (hiddenColumns) {
        return hiddenColumns.includes(column)
      }
      return false
    },
    [hiddenColumns]
  )

  const columnNodes = useMemo(() => {
    return columns.reduce<ReactNode[]>((acc, column) => {
      if (column && !isColumnHidden(column.field)) {
        acc.push(
          <TableColumn key={column.field} data={data} options={column} groupData={groupData} />
        )
      }
      return acc
    }, [])
  }, [columns, data, groupData, isColumnHidden])

  const actionColumn = useMemo(() => {
    if (actions) {
      const actionNode = actions(data)
      return (
        <td>
          <div className='table-action-container'>{actionNode}</div>
        </td>
      )
    }
    return null
  }, [actions, data])

  const paddingStartNodes = useMemo(() => {
    const nodes: ReactNode[] = []

    for (let i = 0; i < paddingStart; i++) {
      nodes.push(<td key={i} className='table-row-empty-box'></td>)
    }

    return nodes
  }, [paddingStart])

  const expansionRow = useMemo(() => {
    if (expansion && expanded) {
      const Expansion = expansion
      let colSpan = columns.length + 1 + paddingStart

      if (actions) {
        colSpan++
      }
      if (checked !== undefined) {
        colSpan++
      }

      return (
        <tr>
          <td colSpan={colSpan}>
            <Expansion data={data} />
          </td>
        </tr>
      )
    }
  }, [actions, checked, columns.length, data, expanded, expansion, paddingStart])

  const expandButton = useMemo(() => {
    if (!hideExpansion) {
      return (
        <td className={clsx('text-end w-30px')} onClick={handleOnExpandToggle}>
          <MetronicIconButton
            iconType='Navigation'
            iconName={expanded ? 'Angle-down' : 'Angle-right'}
            size='sm'
            variant='text'
          />
        </td>
      )
    }
    return null
  }, [expanded, handleOnExpandToggle, hideExpansion])

  return (
    <>
      <tr className={className}>
        <TableRowCheckbox disabled={disableCheckbox} onSelect={handleOnSelect} checked={checked} />
        {paddingStartNodes}
        {expandButton}
        {columnNodes}
        {actionColumn}
      </tr>
      {expansionRow}
    </>
  )
}
