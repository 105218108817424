import {useCallback, useEffect, useMemo, useState} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {SweetAlert} from '../../../../components/modals/SweetAlert'
import {HorizontalDivider} from '../../../../components/utils/HorizontalDivider'
import {QrCode} from '../../../../components/utils/QrCode'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {GetVoucherWalletQrCode} from '../../redux/CustomerPortalCRUD'

interface WalletModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  data: VoucherModel[]
  onSubmit: (data: VoucherModel) => any
}

export interface InitialValuesProps {
  email: string
}

export const WalletVoucherModal = ({open, setOpen, data, onSubmit}: WalletModalProps) => {
  const {pushError} = useAlerts()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  const {down} = useBreakpoint()

  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])
  const [qrCodeURL, setQrCodeURL] = useState<string>()

  const handleQrCode = useCallback(async () => {
    try {
      if (data) {
        const {data: response} = await GetVoucherWalletQrCode(data[0]?.code)
        setQrCodeURL(response.qrCode)
      }
    } catch (err) {
      pushError(err)
    }
  }, [data, pushError])

  useEffect(() => {
    handleQrCode()
  }, [handleQrCode])

  const handleSubmit = useCallback(async () => {
    if (data) {
      setIsLoading(true)
      const response = await onSubmit(data[0])
      if (response) {
        setIsLoading(false)
      }
    }
  }, [data, onSubmit])

  return (
    <SweetAlert
      customClass={{popup: 'bg-primary-cp-dark'}}
      open={open}
      onClose={handleClose}
      showConfirmButton={false}
      width='auto'
    >
      <div className='px-5'>
        <div className='d-flex px-5'>
          <div className='flex-grow-1 text-start d-flex flex-column justify-content-center'>
            <p className='text-white ff-admm-bold fs-1 mb-0'>Wallet</p>
          </div>

          <div>
            <MetronicIconButton
              type='button'
              className='btn'
              iconType='Navigation'
              iconName='Close'
              variant='text'
              size='md'
              color='white'
              onClick={handleClose}
            />
          </div>
        </div>

        <HorizontalDivider className='bg-dark' />
        <div className='d-flex gap-10 mt-5 px-5'>
          <div>{qrCodeURL && <QrCode size={isMobile ? 125 : 200} value={qrCodeURL} />}</div>

          <div className='d-flex flex-column justify-content-center'>
            <div className='flex-grow-1'>
              <p
                className='text-white text-start ff-admm-bold'
                style={{
                  fontSize: isMobile ? '15px' : '20px',
                }}
              >
                {data[0].name}
              </p>
              <p
                className='text-white text-start ff-admm-bold'
                style={{
                  fontSize: isMobile ? '15px' : '20px',
                }}
              >
                {data[0].booking?.reservationNo || data[0].booking?.code}
              </p>
              <p
                className='text-white text-start ff-admm-bold'
                style={{
                  fontSize: isMobile ? '25px' : '30px',
                }}
              >
                {data[0].barcode || data[0].code}
              </p>
            </div>

            {!isMobile && (
              <div className='flex-grow-1 d-flex flex-column justify-content-end'>
                <button
                  className='btn bg-body d-flex gap-2 mt-2 rounded-0 text-white justify-content-center'
                  onClick={handleSubmit}
                >
                  Add To Wallet
                  {isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        {isMobile && (
          <div className='w-100'>
            <button
              className='btn bg-body d-flex gap-2 mx-auto mt-5 rounded-0 text-white w-75 justify-content-center'
              onClick={handleSubmit}
            >
              Add To Wallet
              {isLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
      </div>
    </SweetAlert>
  )
}
