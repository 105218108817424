import {ReactNode, useCallback, useMemo, useState} from 'react'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../../models/GlobalSearchModel'
import {ControlledFilterPortalTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterPortalTable'
import {idExtractor} from '../../../../../utils/idExtractor'
import {VoucherTableRowActions} from './VoucherTableRowActions'
import {useVoucherTableActions} from '../../../hooks/useVoucherTableActions'
import {VoucherModel} from '../../../../../models/svc/VoucherModel'
import {VoucherTableSelectionActions} from './VoucherTableSelectionActions'
// import {useHistory} from 'react-router-dom'
import {EmailVoucherModal, InitialValuesProps} from '../../modals/EmailVoucherModal'
import {WalletVoucherModal} from '../../modals/WalletVoucherModal'
import {EVENT_CODE} from '../../../../../../config/env'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {GroupedTableData} from '../../../../../components/tables/Table'
import {
  WhatsAppInitialVoucherValuesProps,
  WhatsAppVoucherModal,
} from '../../modals/WhatsAppVoucherModal'
import {SelectGroupByButtons} from '../../../pages/PortalTicketPage'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Spinner} from '../../../../../components/utils/Spinner'
import {usePortalVoucherTableColumns} from './usePortalVoucherTableColumns'
import {Button} from '../../../../../components/inputs/Button'
export interface VoucherTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<VoucherModel> | GroupedSearchModel<VoucherModel>
  onRefresh?: () => void
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  advancedGroupBy?: ReactNode
  leftToolbar?: ReactNode
  removedColumns?: string[]
  filters: FilterModel
  expandedGroups?: TableRowId[]
  onExpandedGroupChange?: (groups: TableRowId[]) => void
  tabCode: string
  voucherTabCode: string
  groupedItem?: SelectGroupByButtons | null
}

export const VoucherPortalTable = ({
  data,
  onFilter,
  onRefresh,
  advancedFilters,
  advancedGroupBy,
  initialFilters,
  removedColumns,
  leftToolbar,
  filters,
  expandedGroups,
  onExpandedGroupChange,
  tabCode,
  // voucherTabCode,
  groupedItem,
}: VoucherTableProps) => {
  const [selected, setSelected] = useState<TableRowId[]>([])
  const [selectedVouchers, setSelectedVouchers] = useState<VoucherModel[]>()
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)
  const [openWalletModel, setOpenWalletModel] = useState(false)
  const {
    isLoading,
    isKeyLoading,
    handleDownloadBulk,
    handleShareByWhatsAppBulk,
    handleShareByEmailBulk,
    addToWallet,
  } = useVoucherTableActions({
    onRefresh,
  })

  // const history = useHistory()

  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: 'voucher',
    defaults: {
      hiddenColumns: ['startedAt', 'endedAt'],
    },
  })

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (values) {
        if (selectedVouchers) {
          const response = await handleShareByEmailBulk(values, selectedVouchers)
          if (response) return response
        }
      }
      onRefresh && onRefresh()
    },
    [handleShareByEmailBulk, onRefresh, selectedVouchers]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialVoucherValuesProps) => {
      if (values) {
        if (selectedVouchers) {
          const response = await handleShareByWhatsAppBulk(values, selectedVouchers)
          if (response) return response
        }
      }
      onRefresh && onRefresh()
    },
    [onRefresh, selectedVouchers, handleShareByWhatsAppBulk]
  )

  const handleOnWallet = useCallback((data: VoucherModel) => {
    setSelectedVouchers([data])
    setOpenWalletModel(true)
  }, [])

  useOnChange(openEmailModel, () => {
    if (!openEmailModel) setSelectedVouchers(undefined)
  })

  useOnChange(openWalletModel, () => {
    if (!openWalletModel) setSelectedVouchers(undefined)
  })

  // const handleOnView = useCallback(
  //   (voucher: VoucherModel) => {
  //     history.push(`/voucher-tab/${voucherTabCode}/${tabCode}/voucher/${voucher?.code}/overview`)
  //   },
  //   [history, tabCode, voucherTabCode]
  // )

  const handleSubmitAddtoWallet = useCallback(
    async (voucher: VoucherModel) => {
      if (voucher && EVENT_CODE) {
        const response = await addToWallet(voucher, EVENT_CODE)
        if (response) setOpenWalletModel(false)
      }
    },
    [addToWallet]
  )

  const handleOnShareByEmail = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (!Array.isArray(data)) setSelectedVouchers([data])
    else setSelectedVouchers(data)
    setOpenEmailModel(true)
  }, [])

  useOnChange(openEmailModel, () => {
    if (!openEmailModel) setSelectedVouchers(undefined)
  })

  useOnChange(openWalletModel, () => {
    if (!openWalletModel) setSelectedVouchers(undefined)
  })

  useOnChange(openWhatsAppModel, () => {
    if (!openWhatsAppModel) setSelectedVouchers(undefined)
  })

  const handleOnShareWhatsApp = useCallback((data: VoucherModel | VoucherModel[]) => {
    if (!Array.isArray(data)) setSelectedVouchers([data])
    else setSelectedVouchers(data)
    setOpenWhatsAppModel(true)
  }, [])

  const rowActions = useCallback(
    (data: VoucherModel) => (
      <VoucherTableRowActions
        data={data}
        loading={isKeyLoading(data.code)}
        // onView={handleOnView}
        onDownload={handleDownloadBulk}
        onWallet={handleOnWallet}
        onShareByWahtsApp={handleOnShareWhatsApp}
        onShareByEmail={handleOnShareByEmail}
      />
    ),
    [handleDownloadBulk, handleOnShareByEmail, handleOnShareWhatsApp, handleOnWallet, isKeyLoading]
  )

  const groupRowActions = useCallback(
    (data: GroupedTableData<VoucherModel>) => {
      return (
        <div className='d-flex gap-3'>
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Download'
            style={{
              width: '29px',
            }}
          >
            {isKeyLoading(data.data[0].code) ? (
              <Spinner className='me-5' size='lg' />
            ) : (
              <KTSVG
                color='white'
                className='h-100 w-100'
                svgClassName='h-100 w-100'
                path='/media/svg/customer-poratl-actions/download.svg'
                onClick={() => handleDownloadBulk(data.data)}
              />
            )}
          </Button>
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by whatsApp'
            style={{
              width: '29px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/whatsapp.svg'
              onClick={() => handleOnShareWhatsApp(data.data)}
            />
          </Button>

          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by email'
            style={{
              width: '29px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/mail.svg'
              onClick={() => handleOnShareByEmail(data.data)}
            />
          </Button>
        </div>
      )
    },
    [handleDownloadBulk, handleOnShareByEmail, handleOnShareWhatsApp, isKeyLoading]
  )

  const tableSelectionItems = useMemo(() => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
      return Object.values(data.data).reduce<VoucherModel[]>((acc, group) => {
        acc.push(...group)
        return acc
      }, [])
    }
    return []
  }, [data])

  const column = usePortalVoucherTableColumns({tabCode})

  const tableColumns = useMemo(() => {
    return column.filter((c) => {
      if (removedColumns) {
        return !removedColumns.includes(c.field)
      }
      return true
    })
  }, [column, removedColumns])

  return (
    <>
      <ControlledFilterPortalTable<VoucherModel>
        onHiddenColumnsChange={setHiddenColumns}
        hiddenColumns={hiddenColumns}
        initialFilters={initialFilters}
        advancedFilters={advancedFilters}
        advancedGroupBy={advancedGroupBy}
        selectionAction={
          <VoucherTableSelectionActions
            items={tableSelectionItems}
            selected={selected}
            onShareByEmail={handleOnShareByEmail}
            onShareByWhatsApp={handleOnShareWhatsApp}
            onDownload={handleDownloadBulk}
            isLoading={isLoading}
            className='rounded-0'
          />
        }
        leftToolbar={leftToolbar}
        onFilter={onFilter}
        idExtractor={idExtractor}
        columns={tableColumns}
        filters={filters}
        searchResults={data}
        actions={rowActions}
        groupRowActions={groupRowActions}
        selection={selected}
        onSelectionChange={setSelected}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={onExpandedGroupChange}
        groupedItem={groupedItem}
      />
      {selectedVouchers && (
        <EmailVoucherModal
          data={selectedVouchers}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}
      {selectedVouchers && (
        <WhatsAppVoucherModal
          data={selectedVouchers}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}
      {selectedVouchers && (
        <WalletVoucherModal
          data={selectedVouchers}
          setOpen={setOpenWalletModel}
          open={openWalletModel}
          onSubmit={handleSubmitAddtoWallet}
        />
      )}
    </>
  )
}
