import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useWebSocket} from '../../../../components/utils/WebSocketProvider'
import {GetFandBWidgetData} from '../redux/EmsCRUD'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'
import {WSS_EMS_URL} from '../../../../../config/env'
import {WidgetModel} from '../../../../models/fnb/WidgetModel'

export interface useEventFandBDashboardDataOptions {
  widgets?: (keyof WidgetModel)[]
  date: Date | null
  eventCode?: string
}

export const useEventFandBDashboardData = ({
  date,
  eventCode,
  widgets,
}: useEventFandBDashboardDataOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {isLoading: isDashboardLoading, managePromise} = usePromiseManager()
  const {socket, setSocket, startSocket, isConnected} = useWebSocket()
  const resetWidgetDebounce = useDebounce(500)

  const getSocketHandlers = useCallback(
    (eventCode: string, widgetCodes: Array<keyof WidgetModel>) => {
      return widgetCodes.map((widget) => {
        const event = `${widget}:${eventCode}`

        return {
          event,
          callback: (data: {data: WidgetModel[keyof WidgetModel]}) => {
            setWidgetData((widgetData) => ({...widgetData, [widget]: data.data}))
          },
        }
      })
    },
    []
  )

  useEffect(() => {
    const stopSocket = startSocket(WSS_EMS_URL)
    return () => {
      stopSocket()
    }
  }, [startSocket])

  const resetWidgetData = useCallback(async () => {
    if (eventCode && date) {
      try {
        const {data} = await managePromise(
          'dashboard',
          GetFandBWidgetData({
            widgets: [...(widgets || [])],
            eventCode,
            date: date.toISOString(),
          })
        )
        setWidgetData(data)
      } catch (e) {
        if (e instanceof CancelledPromiseError) {
          // Ignore cancelled promise.
        }
      }
    }
  }, [eventCode, date, managePromise, widgets])

  const isSameDay = useMemo(
    () => !date || Boolean(date && moment(date).isSame(new Date(), 'day')),
    [date]
  )

  useEffect(() => {
    if (eventCode && isSameDay && widgets) {
      const handlers = getSocketHandlers(eventCode, widgets)
      handlers.forEach((handler) => {
        socket?.on(handler.event, handler.callback)
      })

      return () => {
        handlers.forEach((handler) => {
          socket?.off(handler.event, handler.callback)
        })
      }
    }
  }, [widgets, eventCode, getSocketHandlers, isSameDay, socket])

  useEffect(() => {
    resetWidgetDebounce(async () => {
      resetWidgetData()
    })
  }, [resetWidgetData, resetWidgetDebounce])

  return {
    data: widgetData,
    isLoading: isDashboardLoading,
    startSocket,
    isConnected,
    socket,
    setSocket,
    resetWidgetData,
  }
}
