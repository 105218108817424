import axios from 'axios'
import {SeatMapValueObject} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {FilterModel} from '../../../../models/FilterModel'
import {GateModel, GateModelCreateParams} from '../../../../models/acs/GateModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {LocationModel, LocationModelCreateParams} from '../../../../models/acs/LocationModel'
import {SeatMapModelCreateParams} from '../../../../models/SeatMapModel'
import {VenueModel, VenueModelCreateParams} from '../../../../models/acs/VenueModel'
import {DateUtil} from '../../../../utils/DateUtil'

const CRUD_GATE = '/gate'
const CRUD_LOCATION = '/location'
const CRUD_VENUE = '/venue'
const ACS_PARENT_GATE = '/gate/parent'
const ACS_PARENT_LOCATION = '/location/parent'
const ACS_PARENT_LOCATION_VENUE = '/location/venue'
const ACS_PARENT_VENUE = '/venue/parent'
const ACS_PARENT_VENUE_TREE = '/venue/tree'
const ACS_SEARCH_GATE = '/gate/list'
const ACS_SEARCH_LOCATION = '/location/list'
const ACS_SEARCH_VENUE = '/venue/list'

const DeleteGates = (codes: string[]) => axios.delete('/gate', {data: {codes}})
const DeleteLocations = (codes: string[]) => axios.delete(CRUD_LOCATION, {data: {codes}})
const DeleteVenues = (codes: string[]) => axios.delete(CRUD_VENUE, {data: {codes}})

const GetParentGates = (filter?: FilterModel) => axios.post(ACS_PARENT_GATE, filter)
const GetParentLocations = (filter?: FilterModel) =>
  axios.post<LocationModel[]>(ACS_PARENT_LOCATION, filter)
const GetParentLocationByVenue = (filter?: FilterModel) =>
  axios.post<VenueModel[]>(ACS_PARENT_LOCATION_VENUE, filter)
const GetParentVenues = (filter?: FilterModel) => axios.post<VenueModel[]>(ACS_PARENT_VENUE, filter)
const GetParentVenueTree = (filter?: FilterModel) =>
  axios.post<VenueModel[]>(ACS_PARENT_VENUE_TREE, filter)
const GetGates = (filter?: FilterModel) => axios.post(ACS_SEARCH_GATE, filter)
const GetLocations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<LocationModel>>(ACS_SEARCH_LOCATION, filter)
const GetLocationByCode = (locationCode: string) =>
  axios.get<LocationModel>(`${CRUD_LOCATION}/${locationCode}`)
const GetLocationByEventCode = (eventCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/event/seat-map/${eventCode}`)
const GetProductLocationsByCode = (productCode: string) =>
  axios.get<LocationModel[]>(`/product/${productCode}/location`)
const GetDefaultLocationByCode = (locationCode: string) =>
  axios.get<LocationModel>(`${CRUD_LOCATION}/${locationCode}/default`)
const GetGateLocationByCode = (locationCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/${locationCode}/gate`)
const GetEntranceLocationByCode = (locationCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/${locationCode}/entrance`)
const ExportLocations = (venueCode: string) =>
  axios.post<Blob>(
    `/location/venue/export`,
    {
      venue: venueCode,
    },
    {
      responseType: 'blob',
    }
  )

const GetGateScanner = (options: {type?: string; location?: string}) =>
  axios.post<GateModel[]>(`${CRUD_GATE}/scanner`, options)
const GetVenues = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VenueModel>>(ACS_SEARCH_VENUE, filter)
const GetVenueByCode = (code: string) => axios.get<VenueModel>(`${CRUD_VENUE}/${code}`)

const GetOccupiedSeatMaps = (locationCode: string, eventCode: string, date: Date) =>
  axios.post<SeatMapValueObject>(`${CRUD_LOCATION}/used-seats`, {
    location: locationCode,
    event: eventCode,
    date: DateUtil.convertDateToApiString(date),
  })
const GetOccupiedSeatMapsByReservation = (locationCode: string, payload: any) =>
  axios.post<SeatMapValueObject>(
    `${CRUD_LOCATION}/${locationCode}/seatmap/reservation-availability`,
    payload
  )
const GetOccupiedSeatMapsByProduct = (
  locationCode: string,
  eventCode: string,
  productCode?: string
) =>
  axios.post<SeatMapValueObject>(`${CRUD_LOCATION}/${locationCode}/seatmap/availability`, {
    eventCode,
    productCode,
  })
const PostSeatMapByLocationCode = (locationCode: string, data: SeatMapModelCreateParams) =>
  axios.post<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`, data)
const PutSeatMapByLocationCode = (locationCode: string, data: SeatMapModelCreateParams) =>
  axios.put<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`, data)
const DeleteSeatMapByLocationCode = (locationCode: string) =>
  axios.delete<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`)

const PostGate = (data: GateModelCreateParams) => axios.post(CRUD_GATE, data)
const GetGateByCode = (gateCode: string) => axios.get(`${CRUD_GATE}/${gateCode}`)
const PutGate = (data: GateModelCreateParams, gateCode: string) =>
  axios.put(`${CRUD_GATE}/${gateCode}`, data)
const PostLocation = (params: LocationModelCreateParams) => {
  const formData = new FormData()
  formData.append('name', params.name)
  formData.append('description', params.description)
  formData.append('venueCode', params.venueCode)
  formData.append('type', params.type)
  formData.append('isFeatured', String(params.isFeatured))
  params.refCodes && formData.append('refCodes', params.refCodes)
  params.slug && formData.append('slug', params.slug)
  params.gateCode && formData.append('gateCode', params.gateCode)
  params.entranceCode && formData.append('entranceCode', params.entranceCode)
  params.parentCode && formData.append('parentCode', params.parentCode)
  params.acsFile && formData.append('acsFile', params.acsFile)
  params.geometry && formData.append('geometry', JSON.stringify(params.geometry))
  params.region && formData.append('region', JSON.stringify(params.region))
  params.gateCodes?.forEach((code) => {
    formData.append('gateCodes', code)
  })
  params.entranceCodes?.forEach((code) => {
    formData.append('entranceCodes', code)
  })
  return axios.post<LocationModel>(CRUD_LOCATION, formData)
}
const PutLocation = (params: LocationModelCreateParams, locationCode: string) => {
  const formData = new FormData()
  formData.append('name', params.name)
  formData.append('description', params.description)
  formData.append('venueCode', params.venueCode)
  formData.append('type', params.type)
  formData.append('isFeatured', String(params.isFeatured))
  params.refCodes && formData.append('refCodes', params.refCodes)
  params.slug && formData.append('slug', params.slug)
  params.slug && formData.append('slug', params.slug)
  params.gateCode && formData.append('gateCode', params.gateCode)
  params.entranceCode && formData.append('entranceCode', params.entranceCode)
  params.parentCode && formData.append('parentCode', params.parentCode)
  params.acsFile && formData.append('acsFile', params.acsFile)
  params.geometry && formData.append('geometry', JSON.stringify(params.geometry))
  params.region && formData.append('region', JSON.stringify(params.region))
  params.gateCodes?.forEach((code) => {
    formData.append('gateCodes', code)
  })
  params.entranceCodes?.forEach((code) => {
    formData.append('entranceCodes', code)
  })

  return axios.put<LocationModel>(`${CRUD_LOCATION}/${locationCode}`, formData)
}
const PostVenue = (data: VenueModelCreateParams) => axios.post(CRUD_VENUE, data)

const PutVenue = (data: VenueModelCreateParams, code: string) =>
  axios.put<VenueModel>(`${CRUD_VENUE}/${code}`, data)

export {
  // DELETE
  DeleteGates,
  DeleteLocations,
  DeleteVenues,
  DeleteSeatMapByLocationCode,
  // SEARCH
  GetParentGates,
  GetParentLocations,
  GetParentLocationByVenue,
  GetParentVenues,
  GetParentVenueTree,
  GetGates,
  GetLocations,
  GetDefaultLocationByCode,
  GetVenues,
  GetVenueByCode,
  GetGateByCode,
  GetLocationByCode,
  GetLocationByEventCode,
  GetEntranceLocationByCode,
  GetGateLocationByCode,
  GetOccupiedSeatMaps,
  GetOccupiedSeatMapsByProduct,
  GetOccupiedSeatMapsByReservation,
  GetGateScanner,
  GetProductLocationsByCode,
  ExportLocations,
  // CREATE
  PostGate,
  PostLocation,
  PostVenue,
  PostSeatMapByLocationCode,
  // UPDATE
  PutVenue,
  PutGate,
  PutLocation,
  PutSeatMapByLocationCode,
}
