import clsx from 'clsx'
import {useMemo} from 'react'
import {PoralProductWidgetModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {SubProductBox} from './SubProductBox'

interface SubProductsCardProps {
  product: PoralProductWidgetModel[]
}

export const SubProductsCard = ({product}: SubProductsCardProps) => {
  const subProduct = useMemo(() => {
    return product && product.length > 0
      ? product.map((product, idx, array) => {
          const isLast = array.length - 1 === idx
          return (
            <div
              className='col-12 row p-0 m-0'
              key={idx}
              style={{
                height: '73px',
              }}
            >
              <div
                className={clsx('col-3 px-0 mx-0 border border-gray border-start-0 border-top-0')}
              >
                <div
                  className='h-100 ff-admm-bold d-flex flex-column justify-content-center text-white fs-1 text-center bg-primary'
                  style={{
                    boxShadow: '0 1rem 2rem 1rem rgb(0 0 0 / 30%)',
                  }}
                >
                  <div>
                    {product.day
                      ? product.day
                      : product.name && (product.name.split('-')[1] || product.name)}
                  </div>
                  <div>{`${product.total} / ${product.active}`}</div>
                  {/* <div className='fs-3'>
                    {`${product.total}`}{' '}
                    <small
                      style={{
                        fontSize: '7px',
                      }}
                    >
                      Total
                    </small>{' '}
                    / {`${product.active}`}{' '}
                    <small
                      style={{
                        fontSize: '7px',
                      }}
                    >
                      Available
                    </small>
                  </div> */}
                </div>
              </div>
              <div
                className={clsx(
                  'col-9 px-0 mx-0 d-flex flex-row border',
                  !isLast && 'border-bottom-0'
                )}
              >
                {/* <SubProductBox
                  title='Total'
                  value={product.total}
                  titleClassName='text-white'
                  style={{fontSize: '22px'}}
                  isLast={isLast}
                  wrapperClasName='border border-top-0 border-start-0 border-bottom-0 border-gray'
                /> */}
                <SubProductBox
                  title='Shared'
                  value={product.shared}
                  titleClassName='text-white'
                  style={{fontSize: '22px'}}
                  isLast={isLast}
                  wrapperClasName='border border-top-0 border-start-0 border-bottom-0 border-gray'
                />

                <SubProductBox
                  title='Downloaded'
                  value={product.downloaded}
                  titleClassName='text-white'
                  style={{fontSize: '22px'}}
                  isLast={isLast}
                  wrapperClasName='border border-top-0 border-start-0 border-bottom-0 border-gray'
                />

                <SubProductBox
                  title='Wallet'
                  value={product.addedToWallet}
                  titleClassName='text-white'
                  style={{fontSize: '22px'}}
                  isLast={isLast}
                  wrapperClasName='border border-top-0 border-start-0 border-bottom-0 border-gray'
                />
                {/* <SubProductBox
                  title='Available'
                  value={product.active}
                  wrapperClasName='border-end-0 border border-top-0 border-start-0 border-bottom-0 border-gray'
                  titleClassName='text-white'
                  style={{fontSize: '22px'}}
                  isLast={isLast}
                /> */}
              </div>
            </div>
          )
        })
      : null
  }, [product])

  return (
    <div className='container-fluid px-0 mx-0'>
      <div className='row px-0 mx-0'>
        <div className='col-12 px-0 mx-0 row text-uppercase'>{subProduct}</div>
      </div>
    </div>
  )
}
