import {FormikContextType} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {DatePickerInput, SelectInput, TextInput} from '../../../../../components/inputs'
import {DragDropFileInput} from '../../../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileType} from '../../../../../components/inputs/FileInput/FileInput'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikFileInputHelpers} from '../../../../../components/inputs/hooks/useFormikFileInputHelpers'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import {TicketSubmitFormValues} from '../TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'
import {FieldsContainerWithBorderAndLabel} from './FieldsContainerWithBorderAndLabel'
import {FieldWithLabelBox} from './FieldWithLabelBox'
import Tesseract from 'tesseract.js'
import {LicenseCaptureModal} from '../../modals/LicenseCaptureModal'
import {MetronicIconButton} from '../../../../../components/inputs/MetronicIconButton'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {DateUtil} from '../../../../../utils/DateUtil'
interface DriverLicenseFormProps {
  formik: FormikContextType<TicketSubmitFormValues>
  onView?: (file: FileInputValueCollection<ImageInputValue>) => void
  countries: ISOCountryModel[]
  ticket?: TicketWaiverModel
}

export const DriverLicenseForm = ({formik, onView, countries, ticket}: DriverLicenseFormProps) => {
  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  const imageInputHandler = useFormikFileInputHelpers(formik, FileType.IMAGE)
  const [openCamera, setOpenCamera] = useState<boolean>(false)
  const [isLoadingWorker, setIsLoadingWorker] = useState<boolean>(false)
  const handleClaimLicense = useCallback(
    async (image: ImageInputValue) => {
      // const rectangle = {left: 0, top: 0, width: 500, height: 250}
      setIsLoadingWorker(true)
      const worker = await Tesseract.createWorker({
        logger: (m) => () => {},
      })
      await worker.load()
      // await worker.detect(image[0].url, {})
      await worker.loadLanguage('eng')
      await worker.initialize('eng')
      // const {
      //   data: {lines},
      // } = await worker.recognize(image.url)

      // console.log('lines', lines)
      const imageFront = new FileInputValueCollection<ImageInputValue>()
      imageFront.add(image)
      formik.setFieldValue('licenseFront', imageFront)
      setIsLoadingWorker(false)
      setOpenCamera(false)

      await worker.terminate()
      // if (image.url) {
      //   Tesseract.recognize(image.url, 'eng+ar', {
      //     logger: (m) => console.log(m),
      //   })
      //     .catch((err) => {
      //       console.error(err)
      //     })
      //     .then((result) => {
      //       // Get Confidence score

      //     })
      // }
    },
    [formik]
  )
  const countryList = useMemo(() => {
    return countries.map<SelectInputItem>((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, [countries])

  return (
    <FieldsContainerWithBorderAndLabel legend='Driver License Details'>
      <div className='col-12 mb-5 text-end'>
        <MetronicIconButton
          size='md'
          iconType='Shopping'
          iconName='Barcode-scan'
          variant='primary'
          tooltip='Scan license'
          type='button'
          onClick={() => setOpenCamera(true)}
        />
      </div>

      <LicenseCaptureModal
        isOpen={openCamera}
        onSubmit={handleClaimLicense}
        onClose={() => setOpenCamera(false)}
        isLoading={isLoadingWorker}
      />

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='Issued country' arabicLabel='بلد الإصدار'>
          <SelectInput
            placeholder='Select issued country'
            className='h-100'
            classes={{
              input: 'h-100 bg-primary-dw-darker',
            }}
            disabled={formik.isSubmitting}
            errorMessage={formik.errors.licenseCountryCode}
            isTouched={formik.touched.licenseCountryCode}
            items={countryList}
            {...formik.getFieldProps('licenseCountryCode')}
          />
        </FieldWithLabelBox>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='License Number' arabicLabel='رقم الرخصة'>
          <TextInput
            noMargin
            fullWidth
            className='h-100'
            inputWrapperClassName='h-100'
            disabled={formik.isSubmitting}
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            errorMessage={formik.errors.licenseNumber}
            isTouched={formik.touched.licenseNumber}
            placeholder='Enter License Number'
            {...formik.getFieldProps('licenseNumber')}
          />
        </FieldWithLabelBox>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='Issued date' arabicLabel='تاريخ الإصدار'>
          <DatePickerInput
            className='h-100'
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            calendarClassName='h-100'
            noMargin
            disabled={formik.isSubmitting}
            onBlur={() => formik.setFieldTouched('licenseIssueDate', true)}
            maxDate={TODAY}
            value={formik.values.licenseIssueDate}
            {...dateInputHelpers.getProps('licenseIssueDate')}
          />
        </FieldWithLabelBox>
        {formik.errors.licenseIssueDate && formik.touched.licenseIssueDate && (
          <div
            className='fv-plugins-message-container mb-3'
            style={{
              marginLeft: '200px',
            }}
          >
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.licenseIssueDate}</span>
            </div>
          </div>
        )}
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='License Expiry' arabicLabel='تاريخ الانتهاء'>
          <DatePickerInput
            minDate={ticket ? DateUtil.getDateFromApiString(ticket.startedAt) : TODAY}
            className='h-100'
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            calendarClassName='h-100'
            noMargin
            disabled={formik.isSubmitting}
            onBlur={() => formik.setFieldTouched('licenseExpiryDate', true)}
            value={formik.values.licenseExpiryDate}
            {...dateInputHelpers.getProps('licenseExpiryDate')}
          />
        </FieldWithLabelBox>

        {formik.errors.licenseExpiryDate && formik.touched.licenseExpiryDate && (
          <div
            className='fv-plugins-message-container mb-3'
            style={{
              marginLeft: '200px',
            }}
          >
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.licenseExpiryDate}</span>
            </div>
          </div>
        )}
      </div>
      <div className='col-12'>
        <div className='row '>
          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-7 mb-lg-0 position-relative'>
            <DragDropFileInput
              accept={FileType.PNG_JPG_JPEG}
              onClickImageInside={() => onView?.(formik.values.licenseFront)}
              placeholder='Upload Driving License Front'
              placeholderArabicText='تحميل رخصة قيادة من الأمام'
              disabled={formik.isSubmitting}
              classes={{
                dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
              }}
              thumbnailClassName='d-flex align-items-center gap-5'
              icon={{
                iconName: 'Plus',
                iconType: 'Navigation',
              }}
              iconClassname='p-2 rounded-circle border border-white'
              limit={1}
              onBlur={() => formik.setFieldTouched('licenseFront', true)}
              {...imageInputHandler.getProps('licenseFront')}
            />
            {formik.errors.licenseFront && formik.touched.licenseFront && (
              <div className='fv-plugins-message-container mb-3'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.licenseFront}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-12 col-sm-12 col-md-12 col-lg-6 position-relative'>
            <DragDropFileInput
              accept={FileType.PNG_JPG_JPEG}
              placeholder='Upload Driving License back'
              placeholderArabicText='تحميل رخصة قيادة من الخلف'
              onClickImageInside={() => onView?.(formik.values.licenseBack)}
              classes={{
                dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
              }}
              limit={1}
              thumbnailClassName='d-flex align-items-center gap-5'
              icon={{
                iconName: 'Plus',
                iconType: 'Navigation',
              }}
              iconClassname='p-2 rounded-circle border border-white'
              onBlur={() => formik.setFieldTouched('licenseBack', true)}
              disabled={formik.isSubmitting}
              {...imageInputHandler.getProps('licenseBack')}
            />
            {formik.errors.licenseBack && formik.touched.licenseBack && (
              <div className='fv-plugins-message-container mb-3'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.licenseBack}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </FieldsContainerWithBorderAndLabel>
  )
}

const TODAY = new Date()
