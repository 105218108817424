import {useMemo} from 'react'
import {SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {GroupByFilterInput} from '../../../../../components/tables/advanced-filter-inputs/GroupByFilterInput'

export interface TicketTableGroupByFilterInputProps {
  removedItems?: string[]
  groupItems?: SelectInputItem[]
  endAdornment?: string
}

export const TicketTableGroupByFilterInput = ({
  removedItems,
  groupItems,
  endAdornment,
}: TicketTableGroupByFilterInputProps) => {
  const items = useMemo(() => {
    if (removedItems) {
      return (groupItems || TICKET_GROUP_ITEMS).filter((item) => !removedItems.includes(item.value))
    }
    return groupItems || TICKET_GROUP_ITEMS
  }, [removedItems, groupItems])

  return (
    <GroupByFilterInput
      endAdornment={endAdornment}
      label='Grouping'
      items={items}
      placeholder='Group By'
    />
  )
}

const TICKET_GROUP_ITEMS: SelectInputItem[] = [
  {
    label: 'Customer',
    value: 'customerName',
  },
  {
    label: 'Product Name',
    value: 'productName',
  },
  {
    label: 'Ticket',
    value: 'batchId',
  },
]
