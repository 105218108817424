import {useCallback, useMemo, useRef} from 'react'
import clsx from 'clsx'
import {VoucherInputItem, VoucherInputItemValue} from './VoucherInputItem'
import {Button} from '../Button'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {FilterModel} from '../../../models/FilterModel'

export interface VoucherInputProps {
  className?: string
  searchResult?: GlobalSearchModel<VoucherModel>
  onSearch: (filter?: FilterModel) => void
  values: Array<VoucherInputItemValue>
  onChange: (newValues: VoucherInputItemValue[]) => void
  label: string
  disabled?: boolean
}

export const VoucherInput = ({
  className,
  onChange,
  searchResult,
  onSearch,
  values,
  label,
  disabled,
}: VoucherInputProps) => {
  const nextInputId = useRef(0)
  const getInputId = useCallback(() => {
    const inputId = nextInputId.current
    nextInputId.current++
    return inputId
  }, [])

  const handleOnAdd = useCallback(() => {
    const newValues: VoucherInputItemValue[] = [
      ...values,
      {count: 0, voucher: null, id: getInputId()},
    ]
    onChange(newValues)
  }, [values, onChange, getInputId])

  const handleInputChange = useCallback(
    (changed: VoucherInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.id === changed.id)
      if (inputIndex >= 0) {
        const newValues = [...values]
        const modifiedItem = newValues[inputIndex]
        modifiedItem.count = changed.count
        modifiedItem.voucher = changed.voucher
        if (modifiedItem.voucher && modifiedItem.count === 0) {
          modifiedItem.count = 1
        }
        onChange(newValues)
      }
    },
    [values, onChange]
  )

  const handleRemove = useCallback(
    (removed: VoucherInputItemValue) => {
      const inputIndex = values.findIndex((item) => item.voucher?.code === removed.voucher?.code)
      if (inputIndex >= 0) {
        const newValues = [...values]
        newValues.splice(inputIndex, 1)
        onChange(newValues)
      }
    },
    [onChange, values]
  )

  const inputNodes = useMemo(() => {
    const nodes = values.map((item, i) => {
      return (
        <VoucherInputItem
          key={`${item.voucher?.code}${i}`}
          onSearch={onSearch}
          placeholder='Select a Voucher'
          onChange={handleInputChange}
          selectedItems={values}
          searchResult={searchResult}
          label={`${label} ${i + 1}`}
          onRemove={handleRemove}
          value={item}
        />
      )
    })
    return nodes
  }, [values, onSearch, handleInputChange, searchResult, label, handleRemove])

  const hasBlankItem = useMemo(() => {
    return values.some((some) => some.voucher === null)
  }, [values])

  const button = useMemo(() => {
    let label = 'Add Voucher'
    if (hasBlankItem) {
      return null
    }

    return (
      <Button
        size='sm'
        variant='primary'
        onClick={handleOnAdd}
        disabled={disabled}
        uppercase={false}
      >
        {label}
      </Button>
    )
  }, [disabled, handleOnAdd, hasBlankItem])

  return (
    <div className={clsx('', className)}>
      <div>{inputNodes}</div>
      {button}
    </div>
  )
}
