import {useCallback, useEffect, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {BlobUtils} from '../../../utils/BlobUtils'
import {PortalWhatsAppPdf} from '../components/PortalWhatsAppPdf'

import {PrintVoucher} from '../redux/CustomerPortalCRUD'
interface RouteParams {
  voucherCode: string
}
export const PortalDownloadVoucherByWhatsApp = () => {
  const {pushError} = useAlerts()

  const [error, setError] = useState<string | null>(null)
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pdf, setPdf] = useState<string>()

  const {
    params: {voucherCode},
  } = useRouteMatch<RouteParams>()

  const getPdfFile = useCallback(async () => {
    if (voucherCode && voucherCode !== 'undefined') {
      try {
        const {data} = await PrintVoucher(voucherCode)
        if (data) {
          const blobToUrl = URL.createObjectURL(data)
          setPdf(blobToUrl)
        }
      } catch (err) {
        pushError(err)
        setError('Please try again later!')
      } finally {
        setIsLoading(false)
      }
    }
  }, [pushError, voucherCode])

  const downloadPdfFile = useCallback(async () => {
    if (voucherCode && voucherCode !== 'undefined') {
      try {
        setIsDownloadLoading(true)
        const {data, headers} = await PrintVoucher(voucherCode)
        if (data) {
          let name = headers['content-disposition']
          name = name
            .replace('attachment;', '')
            .replace('filename=', '')
            .replaceAll('"', '')
            .split('(')[0]
            .trim()
          if (data) BlobUtils.downloadBlob(data, name)
        }
      } catch (err) {
        pushError(err)
      } finally {
        setIsDownloadLoading(false)
      }
    }
  }, [pushError, voucherCode])

  useEffect(() => {
    getPdfFile()
  }, [getPdfFile])

  return (
    <>
      <PortalWhatsAppPdf
        isLoading={isLoading}
        isDownloadLoading={isDownloadLoading}
        error={error}
        downloadPdfFile={downloadPdfFile}
        pdf={pdf}
      />
    </>
  )
}
