import {ChangeEvent, useCallback, useMemo, useState} from 'react'
import {ShipmentsTicketCard} from './ShipmentsTicketCard'
import {FilterModel} from '../../../../models/FilterModel'
import {useFilterState} from '../../../../components/hooks/useFilterState'
import {TextInput} from '../../../../components/inputs'
import PaginationHelper from '../../../../components/extras/PaginationHelper'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../models/GlobalSearchModel'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {FilterInputContainer} from '../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {DateRangeInput} from '../../../../components/inputs/DateRangeInput'
import {SelectInput, SelectInputItem} from '../../../../components/inputs/SelectInput'
import {DateRange} from '../../../../utils/DateRange'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
export interface ShipmentsTicketCardGridProps {
  data?: GlobalSearchModel<TicketPortalModel> | GroupedSearchModel<TicketPortalModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  dateOnChange?: (dateRange: DateRange) => void
  onRefreshCallback?: () => void
  className?: string
  dateValue?: DateRange
  initialFilters?: FilterModel
  groupedItems?: SelectInputItem[]
  filters?: FilterModel
  setFilter?: (filter: FilterModel) => void
}

export const ShipmentsTicketCardGrid = ({
  data,
  onFilter,
  onRefresh,
  className,
  initialFilters,
  groupedItems,
  dateOnChange,
  dateValue,
  filters,
  setFilter,
}: ShipmentsTicketCardGridProps) => {
  const refreshTicketDebounced = useDebounce(300)
  const [isCollapsed, setIsCollapse] = useState<{key: string; value: boolean}>()
  const {pageNumber, pageSize, setPageNumber, setPageSize} = useFilterState(onFilter, {
    initialFilters,
    filters,
  })

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilter?.({
        filters: {...filters?.filters, search: e.target.value},
      })
      refreshTicketDebounced(() => onRefresh())
    },
    [filters?.filters, onRefresh, refreshTicketDebounced, setFilter]
  )

  const handleGroupByOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (setFilter)
        setFilter({
          ...filters,
          groupBy: e.target.value,
        })
      refreshTicketDebounced(() => onRefresh())
    },
    [filters, onRefresh, refreshTicketDebounced, setFilter]
  )

  const toggleCollapse = useCallback(
    (key: string) => {
      setIsCollapse({
        key: key,
        value: !isCollapsed?.value,
      })
    },
    [isCollapsed]
  )

  const cards = useMemo(() => {
    if (data?.data) {
      return (
        <div className='row'>
          {Array.isArray(data.data)
            ? data.data.map((ticket) => (
                <div key={ticket.code} className='mb-3 col-xs-12 col-sm-6'>
                  <ShipmentsTicketCard className='h-100' data={ticket} />
                </div>
              ))
            : Object.entries(data.data).map(([key, tickets]) => {
                return (
                  <>
                    <div className='col-12'>
                      <div
                        className='mb-0 card p-3 mb-5 w-100 d-flex flex-row justify-content-between'
                        onClick={() => toggleCollapse(key)}
                      >
                        <div className='d-flex flex-column justify-content-center'>{key}</div>
                        <MetronicIconButton
                          className='flex-shrink-0'
                          size='sm'
                          type='button'
                          iconType='Navigation'
                          iconName={
                            isCollapsed?.value && isCollapsed.key === key
                              ? 'Angle-down'
                              : 'Angle-right'
                          }
                        />
                      </div>
                    </div>
                    {isCollapsed?.value && isCollapsed.key === key && (
                      <div>
                        {tickets &&
                          tickets.length > 0 &&
                          tickets.map((ticket: TicketPortalModel) => {
                            return (
                              <div key={ticket.code} className='mb-3 col-xs-12 col-sm-6'>
                                <ShipmentsTicketCard className='h-100' data={ticket} />
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </>
                )
              })}
        </div>
      )
    }
    return null
  }, [data, isCollapsed, toggleCollapse])

  return (
    <div className={className}>
      <TextInput
        className='mb-3'
        noMargin
        placeholder='Search'
        value={filters?.filters?.search?.toString()}
        onChange={handleSearchChange}
      />
      <FilterInputContainer>
        <SelectInput
          items={groupedItems ? groupedItems : []}
          value={filters?.groupBy || ''}
          label='Group By'
          placeholder={filters?.groupBy ? 'None' : 'Group'}
          onChange={handleGroupByOnChange}
        />
        <div
          className='gap-3'
          style={{
            display: '-webkit-inline-box',
          }}
        >
          {dateOnChange && dateValue && (
            <DateRangeInput onChange={dateOnChange} value={dateValue} />
          )}
        </div>
      </FilterInputContainer>
      <div>{cards}</div>
      <PaginationHelper
        currentPageNumber={pageNumber}
        currentPageSize={pageSize}
        onChangePageNumber={setPageNumber}
        onChangePageSize={setPageSize}
        total={data?.total}
      />
    </div>
  )
}
