import clsx from 'clsx'
import {useMemo} from 'react'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'

interface BannerImageProps {
  className?: string
  marginTop?: string
}
export const BannerImage = ({className, marginTop}: BannerImageProps) => {
  const {down} = useBreakpoint()
  const isiPad = useMemo(() => {
    return down('md')
  }, [down])
  return (
    <div
      className={clsx('mb-5', className)}
      style={{
        marginTop: marginTop ? marginTop : isiPad ? '-15px' : '-30px',
        marginLeft: isiPad ? '-15px' : '-30px',
        marginRight: isiPad ? '-15px' : '-30px',
      }}
    >
      <img width='100%' src='/media/saas/admm-banner.jpg' alt='banner-f1' />
    </div>
  )
}
