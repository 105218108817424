import moment from 'moment'
import {Time} from './Time'

export abstract class DateUtil {
  public static getDateFromApiString = (dateString: string) => {
    return new Date(dateString)
  }

  public static convertDateToApiString = (date: Date) => {
    return date.toISOString()
  }

  public static getNewDateWithTime = (time: Time, date: Date) => {
    const dateCopy = new Date(date)
    dateCopy.setHours(time.getHours())
    dateCopy.setMinutes(time.getMinutes())
    dateCopy.setSeconds(time.getSeconds())
    return dateCopy
  }

  public static formatToDateString = (date: Date | string) => {
    return moment(date).format('MMM DD, YYYY')
  }

  public static formatToTimeString = (date: Date | string, ampm?: boolean) => {
    if (ampm) {
      return moment(date).format('LT')
    }
    return moment(date).format('HH:mm:ss')
  }

  public static formatToDateTimeString = (date: Date | string) => {
    return `${DateUtil.formatToDateString(date)} ${DateUtil.formatToTimeString(date)}`
  }

  public static formatToDayUTC04ThreeLetters = (date: Date | string) => {
    return moment(date).utcOffset('+04:00').format('ddd')
  }

  public static formatToDayUTC04FullName = (date: Date | string) => {
    return moment(date).utcOffset('+04:00').format('dddd')
  }
}
