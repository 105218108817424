import {useMemo} from 'react'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {
  PoralProductWidgetModel,
  PortalTicketsValuesProps,
} from '../../../../models/customer-portal/PortalWidgetModal'

interface TicketsDetailsProps {
  items: PortalTicketsValuesProps
  product: PoralProductWidgetModel
}

export const ProductDetails = ({items, product}: TicketsDetailsProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])
  const totalTickets = useMemo(() => {
    return (
      <div
        className='text-white mb-0 ff-admm-bold'
        style={{
          fontSize: isMobile ? '20px' : '22px',
          lineHeight: '27px',
        }}
      >
        {items.total}
      </div>
    )
  }, [isMobile, items.total])

  const shared = useMemo(() => {
    return (
      <div
        className='text-primary ff-admm-bold mb-0'
        style={{
          fontSize: '25px',
          lineHeight: '30px',
        }}
      >
        {items.shared}
      </div>
    )
  }, [items.shared])

  const addedToWallet = useMemo(() => {
    return (
      <div
        className='text-primary ff-admm-bold mb-0'
        style={{
          fontSize: '25px',
          lineHeight: '30px',
        }}
      >
        {items.addedToWallet}
      </div>
    )
  }, [items.addedToWallet])

  const downloaded = useMemo(() => {
    return (
      <div
        className='text-primary ff-admm-bold mb-0'
        style={{
          fontSize: '25px',
          lineHeight: '30px',
        }}
      >
        {items.downloaded}
      </div>
    )
  }, [items.downloaded])

  const active = useMemo(() => {
    return (
      <div
        className='text-primary ff-admm-bold mb-0'
        style={{
          fontSize: '25px',
          lineHeight: '30px',
        }}
      >
        {items.active}
      </div>
    )
  }, [items.active])

  return (
    <div className='container-fluid px-0 mx-0 h-100'>
      <div className='row mx-0 px-0 h-100'>
        <div className='col-6 px-0 text-center border border-gray rounded-0 flex-grow-1'>
          <div className='h-100 d-flex flex-column justify-content-center px-2'>
            <div className=' text-white ff-admm-bold mb-0 d-flex flex-wrap gap-1 justify-content-center text-uppercase'>
              <div className='fs-3 mb-0 d-flex flex-column justify-content-center'>Total</div>
              {totalTickets}
              <div className='fs-3 mb-0 d-flex flex-column justify-content-center'>
                {product.type === 'voucher' ? 'Vouchers' : 'Tickets'}
              </div>
            </div>

            <div className='fs-5 text-white mb-0 d-flex gap-3 justify-content-center text-uppercase mt-5'>
              <div className='mb-0 d-flex flex-column justify-content-center ff-admm-bold'>
                {`${items.childProductTotal} Day Passes`}
              </div>
            </div>
          </div>
        </div>

        <div className='col-6 px-0 column text-center d-flex flex-column'>
          <div className='row mx-0 col-12 px-0 flex-grow-1'>
            <div className='col-6 px-0 border border-gray border-start-0 d-flex flex-column justify-content-center'>
              <div className='fs-7 text-white ff-admm-bold text-uppercase'>Shared</div>
              {shared}
            </div>
            <div className='col-6 px-0 border border-gray border-start-0 d-flex flex-column justify-content-center'>
              <div className='fs-7 text-white ff-admm-bold text-uppercase'>Dowloaded</div>
              {downloaded}
            </div>
          </div>

          <div className='row mx-0 col-12 px-0 flex-grow-1'>
            <div className='col-6 px-0 border border-gray border-start-0 border-top-0 d-flex flex-column justify-content-center'>
              <div className='fs-7 text-white ff-admm-bold text-uppercase'>Wallet</div>
              {addedToWallet}
            </div>
            <div className='col-6 px-0 border border-gray border-start-0 border-top-0 d-flex flex-column justify-content-center'>
              <div className='fs-7 text-white ff-admm-bold text-uppercase'>Available</div>
              {active}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
