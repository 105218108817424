import clsx from 'clsx'
import {useMemo} from 'react'
import {ColorVariant} from '../../../../components/inputs/Button'
import {TicketModelStatus} from '../../../../models/ems/TicketModel'
import {VoucherModelStatus} from '../../../../models/svc/VoucherModel'

export interface PortalShareStatusColumnProps {
  data: {
    status?: TicketModelStatus | VoucherModelStatus
  }
  className?: string
}

export const PortalShareStatusColumn = ({data, className}: PortalShareStatusColumnProps) => {
  const variant = useMemo(() => {
    return getStatusVariant(data.status)
  }, [data])

  return <span className={clsx(`text-${variant} text-uppercase`, className)}>{data.status}</span>
}

const getStatusVariant = (status?: TicketModelStatus | VoucherModelStatus): ColorVariant => {
  switch (status) {
    case 'active':
      return 'white'
    case 'sent':
      return 'warning'
    case 'shared':
      return 'info'
    case 'downloaded':
      return 'info'
    case 'disabled':
      return 'danger'
    case 'cancelled':
      return 'danger'
    default:
      return 'dark'
  }
}
