import {useMemo} from 'react'
import {Badge} from '../../../../components/badge/Badge'
import {MarkdownTooltip} from '../../../../components/charts/MarkdownTooltip'
import {usePieChartTooltipContext} from '../../../../components/charts/PieChart/usePieChartTooltip'
import {PoralProductWidgetModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {GuestModelStatus} from '../../../../models/GuestModel'

export interface ProductPertotalTooltipProps {
  data?: PoralProductWidgetModel[]
}

export const ProductPertotalTooltip = ({data}: ProductPertotalTooltipProps) => {
  const {key} = usePieChartTooltipContext()

  const hoveredData = useMemo(() => {
    if (key && data) {
      return data.find((item) => item.name === key)
    }
  }, [data, key])

  if (!hoveredData || !key) {
    if (key) {
      return (
        <div>
          <Badge variant='primary' uppercase>
            {hoveredData?.name}
          </Badge>
          <MarkdownTooltip>{`**Total**: ${hoveredData?.total || 0}`}</MarkdownTooltip>
        </div>
      )
    }
    return null
  }

  return (
    <div className='mt-4 text-white'>
      <MarkdownTooltip>{`**${hoveredData.name}**
      
      
**Total**: ${hoveredData.total} 

      `}</MarkdownTooltip>
    </div>
  )
}

export const getLabel = (key: GuestModelStatus) => {
  return key.charAt(0).toUpperCase() + key.slice(1)
}
