import clsx from 'clsx'
import {useMemo} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {Button} from '../../../components/inputs/Button'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {DeviceUtils} from '../../../utils/DeviceUtils'
import {BannerImage} from '../components/BannerImage'

export const PortalUserGuidePage = () => {
  const {down} = useBreakpoint()

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const isIphone = useMemo(() => {
    return DeviceUtils.isiPhone()
  }, [])

  return (
    <div>
      <Toolbar>
        {isMobile && (
          <p
            className='text-white text-uppercase ff-admm-bold mb-0'
            style={{
              fontSize: isMobile ? '25px' : '40px',
            }}
          >
            User Guide
          </p>
        )}
      </Toolbar>

      {!isMobile && <BannerImage />}
      <div>
        <div className={clsx('flex-wrap justify-content-between', isMobile ? 'd-block' : 'd-flex')}>
          {!isMobile && (
            <p
              className='text-white text-uppercase ff-admm-bold'
              style={{
                fontSize: isMobile ? '25px' : '40px',
              }}
            >
              User Guide
            </p>
          )}

          <a
            className='d-flex flex-column justify-content-center align-items-start'
            href='/media/pdf/user-guide-2022-ticketing-customer-portal.pdf'
            download
          >
            <Button
              className='text-white ff-admm-bold bg-transparent p-0'
              style={{
                fontSize: isMobile ? '15px' : '20px',
              }}
            >
              <div
                className='btn btn-icon btn-lg me-1'
                style={{height: isMobile ? '20px' : '30px', width: isMobile ? '20px' : '30px'}}
              >
                <KTSVG
                  color='white'
                  className='h-100 w-100 me-5'
                  svgClassName='h-100 w-100'
                  path='/media/icons/duotone/Files/Download.svg'
                />
              </div>
              Download the user guide
            </Button>
          </a>
        </div>
      </div>
      <div
        className='mt-5 mt-xs-5 mt-sm-5 mt-md-0'
        style={{
          height: '64vh',
        }}
      >
        {!isIphone ? (
          <object
            data='/media/pdf/user-guide-2022-ticketing-customer-portal.pdf#toolbar=0'
            width='100%'
            height='100%'
            type='application/pdf'
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <div className='position-absolute top-50 start-50 translate-middle text-center  w-100 px-3'>
              <p className='text-white fs-6'>Sorry, your browser doesn't support PDFs!</p>
              <a href='/media/pdf/user-guide-2022-ticketing-customer-portal.pdf#toolbar=0' download>
                <Button
                  className='d-flex flex-column align-items-center gap-3 text-center text-white m-auto py-5'
                  style={{
                    backgroundColor: '#0a393f',
                  }}
                >
                  Download Instead
                  <KTSVG
                    path='/media/icons/duotone/Files/Download.svg'
                    className='svg-icon-5x svg-icon-primary mb-2'
                  />
                </Button>
              </a>
            </div>
          </object>
        ) : (
          <div className='position-absolute top-50 start-50 translate-middle text-center  w-100 px-3'>
            <p className='text-white fs-6'>Sorry, your browser doesn't support PDFs!</p>
            <a href='/media/pdf/user-guide-2022-ticketing-customer-portal.pdf#toolbar=0' download>
              <Button
                className='d-flex flex-column align-items-center gap-3 text-center text-white m-auto py-5'
                style={{
                  backgroundColor: '#0a393f',
                }}
              >
                Download Instead
                <KTSVG
                  path='/media/icons/duotone/Files/Download.svg'
                  className='svg-icon-5x svg-icon-primary mb-2'
                />
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
