import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {DatePickerInputProps} from '../DatePickerInput'

export type UseFormikDatePickerInputHelpersProps = Pick<DatePickerInputProps, 'onChange' | 'value'>
export const useFormikDatePickerInputHelpers = (formik: FormikContextType<any>) => {
  const getProps = useCallback(
    (name: string): UseFormikDatePickerInputHelpersProps => {
      return {
        onChange: (value) => {
          formik.setFieldValue(name, value)
        },
        value: formik.values[name],
      }
    },
    [formik]
  )

  return {
    getProps,
  }
}
