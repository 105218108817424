import {useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {TableRowId} from '../../../components/tables/TableRow'
import {FilterModel} from '../../../models/FilterModel'
import {TicketTableGroupByFilterInput} from '../../default/ems/components/tables/TicketTableGroupByFilterInput'
import {actions} from '../redux/CustomerPortalRedux'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {ShipmentsPortalTable} from '../components/tables/ShipmentsTable/ShipmentsPortalTable'
import {ShipmentCardGrid} from '../components/ShipmentCard/ShipmentCardGrid'

interface PortalShipmentsPageProps {
  shipmentsTabCode: string
  tabCode?: string
}

export const PortalShipmentsPage = ({shipmentsTabCode, tabCode}: PortalShipmentsPageProps) => {
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {setFilter: setFilterShipments, filters: shipmentsFilter} =
    useEntityFilter('customer-portal-booking')
  const dispatch = useDispatch()
  const refreshDebounced = useDebounce(300)
  const shipments = useRootStateSelector((state) => state.customerPortal.bookings)
  const shipmentFilterMemo = useMemo(() => shipmentsFilter || {}, [shipmentsFilter])
  const {down} = useBreakpoint()
  const refresh = useCallback(() => {
    dispatch(actions.bookings.search())
  }, [dispatch])

  const onFilterShipmentsHandler = useCallback(
    (filter: FilterModel) => {
      setFilterShipments({
        ...shipmentsFilter,
        ...filter,
        filters: {...filter?.filters},
      })

      refreshDebounced(() => refresh())
    },
    [setFilterShipments, shipmentsFilter, refreshDebounced, refresh]
  )

  const shipmentTable = useMemo(() => {
    return shipmentsTabCode ? (
      <ShipmentsPortalTable
        expandedGroups={expandedGroups}
        onExpandedGroupChange={setExpandedGroups}
        filters={shipmentFilterMemo}
        data={shipments}
        onFilter={onFilterShipmentsHandler}
        shipmentsTabCode={shipmentsTabCode}
        advancedFilters={
          <div className='d-flex flex-wrap flex-row w-100 gap-3'>
            <TicketTableGroupByFilterInput groupItems={SHIPMENTS_GROUP_ITEMS} />
          </div>
        }
      />
    ) : null
  }, [shipmentsTabCode, expandedGroups, shipmentFilterMemo, shipments, onFilterShipmentsHandler])

  return (
    <>
      {down('sm') ? (
        <div className='d-sm-block d-xs-block d-md-none'>
          <ShipmentCardGrid
            className='d-md-none'
            data={shipments}
            filters={shipmentFilterMemo}
            onFilter={onFilterShipmentsHandler}
            groupedItems={SHIPMENTS_GROUP_ITEMS}
            setFilter={setFilterShipments}
            onRefresh={refresh}
            shipmentsTabCode={shipmentsTabCode}
            tabCode={tabCode}
          />
        </div>
      ) : (
        <div className='d-none d-md-block'>{shipmentTable}</div>
      )}
    </>
  )
}

const SHIPMENTS_GROUP_ITEMS: SelectInputItem[] = [
  {
    label: 'Status',
    value: 'status',
  },
]
