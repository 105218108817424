import clsx from 'clsx'
import {useMemo} from 'react'
import {ColorVariant} from '../../../../components/inputs/Button'
import {PortalShareModelActivityStatus} from '../../../../models/ems/TicketModel'

export interface PortaTicketStatusActivityColumnProps {
  data: {
    statusActivity?: PortalShareModelActivityStatus
  }
  className?: string
}

export const PortaTicketStatusActivityColumn = ({
  data,
  className,
}: PortaTicketStatusActivityColumnProps) => {
  const variant = useMemo(() => {
    return getStatusVariant(data.statusActivity)
  }, [data])

  return (
    <span className={clsx(`text-${variant} text-uppercase`, className)}>{data.statusActivity}</span>
  )
}

const getStatusVariant = (statusActivity?: PortalShareModelActivityStatus): ColorVariant => {
  switch (statusActivity) {
    case 'active':
      return 'white'

    case 'used':
      return 'warning'

    case 'expired':
      return 'danger'
    case 'checked-in':
      return 'success'
    case 'checked-out':
      return 'dark'

    default:
      return 'dark'
  }
}
