import {useCustomerPortalWidget} from '../hooks/useCustomerPortalWidget'
import {BannerImage} from '../components/BannerImage'
import {DashboardCards} from '../components/DashboardCards/DashbordsCards'
import {EVENT_CODE} from '../../../../config/env'
import {useMemo} from 'react'
import {PortalWidgetModal} from '../../../models/customer-portal/PortalWidgetModal'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {Link} from 'react-router-dom'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {DashboardProgressbar} from '../components/DashboardCards/DashboardProgressbar'

const DEFAULT_IMAGE = '/media/products/dashboard.png'
const widgets: (keyof PortalWidgetModal)[] = ['WIDGETCP01', 'WIDGETCP02', 'WIDGETCP03']
export const PortaldashboardPage = () => {
  const {down} = useBreakpoint()

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const {data} = useCustomerPortalWidget({
    widgets,
    eventCode: EVENT_CODE,
  })

  const WIDGETCP01 = useMemo(() => {
    return data.WIDGETCP01
  }, [data.WIDGETCP01])

  const WIDGETCP02 = useMemo(() => {
    return data.WIDGETCP02?.[0]
  }, [data.WIDGETCP02])

  const WIDGETCP03 = useMemo(() => {
    return data.WIDGETCP03
  }, [data.WIDGETCP03])

  return (
    <div>
      <Toolbar>
        <div className='ff-admm-bold fs-1 text-white mw-sm-100 text-iuppercase d-flex flex-column justify-content-center'>
          DASHBOARD & SUMMARY
        </div>
      </Toolbar>
      {!isMobile && <BannerImage />}

      <div className='container-fluid w-100 mx-0 px-0 overflow-hidden'>
        <div className='card row flex-row px-0 mx-0 pt-2 ps-3 pe-3 pb-5'>
          <div className='col-12 px-0 mx-0 d-flex flex-wrap px-3 pt-3 justify-content-between mb-3'>
            <p className='ff-admm-regular fs-3 text-gray d-flex mb-0 flex-column justify-content-center'>
              <span>
                Welcome to your Dashboard, here is a summary of your tickets and vouchers.
                <br />
                Please visit our <Link to='/user-guide'>User Guide.</Link>
              </span>
            </p>
          </div>
          {WIDGETCP02 && <DashboardProgressbar WIDGETCP02={WIDGETCP02} />}
          {WIDGETCP01 && (
            <DashboardCards
              widgettcp03={WIDGETCP03}
              products={WIDGETCP01}
              defaultImage={DEFAULT_IMAGE}
            />
          )}
        </div>
      </div>
    </div>
  )
}
