import {useCallback, useMemo} from 'react'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {BlobUtils} from '../../../utils/BlobUtils'
import {DeviceUtils} from '../../../utils/DeviceUtils'
import {SwalUtils} from '../../../utils/SwalUtils'
import {InitialValuesProps} from '../components/modals/EmailModal'

import {WhatsAppInitialVoucherValuesProps} from '../components/modals/WhatsAppVoucherModal'
import {
  DisableVoucher,
  DownloadVoucher,
  PostVoucherAddToWallet,
  ShareTicketProps,
  ShareVoucher,
} from '../redux/CustomerPortalCRUD'

interface Props {
  onRefresh?: () => void
  onRefreshCallback?: () => void
  clearSelected?: () => void
}
export const useVoucherTableActions = ({onRefresh, onRefreshCallback, clearSelected}: Props) => {
  const {isKeyLoading, isLoading, setIsLoading} = useLoadingState()
  const {pushError, push} = useAlerts()

  const disable = useCallback(
    async (codes: string[]) => {
      const confirmation = await SwalUtils.confirm({
        titleText: 'Disable Voucher',
        text: 'Are you sure?',
        confirmButtonText: "Don't Disable!",
        icon: 'question',
        cancelButtonText: 'Disable!',
      })
      if (!confirmation.isConfirmed) {
        const doneLoading = setIsLoading(codes)
        try {
          await DisableVoucher(codes)
          push({
            message: `successfully disabled.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
        } catch (e) {
          pushError(e)
        } finally {
          doneLoading()
        }
      }
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const download = useCallback(
    async (codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        const {data, headers} = await DownloadVoucher({codes})
        let name = headers['content-disposition']
        name = name
          .replace('attachment;', '')
          .replace('filename=', '')
          .replaceAll('"', '')
          .split('(')[0]
          .trim()
        BlobUtils.downloadBlob(data, name)
        push({
          message: `Successfully downloaded.`,
          timeout: 5000,
          variant: 'success',
        })
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const shareByEmail = useCallback(
    async (values: InitialValuesProps, codes: string[]) => {
      const doneLoading = setIsLoading(codes)
      try {
        if (values.email) {
          const payload: ShareTicketProps = {
            email: values.email,
            codes: codes,
            type: 'email',
            name: values.name,
          }
          const {data} = await ShareVoucher(payload)
          push({
            message: `Successfully shared.`,
            timeout: 5000,
            variant: 'success',
          })
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          clearSelected && clearSelected()
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        doneLoading()
      }
    },
    [clearSelected, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const shareByWhatsApp = useCallback(
    async (
      values: WhatsAppInitialVoucherValuesProps,
      codes: string[],
      vouchers: VoucherModel[]
    ) => {
      const doneLoading = setIsLoading(codes)
      try {
        if (values.name) {
          const payload: ShareTicketProps = {
            codes: codes,
            type: 'whatsapp',
            name: values.name,
            mobile: values.mobile,
          }
          const {data} = await ShareVoucher(payload)
          push({
            message: `Successfully shared.`,
            timeout: 5000,
            variant: 'success',
          })
          const text = data.links.map((item, index) => {
            const voucher = vouchers[index]?.name || null
            return `${voucher ? '*' + voucher + '*%0A' : ''}${item.link}%0A`
          })
          window.open(`https://api.whatsapp.com/send?phone=${values.mobile}&text=${text}`)
          onRefresh && onRefresh()
          onRefreshCallback && onRefreshCallback()
          clearSelected && clearSelected()
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        doneLoading()
      }
    },

    [clearSelected, onRefresh, onRefreshCallback, push, pushError, setIsLoading]
  )

  const addToWallet = useCallback(
    async (voucher: VoucherModel, eventCode: string) => {
      try {
        if (DeviceUtils.isApple()) {
          const {data, headers} = await PostVoucherAddToWallet({
            eventCode: eventCode,
            walletCode: voucher.code,
            responseType: 'blob',
          })
          DeviceUtils.downloadPkpass(data, headers['content-disposition'])
          push({
            message: `Successfully added.`,
            timeout: 5000,
            variant: 'success',
          })
          return data
        } else {
          const {data} = await PostVoucherAddToWallet({
            eventCode: eventCode,
            walletCode: voucher.code,
            responseType: 'json',
          })
          window.open(data.url, '_blank')
          push({
            message: `Successfully added.`,
            timeout: 5000,
            variant: 'success',
          })
          return data
        }
      } catch (err) {
        pushError(err)
        return err
      } finally {
        onRefresh && onRefresh()
        onRefreshCallback && onRefreshCallback()
        clearSelected && clearSelected()
        return true
      }
    },
    [clearSelected, onRefresh, onRefreshCallback, push, pushError]
  )

  const handleDisableBulk = useCallback(
    (data: VoucherModel[]) => {
      disable(data.map((bookings) => bookings.code))
    },
    [disable]
  )

  const handleDisableSingle = useCallback(
    (data: VoucherModel) => {
      disable([data.code])
    },
    [disable]
  )

  const handleDownloadBulk = useCallback(
    async (data: VoucherModel[]) => {
      for (let item of data) {
        await download([item.code])
      }
    },
    [download]
  )

  const handleShareByEmailBulk = useCallback(
    (values: InitialValuesProps, data: VoucherModel[]) => {
      return shareByEmail(
        values,
        data.map((item) => item.code)
      )
    },
    [shareByEmail]
  )

  const handleShareByEmailSingle = useCallback(
    (values: InitialValuesProps, data: VoucherModel) => {
      return shareByEmail(values, [data.code])
    },
    [shareByEmail]
  )

  const handleShareByWhatsAppBulk = useCallback(
    (values: WhatsAppInitialVoucherValuesProps, data: VoucherModel[]) => {
      return shareByWhatsApp(
        values,
        data.map((item) => item.code),
        data
      )
    },
    [shareByWhatsApp]
  )

  const handleShareByWhatsAppSingle = useCallback(
    (values: WhatsAppInitialVoucherValuesProps, data: VoucherModel) => {
      shareByWhatsApp(values, [data.code], [data])
    },
    [shareByWhatsApp]
  )

  return useMemo(
    () => ({
      isKeyLoading,
      isLoading,
      handleDisableBulk,
      handleDisableSingle,
      handleDownloadBulk,
      handleShareByWhatsAppBulk,
      handleShareByWhatsAppSingle,
      handleShareByEmailSingle,
      handleShareByEmailBulk,
      addToWallet,
    }),
    [
      isKeyLoading,
      isLoading,
      handleDisableBulk,
      handleDisableSingle,
      handleDownloadBulk,
      handleShareByWhatsAppBulk,
      handleShareByWhatsAppSingle,
      handleShareByEmailSingle,
      handleShareByEmailBulk,
      addToWallet,
    ]
  )
}

export const isVoucherShareable = (voucher: VoucherModel) => {
  return voucher.status === 'active' || voucher.status === 'sent'
}

export const isVoucherIsAddToWallet = (voucher: VoucherModel) => {
  return voucher.status !== 'disabled'
}
