import clsx from 'clsx'
import {ChangeEvent, ReactNode, useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import {SelectGroupByButtons} from '../../../modules/customer-portal/pages/PortalTicketPage'
import PaginationHelper from '../../extras/PaginationHelper'
import {useFilterState} from '../../hooks/useFilterState'
import {useOnChange} from '../../hooks/useOnChange'
import {useSafeStateUpdate} from '../../hooks/useSafeStateUpdate'
import {TextInput} from '../../inputs'
import {MetronicIcon} from '../../inputs/MetronicIcon'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {CustomerPortalTable} from '../CustomerPortalTable'
import {PORTAL_INITIAL_TABLE_PAGE_SIZE} from '../FilterTable'
import {GroupedTableData, TableProps} from '../Table'
import {TableColumnOptions} from '../TableColumn'
import {TableRowId} from '../TableRow'
import {TableSelectionCountLabel} from '../TableSelectionCountLabel'
import {TableFilterStateContext} from '../useTableFilterState'

type FilterTableTableProps<T> = Pick<
  TableProps<T>,
  | 'selection'
  | 'onSelectionChange'
  | 'rightToolbar'
  | 'idExtractor'
  | 'actions'
  | 'hiddenColumns'
  | 'onHiddenColumnsChange'
  | 'title'
  | 'hideSelectAll'
  | 'actions'
  | 'expandedGroups'
  | 'onExpandedGroupsChange'
  | 'grouping'
  | 'groupRowActions'
>

export type ControlledFilterPortalTableColumnOptions<T> = TableColumnOptions<T> & {
  groupable?: boolean
}

export interface ControlledFilterPortalTableProps<T> extends FilterTableTableProps<T> {
  onFilter: (filter: FilterModel) => void
  searchResults?: GlobalSearchModel<T> | GroupedSearchModel<T>
  filters: FilterModel
  noPagination?: boolean
  selectionAction?: ReactNode
  leftToolbar?: ReactNode
  columns: ControlledFilterPortalTableColumnOptions<T>[]
  className?: string
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  advancedGroupBy?: ReactNode
  filterOnMount?: boolean
  groupedItem?: SelectGroupByButtons | null
  expandedRows?: TableRowId[]
}

export const ControlledFilterPortalTable = <T,>({
  onFilter,
  noPagination,
  leftToolbar,
  selectionAction,
  selection,
  onSelectionChange,
  columns,
  rightToolbar,
  className,
  initialFilters,
  advancedFilters: advancedFiltersNode,
  advancedGroupBy,
  searchResults,
  filters,
  filterOnMount,
  groupedItem,
  expandedRows,
  groupRowActions,
  ...tableProps
}: ControlledFilterPortalTableProps<T>) => {
  const [filterShown, setFilterShown] = useState(false)
  const [groupByShown, setGroupByShown] = useState(false)
  const safeUpdate = useSafeStateUpdate()

  const toggleFilter = useCallback(() => {
    setFilterShown(!filterShown)
    setGroupByShown(false)
  }, [filterShown])

  const toggleGroupBy = useCallback(() => {
    setGroupByShown(!groupByShown)
    setFilterShown(false)
  }, [groupByShown])

  useOnChange(searchResults?.data, () => {
    safeUpdate(() => onSelectionChange && onSelectionChange([]))
  })

  const filterState = useFilterState(onFilter, {initialFilters, filters})

  const {
    sortColumn,
    setSortColumn,
    pageNumber,
    setPageSize,
    setPageNumber,
    setSearch,
    search,
    clearFilters,
    hasFilters,
  } = filterState

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  const searchAction = useMemo(() => {
    return (
      <div className='w-100'>
        <div className='table-left-toolbar'>
          <TextInput
            className='filter-table-search-input'
            inputClassName='rounded-0'
            inputWrapperClassName='bg-primary-cp-dark rounded-0'
            noMargin
            placeholder='Search'
            value={search}
            onChange={handleSearchChange}
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
            }
          />

          {leftToolbar}
          {hasFilters && (
            <MetronicIconButton
              iconType='Code'
              iconName='Stop'
              activeColor='danger'
              size='md'
              onClick={clearFilters}
              tooltip='Clear Filters'
              color='dark'
              className='bg-transparent'
            />
          )}
        </div>
      </div>
    )
  }, [search, handleSearchChange, leftToolbar, hasFilters, clearFilters])

  const bodyActions = useMemo(() => {
    return (
      <div className={clsx('mt-0 mb-2 filters-box-cp', (filterShown || groupByShown) && 'mb-5')}>
        {advancedFiltersNode && filterShown && (
          <div className='px-5 py-4'>{advancedFiltersNode}</div>
        )}
        {advancedGroupBy && groupByShown && <div className='px-5 py-4'>{advancedGroupBy}</div>}
        {selection && selection.length && selection.length > 0 ? (
          <div className='px-5 py-4'>
            <TableSelectionCountLabel
              count={selection?.length}
              className='text-white text-uppercase'
            />
          </div>
        ) : null}
        {selectionAction && (
          <div
            className={clsx('table-left-toolbar px-5', selection && selection.length > 0 && 'pb-2')}
          >
            {selectionAction}
          </div>
        )}
      </div>
    )
  }, [advancedFiltersNode, advancedGroupBy, filterShown, groupByShown, selection, selectionAction])

  const handleChangePageNumber = useCallback(
    (pageNumber: number) => {
      setPageNumber(pageNumber)
      onSelectionChange && onSelectionChange([])
    },
    [onSelectionChange, setPageNumber]
  )

  const filterButton = useMemo(() => {
    const hasAdvancedFilterInputs = Boolean(advancedFiltersNode)
    if (hasAdvancedFilterInputs) {
      return (
        <div
          className={clsx(
            'ps-4 cursor-pointer d-flex justify-content-between filter-cp-button',
            filterShown ? 'active' : 'not-active'
          )}
          onClick={toggleFilter}
        >
          <div className='mt-3'>
            <span className='fs-5 fw-bold text-white ff-admm-bold text-uppercase'>Filter</span>
          </div>
          <div>
            <MetronicIconButton
              iconType='Text'
              iconName='Filter'
              size='md'
              color='white'
              tooltip='Toggle filter'
              className='bg-transparent'
            />
          </div>
        </div>
      )
    }
    return null
  }, [advancedFiltersNode, filterShown, toggleFilter])

  const groupedTableData = useMemo(() => {
    if (searchResults) {
      if (!Array.isArray(searchResults.data)) {
        if (filters?.groupBy) {
          const groupedColumn = columns.find((c) => filters.groupBy === c.field)
          if (groupedColumn) {
            return Object.entries(searchResults.data).map<GroupedTableData<T>>(([key, value]) => ({
              key,
              data: value,
              field: groupedColumn.field,
              label: key,
            }))
          }
        }
      }
    }
  }, [columns, filters.groupBy, searchResults])

  const tableData = useMemo(() => {
    if (Array.isArray(searchResults?.data)) {
      return searchResults?.data
    }
  }, [searchResults?.data])

  const itemGroupBy = useMemo(() => {
    if (groupedItem)
      if (Array.isArray(groupedItem)) return groupedItem.toString()
      else return groupedItem.label
    else return null
  }, [groupedItem])

  const groupByButton = useMemo(() => {
    if (advancedGroupBy) {
      return (
        <div
          className={clsx(
            'ps-4 cursor-pointer d-flex justify-content-between filter-cp-button',
            groupByShown ? 'active' : 'not-active'
          )}
          onClick={toggleGroupBy}
        >
          <div className='mt-3'>
            <span className='fs-5 fw-bold text-white ff-admm-bold text-uppercase'>{`Group By${
              itemGroupBy ? ': ' + itemGroupBy : ''
            }`}</span>
          </div>
          <div>
            <MetronicIconButton
              disabled={true}
              color='white'
              size='md'
              className='bg-transparent'
              tooltip='Group by'
              iconType='Layout'
              iconName='Layout-grid'
            />
          </div>
        </div>
      )
    }

    return null
  }, [advancedGroupBy, groupByShown, toggleGroupBy, itemGroupBy])

  return (
    <TableFilterStateContext.Provider value={filterState}>
      <div className={className}>
        <CustomerPortalTable
          {...tableProps}
          expandedRows={expandedRows}
          columns={columns}
          data={tableData}
          groupedData={groupedTableData}
          onSelectionChange={onSelectionChange}
          selection={selection}
          leftToolbar={searchAction}
          sortedColumn={sortColumn}
          onSort={setSortColumn}
          body={bodyActions}
          groupRowActions={groupRowActions}
          grouping={filters?.groupBy}
          rightToolbar={
            <>
              {rightToolbar}
              {advancedGroupBy && groupByButton}
              {filterButton}
            </>
          }
        />
        {!noPagination && (
          <PaginationHelper
            currentPageNumber={searchResults?.page || pageNumber}
            currentPageSize={searchResults?.limit || PORTAL_INITIAL_TABLE_PAGE_SIZE}
            onChangePageNumber={handleChangePageNumber}
            onChangePageSize={setPageSize}
            total={searchResults?.total}
            pageListOptions={[
              {value: '12', text: '12'},
              {value: '20', text: '20'},
              {value: '40', text: '40'},
              {value: '60', text: '60'},
            ]}
          />
        )}
      </div>
    </TableFilterStateContext.Provider>
  )
}
