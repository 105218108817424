import {FC, useCallback, useEffect, useMemo} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Toolbar} from '../../../components/layouts/DefaultLayout/Toolbar'
import {NavHeader} from '../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {Redirect, useRouteMatch} from 'react-router-dom'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {AuthNavigationSectionMenuItem} from '../../../../config/portalDrawer'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'
import {RootState} from '../../../../setup'
import {PortalTicketPage} from './PortalTicketPage'
import {PortalVoucherPage} from './PortalVoucherPage'
import {usePoratlTabs} from '../hooks/usePortalTabs'
import {PortalShipmentsPage} from './PortalShipmentsPage'
import {BannerImage} from '../components/BannerImage'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {useIsGSSSPP} from '../hooks/useIsGSSSPP'
export interface RouteParams {
  productCategoryCode?: string
  voucherTabCode?: string
  tabCode?: string
  ticketCode?: string
  voucherCode?: string
  shipmentsTabCode?: string
  shipmentCode?: string
}

export interface TabProps {
  code: string
  name: string
}
const PortalPage: FC = () => {
  const {
    params: {productCategoryCode, voucherTabCode, tabCode, shipmentsTabCode},
  } = useRouteMatch<RouteParams>()

  const {down} = useBreakpoint()
  const {isSPP} = useIsGSSSPP({productCategoryCode})

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const {
    refreshVouchertabs,
    refreshTicketProductCategoryTabs,
    refreshShipmentstabs,
    setSubTabs,
    subTabs,
    routeName,
  } = usePoratlTabs()

  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]

  const isInitialUrl = useMemo(() => {
    return !productCategoryCode && !voucherTabCode && !shipmentsTabCode
  }, [productCategoryCode, shipmentsTabCode, voucherTabCode])

  const redirectInitialRoute = useMemo(() => {
    if (isInitialUrl) {
      if (menuesRDX && menuesRDX.length > 0) {
        if (menuesRDX[0].slug) {
          return <Redirect to={`/product-category/${menuesRDX[0].code}`} />
        } else {
          return <Redirect to={`/voucher-tab/${menuesRDX[0].code}`} />
        }
      } else {
        return <Redirect to='/dashboard' />
      }
    }
  }, [isInitialUrl, menuesRDX])

  const isGCC = useMemo(() => {
    if (menuesRDX) {
      const found = menuesRDX.find(
        (item) =>
          item.code === productCategoryCode &&
          item.slug &&
          [
            'GC',
            'GCC',
            'GC & SPP',
            'GCC & SPP',
            'Golden Circle',
            'Golden circle',
            'golden circle',
            'Golden Circle Concert',
            'GOLDEN CIRCLE',
            'GOLDEN CIRCLE CONCERT',
          ].includes(item.slug)
      )
      if (found) return true
      else return false
    }
    return false
  }, [menuesRDX, productCategoryCode])

  const description = useMemo(() => {
    if (!isGCC && !isSPP && !voucherTabCode) {
      if (tabCode === 'active') return 'Here is a list of your available race tickets'
      if (tabCode === 'shared') return 'Here is a list of your shared race tickets'
      if (tabCode === 'downloaded') return 'Here is a list of your downloaded race tickets'
      if (tabCode === 'added-to-wallet')
        return 'Here is a list of your added to wallet race tickets'
    }
    if (isGCC) {
      if (tabCode === 'active')
        return 'Here is a list of your available Golden Circle Concert tickets'
      if (tabCode === 'shared') return 'Here is a list of your shared Golden Circle Concert tickets'
      if (tabCode === 'downloaded')
        return 'Here is a list of your downloaded Golden Circle Concert tickets'
      if (tabCode === 'added-to-wallet')
        return 'Here is a list of your added to wallet Golden Circle tickets'
    }

    if (isSPP) {
      if (tabCode === 'active') return 'Here is a list of your available Super Park Pass tickets'
      if (tabCode === 'shared') return 'Here is a list of your shared Super Park Pass tickets'
      if (tabCode === 'downloaded')
        return 'Here is a list of your downloaded Super Park Pass tickets'
      if (tabCode === 'added-to-wallet')
        return 'Here is a list of your added to wallet Super Park Pass tickets'
    }

    if (voucherTabCode) {
      if (tabCode === 'active') return 'Here is a list of your available vouchers'
      if (tabCode === 'shared') return 'Here is a list of your shared vouchers'
      if (tabCode === 'downloaded') return 'Here is a list of your downloaded vouchers'
      if (tabCode === 'added-to-wallet') return 'Here is a list of your added to wallet vouchers'
    }
    return null
  }, [isGCC, isSPP, tabCode, voucherTabCode])

  useEffect(() => {
    setSubTabs([])
  }, [setSubTabs])

  useOnChange(voucherTabCode, () => {
    if (voucherTabCode) refreshVouchertabs()
  })

  const productCategorySlug = useMemo(() => {
    if (productCategoryCode && menuesRDX && menuesRDX.length > 0) {
      const found = menuesRDX.find((item) => item.code === productCategoryCode)
      return found?.slug
    }
    return null
  }, [menuesRDX, productCategoryCode])

  const refreshProductSubTabs = useCallback(() => {
    if (productCategorySlug) refreshTicketProductCategoryTabs(productCategorySlug)
  }, [productCategorySlug, refreshTicketProductCategoryTabs])

  useOnChange(productCategorySlug, () => {
    if (productCategorySlug && productCategoryCode) refreshProductSubTabs()
  })

  useOnChange(shipmentsTabCode, () => {
    if (shipmentsTabCode) refreshShipmentstabs()
  })

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `${routeName}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [routeName, subTabs])

  return (
    <>
      {redirectInitialRoute}
      <Toolbar>
        <NavHeader className='text-uppercase fs-5' menus={subMenus} type='portal' />
      </Toolbar>
      {!isMobile && <BannerImage />}

      {description && (
        <div className='ff-admm-regular fs-3 text-gray mt-sm-0 mt-md-5 px-0 px-md-3'>
          {description}
        </div>
      )}

      {productCategoryCode && tabCode && productCategorySlug && (
        <PortalTicketPage
          key={tabCode}
          productCategorySlug={productCategorySlug}
          productCategoryCode={productCategoryCode}
          tabCode={tabCode}
          refreshCallBack={refreshProductSubTabs}
        />
      )}
      {voucherTabCode && tabCode && (
        <PortalVoucherPage
          voucherTabCode={voucherTabCode}
          tabCode={tabCode}
          refreshCallBack={() => {
            refreshVouchertabs()
          }}
        />
      )}
      {shipmentsTabCode && tabCode && (
        <PortalShipmentsPage shipmentsTabCode={shipmentsTabCode} tabCode={tabCode} />
      )}
    </>
  )
}

export default PortalPage
