import clsx from 'clsx'
import {ReactNode, useCallback, useMemo} from 'react'
import {useBooleanState} from '../../hooks/useBooleanState'
import {useClickAwayListener} from '../../hooks/useClickAwayListener'
import {MetronicIcon} from '../MetronicIcon'
import {MetronicIconButton} from '../MetronicIconButton'
import {SelectInputItem} from '../SelectInput'
import styles from './MultiSelectInput.module.scss'
import {MultiSelectInputItem} from './MultiSelectInputItem'

export interface MultiSelectInputProps {
  hasSelectAll?: boolean
  value?: string[]
  onChange?: (values: string[]) => void
  items: SelectInputItem[]
  disabled?: boolean
  allowClear?: boolean
  label?: string
  placeholder?: string
  endAdornment?: ReactNode
  className?: string
  isLabelUpperCase?: boolean
  inputClassName?: string
}

export const MultiSelectInput = ({
  hasSelectAll,
  onChange,
  value,
  allowClear,
  disabled,
  items,
  label,
  placeholder,
  endAdornment,
  className,
  isLabelUpperCase,
  inputClassName,
}: MultiSelectInputProps) => {
  const {disableState: hideList, enableState: showList, state: isListShown} = useBooleanState(false)
  const ref = useClickAwayListener<HTMLDivElement>({
    onClickAway: hideList,
  })

  const handleClear = useCallback(() => {
    onChange?.([])
  }, [onChange])

  const checked = useMemo(() => value || [], [value])

  const toggleItem = useCallback(
    (item: SelectInputItem) => {
      const newValues = [...checked]
      const index = newValues.indexOf(item.value)
      if (index >= 0) {
        newValues.splice(index, 1)
      } else {
        newValues.push(item.value)
      }
      onChange?.(newValues)
    },
    [checked, onChange]
  )

  const valueList = useMemo(
    () =>
      items
        .reduce<string[]>((acc, item) => {
          if (value?.includes(item.value)) {
            acc.push(item.label)
          }
          return acc
        }, [])
        .join(', '),
    [items, value]
  )

  const isClearButtonShown = useMemo(() => {
    return allowClear && Boolean(value?.length) && !disabled
  }, [allowClear, disabled, value?.length])

  const isAllSelected = useMemo(
    () => items.every((item) => value && value.includes(item.value)),
    [items, value]
  )

  const handleSelectAll = useCallback(() => {
    if (isAllSelected) {
      onChange?.([])
    } else {
      onChange?.(items.map((item) => item.value))
    }
  }, [isAllSelected, items, onChange])

  return (
    <div className={clsx(styles.root, 'mb-3', className)}>
      {label && (
        <label
          className={clsx('form-label', {
            'text-uppercase': isLabelUpperCase,
          })}
        >
          {label}
        </label>
      )}
      <div
        role='button'
        className={clsx(
          styles.input,
          {[styles.inputHasClear]: isClearButtonShown},
          'form-control form-control-solid',
          inputClassName
        )}
        onClick={showList}
        onBlur={hideList}
      >
        <span className={clsx(styles.inputText, 'me-5')}>
          {valueList || placeholder || <>&nbsp;</>}
        </span>
        <div className={styles.buttonContainer}>
          {isClearButtonShown && (
            <MetronicIconButton
              type='button'
              onClick={handleClear}
              iconType='Navigation'
              iconName='Close'
              variant='text'
              size='sm'
            />
          )}
          {endAdornment ? (
            endAdornment
          ) : (
            <MetronicIcon size='sm' iconType='Navigation' iconName='Angle-down' />
          )}
        </div>
      </div>
      {isListShown && (
        <div ref={ref} className={clsx('form-control form-control-solid', styles.dropdown)}>
          <div>
            <ul className={styles.list}>
              {hasSelectAll && (
                <MultiSelectInputItem
                  value=''
                  label='All'
                  onClick={handleSelectAll}
                  checked={isAllSelected}
                />
              )}
              {items.map((item) => (
                <MultiSelectInputItem
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  onClick={() => toggleItem(item)}
                  checked={checked.includes(item.value)}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}
