import {useWaiverAuth} from './useWaiverAuth'
import {useAlerts} from './../../../components/alerts/useAlerts'
import {TicketWaiverModel} from './../../../models/digital-waiver/TicketWaiverModel'
import {useCallback, useMemo, useState} from 'react'
import {useLoadingState} from '../../../components/hooks/useLoadingState'
import {FileInputValueCollection} from '../../../components/inputs/FileInput/FileInputValueCollection'
import {
  checkIfNeedGuardian,
  TicketSubmitFormValues,
} from '../components/waiverForms/TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'
import * as Yup from 'yup'
import moment from 'moment'
import {ImageInputValue} from '../../../components/inputs/FileInput/ImageInputValue'
import {useAppConfig} from '../../app-config/hooks/useAppConfig'
import {DateUtil} from '../../../utils/DateUtil'
import {GetISOCountries, GetWaiverByCode} from '../redux/DigitalWaiverCRUD'
import {DigitalWaiverModel} from '../../../models/ems/DigitalWaiverModel'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
export interface UseSubmitTicketDataOptions {
  ticket?: TicketWaiverModel | null
  isGuardianPage?: boolean
  isQuickWaiver?: boolean
}
//passportFront
export const initialValues: TicketSubmitFormValues = {
  name: '',
  email: '',
  mobile: '',
  dob: null,
  phone: '',
  licenseCountryCode: '',
  licenseIssueDate: null,
  licenseNumber: '',
  licenseExpiryDate: null,
  licenseFront: new FileInputValueCollection(),
  licenseBack: new FileInputValueCollection(),
  guardianIdFront: new FileInputValueCollection(),
  guardianIdBack: new FileInputValueCollection(),
  passportFront: new FileInputValueCollection(),
  passportBack: new FileInputValueCollection(),
  guardianEmail: '',
  guardianName: '',
  guardianMobile: '',
  guardianDOB: null,
  signature: null,
  termsAndConditions: false,
  agreement: false,
  rulesAndPolicy: false,
  autoSign: false,
  // raceLicense: false,
}

export const useSubmitTicketData = ({
  ticket,
  isGuardianPage,
  isQuickWaiver,
}: UseSubmitTicketDataOptions) => {
  const {isLoading} = useLoadingState()
  const [countries, setCountries] = useState<ISOCountryModel[]>([])
  const [initialNewValues, setInitialNewValues] = useState<TicketSubmitFormValues>(initialValues)
  const {staticUrls} = useAppConfig()
  const auth = useWaiverAuth()
  const {pushError} = useAlerts()

  const minAge = useMemo(() => {
    const min = ticket?.productMinimumAge ? ticket.productMinimumAge : 14
    return moment().subtract(min, 'years').toDate()
  }, [ticket?.productMinimumAge])

  const mapWaiverSignDataToFormValues = useCallback(
    (sign: DigitalWaiverModel): TicketSubmitFormValues => {
      const imageFront = new FileInputValueCollection<ImageInputValue>()
      sign.licenseFront &&
        imageFront.add(new ImageInputValue(staticUrls.private, sign.licenseFront))

      const imageBack = new FileInputValueCollection<ImageInputValue>()
      sign.licenseBack && imageBack.add(new ImageInputValue(staticUrls.private, sign.licenseBack))

      const passportFrontPhoto = new FileInputValueCollection<ImageInputValue>()
      if (sign.passportFront)
        passportFrontPhoto.add(new ImageInputValue(staticUrls.private, sign.passportFront))

      const passportBackPhoto = new FileInputValueCollection<ImageInputValue>()
      if (sign.passportBack)
        passportBackPhoto.add(new ImageInputValue(staticUrls.private, sign.passportBack))

      const guardianIdFrontPhoto = new FileInputValueCollection<ImageInputValue>()
      if (sign.guardianIdFront)
        guardianIdFrontPhoto.add(new ImageInputValue(staticUrls.private, sign.guardianIdFront))

      const guardianIdBackPhoto = new FileInputValueCollection<ImageInputValue>()
      if (sign.guardianIdBack)
        guardianIdBackPhoto.add(new ImageInputValue(staticUrls.private, sign.guardianIdBack))

      const formValues: TicketSubmitFormValues = {
        name: sign?.name || '',
        email: sign?.email || '',
        mobile: sign?.mobile || '',
        dob: sign?.dob ? DateUtil.getDateFromApiString(sign.dob) : minAge,
        phone: sign?.phone || '',
        licenseCountryCode: sign.license?.country?.code
          ? sign.license.country.code
          : sign.customer?.license?.country?.code
          ? sign.customer.license.country.code
          : '',
        licenseIssueDate: sign.license?.issueDate
          ? DateUtil.getDateFromApiString(sign.license.issueDate)
          : null,
        licenseNumber: sign.license?.number ? sign.license.number : '',
        licenseExpiryDate: sign.license?.expiryDate
          ? DateUtil.getDateFromApiString(sign.license.expiryDate)
          : null,
        licenseFront: imageFront,
        licenseBack: imageBack,
        guardianDOB: sign.guardian?.dob ? DateUtil.getDateFromApiString(sign.guardian.dob) : minAge,
        guardianName: sign.guardian?.name ? sign.guardian.name : undefined,
        guardianEmail: sign.guardian?.email ? sign.guardian.email : undefined,
        guardianMobile: sign.guardian?.mobile ? sign.guardian.mobile : undefined,
        guardianIdFront: guardianIdFrontPhoto,
        guardianIdBack: guardianIdBackPhoto,
        passportFront: passportFrontPhoto,
        passportBack: passportBackPhoto,
        termsAndConditions: false,
        rulesAndPolicy: false,
        agreement: false,
        autoSign: sign.autoSign,
        // raceLicense: sign.raceLicense,
      }

      return formValues
    },
    [minAge, staticUrls.private]
  )

  const getWaiver = useCallback(async () => {
    if (ticket && ticket.dwCode) {
      try {
        const {data} = await GetWaiverByCode(ticket.dwCode)
        if (data) {
          const mappdValues = mapWaiverSignDataToFormValues(data)
          setInitialNewValues(mappdValues)
        }
      } catch (err: any) {
        pushError(err)
      }
    }
  }, [mapWaiverSignDataToFormValues, pushError, ticket])

  const resetCountries = useCallback(async () => {
    try {
      const {data} = await GetISOCountries()
      setCountries(data)
    } catch (err: any) {
      pushError(err)
    }
  }, [pushError])

  const waiverFormValidationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email().required('Email is required'),
      mobile: Yup.string().required('Mobile is required'),
      phone: Yup.string().required('Emergency Phone is required'),
      dob: Yup.string()
        .nullable()
        .test(
          'is-driver-license',
          'We apologize - you do not meet the minimum age requirements to participate, you must be over the age of 19.',
          (value, context) => {
            const age = moment().diff(value, 'years')
            if (age < (ticket?.productMinimumAge || 19)) {
              return false
            } else return true
            // if (age < (ticket?.productMinimumAge || 14)) {
            //   if (context.parent.raceLicense) return true
            //   else return false
            // } else return true
          }
        )
        .required('Date of Birth is required'),
      licenseCountryCode: Yup.string().test('is-driver', 'Country license is required', (value) => {
        if (ticket?.productDigitalWaiverType === 'driver') {
          if (!value || value === '') return false
          else return true
        } else return true
      }),
      licenseIssueDate: Yup.string()
        .nullable()
        .test('is-driver', 'License issued date is required', (value) => {
          const driveYasKeywords = ['drive yas', 'driveyas']
          const isDriveYas =
            ticket &&
            ticket.productCategorySlug &&
            driveYasKeywords.includes(ticket.productCategorySlug?.toLowerCase())

          if (isDriveYas) {
            if (ticket?.productDigitalWaiverType === 'driver') {
              if (!value || value === '') return false
              else return true
            } else return true
          } else {
            if (!value || value === '') return false
            else return true
          }
        })
        .test('is-year', (value, {parent, createError}) => {
          const driveYasKeywords = ['drive yas', 'driveyas']
          const isDriveYas =
            ticket &&
            ticket.productCategorySlug &&
            driveYasKeywords.includes(ticket.productCategorySlug?.toLowerCase())

          if (isDriveYas) {
            if (ticket?.productDigitalWaiverType === 'driver') {
              const formDate: TicketSubmitFormValues = parent
              const age = moment().diff(formDate.dob, 'years')
              const diff = moment(new Date()).diff(value, 'years')
              // if (formDate.raceLicense) return true

              if (age > 25 && diff < 1) {
                return createError({
                  message:
                    'Drivers aged more than 25 must have held their license for a minimum of one year.',
                  path: 'licenseIssueDate',
                })
              } else if (age <= 25 && diff < 2) {
                return createError({
                  message:
                    'Drivers aged 19-25 must have held their license for a minimum of two years.',
                  path: 'licenseIssueDate',
                })
              } else return true
            } else return true
          } else return true
        }),
      licenseNumber: Yup.string().test('is-driver', 'License number date is required', (value) => {
        if (ticket?.productDigitalWaiverType === 'driver') {
          if (!value || value === '') return false
          else return true
        } else return true
      }),
      licenseExpiryDate: Yup.string()
        .nullable()
        .test('is-driver', 'License expiry date is required', (value) => {
          if (ticket?.productDigitalWaiverType === 'driver') {
            if (!value || value === '') return false
            else return true
          } else return true
        }),
      licenseFront: Yup.mixed().test(
        'is_license_front_has_value',
        'license front side is required',
        (value) => {
          const inputValue = value as FileInputValueCollection<ImageInputValue>
          if (ticket?.productDigitalWaiverType === 'driver') {
            if (!value || !inputValue.getFirst()) return false
            else return true
          } else return true
        }
      ),
      licenseBack: Yup.mixed().test(
        'is_license_back_has_value',
        'license back side is required',
        (value) => {
          const inputValue = value as FileInputValueCollection<ImageInputValue>
          if (ticket?.productDigitalWaiverType === 'driver') {
            if (!value || !inputValue.getFirst()) return false
            else return true
          } else return true
        }
      ),
      signature: Yup.mixed().test('is-guardian', 'Signature is required', (value, context) => {
        const inputValue = value as Blob
        if (isGuardianPage || isQuickWaiver) {
          if (!value || !inputValue) return false
          return true
        } else {
          const age = moment().diff(context.parent.dob, 'years')
          if (
            typeof ticket?.productAgeFrom === 'number' &&
            typeof ticket?.productAgeTo === 'number'
          ) {
            if (age >= ticket.productAgeFrom && age <= ticket.productAgeTo) {
              return true
            } else {
              if (!value || !inputValue) return false
              return true
            }
          }
          return true
        }
      }),
      // PASSENGER
      passportFront: Yup.mixed().test('is-passenger', 'Passport front is required', (value) => {
        const inputValue = value as FileInputValueCollection<ImageInputValue>
        if (ticket?.productDigitalWaiverType === 'passenger') {
          if (!value || !inputValue.getFirst()) return false
          else return true
        } else return true
      }),

      passportBack: Yup.mixed(),

      guardianName: Yup.string().test(
        'is-age-legal',
        'Guardian name is required',
        (value, context) => {
          const formDate: TicketSubmitFormValues = context.parent
          const age = moment().diff(formDate.dob, 'years')
          if (
            typeof ticket?.productAgeFrom === 'number' &&
            typeof ticket?.productAgeTo === 'number'
          ) {
            return age >= ticket.productAgeFrom && age <= ticket.productAgeTo && !value
              ? false
              : true
          }

          return true
        }
      ),

      guardianEmail: Yup.string().test('is-guardian-page', (value, {parent, createError}) => {
        const formDate: TicketSubmitFormValues = parent
        const age = moment().diff(formDate.dob, 'years')
        if (
          typeof ticket?.productAgeFrom === 'number' &&
          typeof ticket?.productAgeTo === 'number'
        ) {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          const isEmail = re.test(String(value).toLowerCase())
          return age >= ticket.productAgeFrom && age <= ticket.productAgeTo
            ? !value
              ? createError({
                  message: 'Guardian email is required',
                  path: 'guardianEmail',
                })
              : value && !isEmail
              ? createError({
                  message: 'Email Invalid',
                  path: 'guardianEmail',
                })
              : value && isEmail && value === auth?.getUser().email
              ? createError({
                  message: "Guardian can't be youself",
                  path: 'guardianEmail',
                })
              : true
            : true
        }
        return true
      }),

      guardianMobile: Yup.string().test(
        'is-guardian-page',
        'Guardian mobile is required',
        (value) => {
          if (isGuardianPage && !value) {
            return false
          }
          return true
        }
      ),

      guardianDOB: Yup.string()
        .nullable()
        .test('is-guardian-page', 'Guardia date of birth is required', (value) => {
          if (isGuardianPage && !value) {
            return false
          }
          return true
        }),

      guardianIdFront: Yup.mixed()
        .nullable()
        .test('is-guardian-page', 'Guardia ID is required', (value) => {
          const inputValue = value as FileInputValueCollection<ImageInputValue>
          if (isGuardianPage && (!value || !inputValue.getFirst())) {
            return false
          }
          return true
        }),

      guardianIdBack: Yup.mixed(),

      termsAndConditions: Yup.boolean()
        .isTrue('Terms and conditions is required')
        .required('Terms and conditions is required'),
      rulesAndPolicy: Yup.boolean()
        .isTrue('Rules and policy is required')
        .required('Rules and policy is required'),

      autoSign: Yup.boolean(),

      agreement: Yup.boolean().isTrue('Approval is required').required('Approval is required'),
      // raceLicense: Yup.boolean(),
    })
  }, [ticket, isGuardianPage, isQuickWaiver, auth])

  return useMemo(
    () => ({
      getWaiver,
      mapWaiverSignDataToFormValues,
      isLoading,
      initialNewValues,
      waiverFormValidationSchema,
      resetCountries,
      countries,
    }),
    [
      getWaiver,
      mapWaiverSignDataToFormValues,
      isLoading,
      initialNewValues,
      waiverFormValidationSchema,
      resetCountries,
      countries,
    ]
  )
}

export const getWaiverSignPayload = ({
  values,
  ticketCode,
  delegateCode,
  ticket,
}: {
  values: TicketSubmitFormValues
  ticketCode: string
  delegateCode?: string | undefined
  ticket?: TicketWaiverModel | null
}): FormData => {
  const licenseFront =
    values.licenseFront && values.licenseFront.getFirst()
      ? values.licenseFront.getFirst().toApiValue()
      : undefined

  const licenseBack =
    values.licenseBack && values.licenseBack.getFirst()
      ? values.licenseBack.getFirst().toApiValue()
      : undefined

  const guardianIdFront =
    values.guardianIdFront && values.guardianIdFront.getFirst()
      ? values.guardianIdFront.getFirst().toApiValue()
      : undefined

  const guardianIdBack =
    values.guardianIdBack && values.guardianIdBack.getFirst()
      ? values.guardianIdBack.getFirst().toApiValue()
      : undefined

  const passportFront =
    values.passportFront && values.passportFront.getFirst()
      ? values.passportFront.getFirst().toApiValue()
      : undefined

  const passportBack =
    values.passportBack && values.passportBack.getFirst()
      ? values.passportBack.getFirst().toApiValue()
      : undefined
  const formData = new FormData()
  formData.append('ticketCode', ticketCode)
  if (delegateCode) formData.append('delegateCode', delegateCode)
  formData.append('mobile', values.mobile)
  formData.append('name', values.name)
  formData.append('email', values.email)
  formData.append('phone', values.phone)
  formData.append('dob', values.dob ? DateUtil.convertDateToApiString(values.dob) : '')
  if (values.licenseCountryCode) formData.append('licenseCountryCode', values.licenseCountryCode)
  if (values.licenseIssueDate)
    formData.append(
      'licenseIssueDate',
      values.licenseIssueDate ? DateUtil.convertDateToApiString(values.licenseIssueDate) : ''
    )
  if (values.licenseNumber) formData.append('licenseNumber', values.licenseNumber)
  if (values.licenseExpiryDate)
    formData.append(
      'licenseExpiryDate',
      values.licenseExpiryDate ? DateUtil.convertDateToApiString(values.licenseExpiryDate) : ''
    )

  if (values.signature) formData.append('signature', values.signature, 'signature.png')
  if (licenseFront) formData.append('licenseFront', licenseFront)
  else if (values.imagesCodes?.['licenseFrontCode'])
    formData.append('licenseFrontCode', values.imagesCodes?.['licenseFrontCode'])
  if (licenseBack) formData.append('licenseBack', licenseBack)
  else if (values.imagesCodes?.['licenseBackCode'])
    formData.append('licenseBackCode', values.imagesCodes?.['licenseBackCode'])
  if (guardianIdFront) formData.append('guardianIdFront', guardianIdFront)
  if (guardianIdBack) formData.append('guardianIdBack', guardianIdBack)
  if (passportFront) formData.append('passportFront', passportFront)
  else if (values.imagesCodes?.['passportFrontCode'])
    formData.append('passportFrontCode', values.imagesCodes?.['passportFrontCode'])
  if (passportBack) formData.append('passportBack', passportBack)
  else if (values.imagesCodes?.['passportBackCode'])
    formData.append('passportBackCode', values.imagesCodes?.['passportBackCode'])
  if (checkIfNeedGuardian(values.dob, ticket)) {
    if (values.guardianEmail) formData.append('guardianEmail', values.guardianEmail)
    if (values.guardianName) formData.append('guardianName', values.guardianName)
    if (values.guardianMobile) formData.append('guardianMobile', values.guardianMobile)
    if (values.guardianDOB)
      formData.append(
        'guardianDOB',
        values.guardianDOB ? DateUtil.convertDateToApiString(values.guardianDOB) : ''
      )
  }
  formData.append('termsAndConditions', String(values.termsAndConditions))
  formData.append('rulesAndPolicy', String(values.rulesAndPolicy))
  formData.append('autoSign', String(values.autoSign))

  // formData.append('raceLicense', String(values.raceLicense))

  return formData
}
