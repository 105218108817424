import {ReactNode, useEffect, useMemo, useState} from 'react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {ReactComponent as CircuitSvg} from './F1_Circuit_Map_2022.svg'
import {createPopper, Placement} from '@popperjs/core'

export type CircuitMapDatumKey =
  | 'south_oasis'
  | 'main_oasis'
  | 'north_oasis'
  | 'west_oasis'
  | 'abu_dhabi_hill'
  | 'abu_dhabi_hill_oasis'
  | 'marina_grandstand'
  | 'south_grandstand'
  | 'main_grandstand'
  | 'north_grandstand'
  | 'west_grandstand'
  | 'hq'
  | 'w_hotel'
  | 'yas_yacht'
  | 'yas_central'
  | 'the_deck_at_nine'
  | 'luna_lounge'
  | 'north_straight'
  | 'north_straight_hospitality'
  | 'the_turns_at_west'
  | 'main_pit_building'

export interface F1CircuitHeatMapChartDatum {
  max: number
  value: number
}

// export type F1CircuitHeatMapChartDatumMap = Partial<
//   Record<CircuitMapDatumKey, F1CircuitHeatMapChartDatum>
// >

export interface F1CircuitHeatMapChartProps {
  renderLabel?: (key: CircuitMapDatumKey) => ReactNode
  labelOptions?: Partial<Record<CircuitMapDatumKey, MapLabelOptions>>
}

export const F1CircuitHeatMapChart = ({labelOptions, renderLabel}: F1CircuitHeatMapChartProps) => {
  const [hoveredKey, setHoveredKey] = useState<CircuitMapDatumKey>()
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  // const [annotation, setAnnotation] =
  //   useState<{datumKey: DatumKey; x: number; y: number; dx: number; dy: number}>()

  // // const heatScale = useMemo(() => {
  // //   return scaleLinear({
  // //     range: ['#FFFFFF', '#f33d15', '#960000'],
  // //     domain: [0, 75, 100],
  // //   })
  // // }, [])

  // const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
  //   useTooltip<DatumKey>()

  // const {
  //   containerRef: tooltipContainerRef,
  //   containerBounds,
  //   TooltipInPortal,
  // } = useTooltipInPortal({
  //   scroll: true,
  //   detectBounds: true,
  // })

  // useEffect(() => {
  //   keys.forEach((key) => {
  //     const wgs = containerRef?.querySelector<SVGElement>(`#${key}`)
  //     if (wgs) {
  //       wgs.style.stroke = '#AAAAAA'
  //       const data = datum[key]
  //       if (data && data.max) {
  //         const value = heatScale((data.value / data.max) * 100)
  //         wgs.style.fill = value
  //       }
  //     }
  //   })
  // }, [containerRef, datum, heatScale])

  // const handlePointerDown = useCallback(
  //   (e: React.PointerEvent<SVGElement>) => {
  //     const elementId = (e.target as any).id
  //     if (keys.includes(elementId)) {
  //       if (annotation?.datumKey === elementId) {
  //         setAnnotation(undefined)
  //       } else {
  //         const clientX = e.clientX
  //         const clientY = e.clientY
  //         const containerX = clientX - containerBounds.left
  //         const containerY = clientY - containerBounds.top

  //         setAnnotation((previousValues) => {
  //           // So that the annotation does not move its position.
  //           // dx2 = x1 + dx1 - x2
  //           // dy2 = y1 + dy1 - y2
  //           // Where 1 is previous and 2 is current
  //           const calculatedDx = previousValues
  //             ? (previousValues?.x || 0) + (previousValues?.dx || 0) - containerX
  //             : 0
  //           const calculatedDy = previousValues
  //             ? (previousValues?.y || 0) + (previousValues?.dy || 0) - containerY
  //             : 50

  //           return {
  //             datumKey: elementId,
  //             x: containerX,
  //             y: containerY,
  //             dx: calculatedDx,
  //             dy: calculatedDy,
  //           }
  //         })
  //       }
  //     }
  //   },
  //   [annotation?.datumKey, containerBounds.left, containerBounds.top]
  // )

  // const handleLabelDragEnd = useCallback(({dx, dy, x, y}: HandlerArgs) => {
  //   setAnnotation((previousValues) => {
  //     if (previousValues) {
  //       return {
  //         ...previousValues,
  //         dx,
  //         dy,
  //         x,
  //         y,
  //       }
  //     }
  //   })
  // }, [])

  // const handlePointerMove = useCallback(
  //   (e: React.PointerEvent<SVGElement>) => {
  //     const elementId = (e.target as any).id
  //     if (keys.includes(elementId)) {
  //       const clientX = e.clientX
  //       const clientY = e.clientY
  //       const containerX = clientX - containerBounds.left
  //       const containerY = clientY - containerBounds.top
  //       showTooltip({
  //         tooltipLeft: containerX,
  //         tooltipTop: containerY,
  //         tooltipData: elementId,
  //       })
  //     } else {
  //       hideTooltip()
  //     }
  //   },
  //   [containerBounds.left, containerBounds.top, hideTooltip, showTooltip]
  // )

  // const tooltipDatum = useMemo(() => {
  //   if (tooltipData) {
  //     return datum[tooltipData]
  //   }
  //   return null
  // }, [datum, tooltipData])

  // const annotationLabels = useMemo(() => {
  //   if (annotation?.datumKey) {
  //     const annotationDatum = datum[annotation.datumKey]
  //     const title = tooltipLabelAccessor(annotation.datumKey)
  //     let subtitle = `No data`
  //     if (annotationDatum) {
  //       subtitle = `Occupied: ${annotationDatum.value}`
  //     }
  //     return {title, subtitle}
  //   }
  // }, [annotation, datum, tooltipLabelAccessor])

  const mapTooltipContainers = useMemo(() => {
    return keys.map((key) => {
      const content = renderLabel ? renderLabel(key) : null
      return (
        <div
          id={`popper-content-${key}`}
          key={key}
          style={{zIndex: hoveredKey === key ? 1 : undefined}}
          onMouseEnter={() => setHoveredKey(key)}
        >
          {content}
        </div>
      )
    }, [])
  }, [hoveredKey, renderLabel])

  const poppers = useMemo(() => {
    return keys.map((key) => {
      const options = labelOptions?.[key]
      const mapArea = containerRef?.querySelector<HTMLElement>(`#text_${key}`)
      const popperContent = containerRef?.querySelector<HTMLElement>(`#popper-content-${key}`)
      if (mapArea && popperContent) {
        const popper = createPopper(mapArea, popperContent, {
          placement: options?.placement || 'auto',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: options?.offset,
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
          ],
        })
        return popper
      }
      return null
    })
  }, [containerRef, labelOptions])

  useEffect(() => {
    return () => {
      poppers.forEach((popper) => popper?.destroy())
    }
  }, [poppers])

  return (
    <div
      className='h-100 w-100'
      ref={(el) => {
        // tooltipContainerRef(el)
        setContainerRef(el)
      }}
    >
      <ParentSize>
        {({width, height}) => {
          poppers.forEach((popper) => {
            popper?.update()
          })
          return (
            <>
              <svg width={width} height={height} className='overflow-visible'>
                <CircuitSvg
                  // onPointerMove={handlePointerMove}
                  width={width}
                  height={height}
                  // onPointerDown={handlePointerDown}
                />
                {/* {annotationLabels && (
                  <EditableAnnotation
                    width={width}
                    height={height}
                    x={annotation?.x || 0}
                    y={annotation?.y || 0}
                    dx={annotation?.dx}
                    dy={annotation?.dy}
                    onDragEnd={handleLabelDragEnd}
                    canEditLabel
                    canEditSubject={false}
                  >
                    <Connector stroke='red' type='line' />
                    <Label
                      width={280}
                      backgroundFill='white'
                      showAnchorLine
                      anchorLineStroke='black'
                      title={annotationLabels.title}
                      subtitle={annotationLabels.subtitle}
                      fontColor='black'
                    />
                    <button>Test</button>
                  </EditableAnnotation>
                )} */}
              </svg>
              {/* {tooltipOpen && (
                <TooltipInPortal left={tooltipLeft} top={tooltipTop} style={tooltipStyles}>
                  <Tooltip
                    tooltipLabelAccessor={tooltipLabelAccessor}
                    datumKey={tooltipData}
                    datum={tooltipDatum}
                  />
                </TooltipInPortal>
              )} */}
              {mapTooltipContainers}
            </>
          )
        }}
      </ParentSize>
    </div>
  )
}

// const Tooltip = ({
//   datumKey,
//   datum,
//   tooltipLabelAccessor,
// }: {
//   tooltipLabelAccessor: (key: DatumKey) => string
//   datumKey?: DatumKey
//   datum?: F1CircuitHeatMapChartDatum | null
// }) => {
//   const label = useMemo(() => {
//     if (datumKey) {
//       return tooltipLabelAccessor(datumKey)
//     }
//   }, [datumKey, tooltipLabelAccessor])

//   if (!datumKey || !datum) {
//     return <span>No Data</span>
//   }

//   return (
//     <div>
//       <h3>{label}</h3>
//       <p>
//         <span className='fw-bold'>Occupied: </span>
//         <span>{datum.value}</span>
//       </p>
//     </div>
//   )
// }

const keys: CircuitMapDatumKey[] = [
  'south_oasis',
  'main_oasis',
  'north_oasis',
  'west_oasis',
  'abu_dhabi_hill',
  'north_straight',
  'north_straight_hospitality',
  'abu_dhabi_hill_oasis',
  'marina_grandstand',
  'south_grandstand',
  'main_grandstand',
  'north_grandstand',
  'west_grandstand',
  'hq',
  'w_hotel',
  'yas_yacht',
  'yas_central',
  'the_deck_at_nine',
  'luna_lounge',
  'the_turns_at_west',
  'main_pit_building',
]

export interface MapLabelOptions {
  placement: Placement
  offset: [number, number]
}

// const tooltipStyles = {
//   ...defaultStyles,
// }
