import {useCallback, useMemo} from 'react'
import {FilterModel} from '../../../../../models/FilterModel'
import {get} from 'lodash'
import {GroupedTableData} from '../../../../../components/tables/Table'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {ControlledFilterTableColumnOptions} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {PortaTicketStatusActivityColumn} from '../PortaTicketStatusActivityColumn'
import {MetronicIconButton} from '../../../../../components/inputs/MetronicIconButton'
import {useIsGSSSPP} from '../../../hooks/useIsGSSSPP'
import {useRouteMatch} from 'react-router-dom'
import {RouteParams} from '../../../pages/PortalPage'
import {useDay} from '../../../hooks/useDay'
interface usePortalTicketTableColumnsProps {
  filters?: FilterModel
  tabCode?: string
}

export const usePortalTicketTableColumns = ({
  filters,
  tabCode,
}: usePortalTicketTableColumnsProps) => {
  const {
    params: {productCategoryCode},
  } = useRouteMatch<RouteParams>()

  const {getDayThreeLetters} = useDay()

  const {isGCCSPP} = useIsGSSSPP({productCategoryCode})
  const rowShouldRender = useCallback(
    (
      field: string,
      unallowedGroupings: string[],
      groupData?: GroupedTableData<TicketPortalModel>
    ) => {
      return (
        !groupData ||
        !unallowedGroupings.includes(filters?.groupBy || '') ||
        !isGroupedDataIdentical(groupData.data, field)
      )
    },
    [filters?.groupBy]
  )

  const rowGroupShouldRender = useCallback(
    (field: string, allowedGroupings: string[], groupData: GroupedTableData<TicketPortalModel>) => {
      if (field === filters?.groupBy) {
        return true
      }
      return (
        allowedGroupings.includes(filters?.groupBy || '') &&
        isGroupedDataIdentical(groupData.data, field)
      )
    },
    [filters?.groupBy]
  )

  const columnMap = useMemo((): Record<
    string,
    ControlledFilterTableColumnOptions<TicketPortalModel>
  > => {
    return {
      day: {
        field: 'startDate',
        label: 'Day',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        render: ({data}) => {
          return (
            <div className='text-uppercase'>{getDayThreeLetters(data.startedAt, data.endedAt)}</div>
          )
        },
      },
      reservationNo: {
        field: 'bookingReservationNo',
        label: 'Reservation',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        render: ({data, groupData}) => {
          const shouldRender = rowShouldRender(
            'bookingReservationNo',
            ['batchId', 'reservationNo', 'product'],
            groupData
          )
          if (!shouldRender) {
            return null
          }
          return <>{data.bookingReservationNo || data.bookingCode}</>
        },
        groupedColumnRender: ({groupData}) => {
          const shouldRender = rowGroupShouldRender(
            'bookingReservationNo',
            ['batchId', 'reservationNo', 'product'],
            groupData
          )
          if (!shouldRender) {
            return null
          }
          const data = groupData.data[0]
          return <>{data.bookingReservationNo || data.bookingCode}</>
        },
      },

      batchId: {
        field: 'batchId',
        label: 'Ticket Id',
        hideable: true,
        sortable: true,
        dataExtract: (data) => data.batchId,
        groupedColumnRender: ({groupData}) => {
          if (groupData.field === 'batchId') {
            const match = groupData.label.match(BATCH_ID_REGEX)
            return <>{match?.[1] || groupData.label}</>
          }
          return <></>
        },
      },

      ticketId: {
        field: 'ticketId',
        label: 'Ticket Id',
        hideable: true,
        sortable: true,
        render: ({data, groupData}) => {
          if (groupData) {
            return <></>
          }
          return <>{data.batchId}</>
        },
        groupedColumnRender: ({groupData}) => {
          if (groupData.field === 'batchId') {
            const found = groupData.data.find((item) => item.batchId)
            const firstRow = groupData.data[0].batchId ? groupData.data[0] : found
            return <>{firstRow?.batchId}</>
          }
          if (groupData.field === 'batchId') {
            return <>{groupData.label}</>
          }
          return <></>
        },
      },
      statusActivity: {
        field: 'statusActivity',
        label: 'Activity Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortaTicketStatusActivityColumn data={data} />,
      },
      shareName: {
        field: 'share.name',
        label: 'Shared With',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return <div>{data.share?.name}</div>
        },
      },
      shareEmailMobile: {
        field: 'share.email',
        label: 'Email/Mobile NO',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return (
            <div>{`${data?.share?.email ? data.share.email : ''}${
              data?.share?.mobile && data?.share?.email ? '|' : ''
            }${data?.share?.mobile ? data.share.mobile : ''}`}</div>
          )
        },
      },
      shareBy: {
        field: 'share',
        label: 'Shared By',
        sortable: false,
        hideable: false,
        render: ({data}) => {
          return (
            <div>
              {data?.share?.type === 'whatsapp' ? (
                <MetronicIconButton
                  variant='text'
                  color='white'
                  disabled
                  size='sm'
                  iconType='Communication'
                  iconName='Call'
                />
              ) : data?.share?.type === 'email' ? (
                <MetronicIconButton
                  variant='text'
                  color='white'
                  size='sm'
                  disabled
                  iconType='Communication'
                  iconName='Mail'
                />
              ) : null}
            </div>
          )
        },
      },

      product: {
        field: 'productName',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.LOCATION,
        render: ({data}) => {
          const day = getDayThreeLetters(data.startedAt, data.endedAt)
          return (
            <div className='text-uppercase'>
              {!isGCCSPP && day === 'Thu' ? 'Pitlane Walk' : data.productName}
            </div>
          )
        },
      },

      section: {
        field: 'locationSlug',
        label: 'Section',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        render: ({data}) => {
          if (filters?.groupBy && filters?.groupBy === 'batchId') {
            return null
          }
          return <>{data?.locationSlug}</>
        },
        groupedColumnRender: ({groupData}) => {
          if (groupData.field === 'batchId') {
            const firstRow = groupData.data[0]
            return <>{firstRow?.locationSlug}</>
          }
          return <></>
        },
      },

      seatRow: {
        field: 'seatRow',
        label: 'Seat Row',
        hideable: true,
        sortable: true,
        render: ({data}) => {
          if (filters?.groupBy && filters?.groupBy === 'batchId') {
            return null
          }
          const day = getDayThreeLetters(data.startedAt, data.endedAt)
          if (day === 'Thu') return null
          return <>{data?.seatRow}</>
        },
        groupedColumnRender: ({groupData}) => {
          if (groupData.field === 'batchId') {
            const firstRow = groupData.data[0]
            return <>{firstRow?.seatRow}</>
          }
          return <></>
        },
      },

      seatNo: {
        field: 'seatNo',
        label: 'Seat No',
        hideable: true,
        sortable: true,
        render: ({data}) => {
          if (filters?.groupBy && filters?.groupBy === 'batchId') {
            return null
          }
          const day = getDayThreeLetters(data.startedAt, data.endedAt)
          if (day === 'Thu') return null
          return <>{data?.seatNo}</>
        },
        groupedColumnRender: ({groupData}) => {
          if (groupData.field === 'batchId') {
            const firstRow = groupData.data[0]

            return <>{firstRow?.seatNo}</>
          }
          return <></>
        },
      },
    }
  }, [filters?.groupBy, getDayThreeLetters, isGCCSPP, rowGroupShouldRender, rowShouldRender])

  const getDefaultColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<TicketPortalModel>[] = []

    columns.push(
      columnMap.batchId,
      columnMap.day,
      columnMap.section,
      columnMap.seatRow,
      columnMap.seatNo,
      columnMap.reservationNo,
      columnMap.ticketId,
      columnMap.statusActivity
    )
    return columns
  }, [columnMap])

  const getBatchIdColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<TicketPortalModel>[] = [
      {field: '', label: 'Ticket', hideable: false, sortable: false, className: 'grouped-column'},
    ]

    columns.push(
      columnMap.batchId,
      columnMap.product,
      columnMap.day,
      columnMap.section,
      columnMap.seatRow,
      columnMap.seatNo,
      columnMap.reservationNo,
      columnMap.ticketId,
      columnMap.statusActivity
    )
    return columns
  }, [columnMap])

  const getSharedColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<TicketPortalModel>[] = []

    columns.push(
      columnMap.shareName,
      columnMap.shareEmailMobile,
      columnMap.product,
      columnMap.day,
      columnMap.section,
      columnMap.seatRow,
      columnMap.seatNo,
      columnMap.reservationNo,
      columnMap.ticketId,
      columnMap.shareBy,
      columnMap.statusActivity
    )
    return columns
  }, [
    columnMap.day,
    columnMap.product,
    columnMap.reservationNo,
    columnMap.seatNo,
    columnMap.seatRow,
    columnMap.section,
    columnMap.shareBy,
    columnMap.shareEmailMobile,
    columnMap.shareName,
    columnMap.statusActivity,
    columnMap.ticketId,
  ])

  const getNotSharedColumns = useCallback(() => {
    const columns: ControlledFilterTableColumnOptions<TicketPortalModel>[] = []

    columns.push(
      columnMap.product,
      columnMap.day,
      columnMap.section,
      columnMap.seatRow,
      columnMap.seatNo,
      columnMap.reservationNo,
      columnMap.ticketId,
      columnMap.statusActivity
    )
    return columns
  }, [
    columnMap.day,
    columnMap.product,
    columnMap.reservationNo,
    columnMap.seatNo,
    columnMap.seatRow,
    columnMap.section,
    columnMap.statusActivity,
    columnMap.ticketId,
  ])

  const columns = useMemo(() => {
    if (filters?.groupBy) {
      switch (filters.groupBy) {
        case 'batchId':
          return getBatchIdColumns()
        default:
          return getDefaultColumns()
      }
    }

    if (tabCode) {
      switch (tabCode) {
        case 'shared':
          return getSharedColumns()
        default:
          return getNotSharedColumns()
      }
    }

    return getDefaultColumns()
  }, [
    filters,
    getBatchIdColumns,
    getDefaultColumns,
    getNotSharedColumns,
    getSharedColumns,
    tabCode,
  ])

  return columns
}

const isGroupedDataIdentical = <T extends Record<string, any>>(group: T[], path: string) => {
  return group.every((item, i, group) => {
    if (i === 0) {
      return true
    }

    const previousValue = get(group[i - 1], path)
    const value = get(item, path)
    return previousValue === value
  })
}

const BATCH_ID_REGEX = /(.+) \(.+\)/
