import {ProvidedProps, PieArcDatum} from '@visx/shape/lib/shapes/Pie'
import {animated, useTransition, to} from 'react-spring'

export interface PieChartDatum {
  value: number
  label: string
  key: string
}

export interface PieChartSegmentProps<Datum> extends ProvidedProps<Datum> {
  animate?: boolean
  getColor: (d: PieArcDatum<Datum>) => string
  onClickDatum?: (d: PieArcDatum<Datum>) => void
  delay?: number
  isHideText?: boolean
  onMouseMove?: (e: React.MouseEvent<SVGGElement, MouseEvent>, d: PieArcDatum<Datum>) => void
  showValue?: boolean
}

interface AnimatedStyles {
  startAngle: number
  endAngle: number
  opacity: number
}

export const PieChartSegment = ({
  animate,
  arcs,
  path,
  getColor,
  onClickDatum,
  onMouseMove,
  isHideText,
  showValue,
}: PieChartSegmentProps<PieChartDatum>) => {
  const transitions = useTransition<PieArcDatum<PieChartDatum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })

  return transitions((props, arc, {key}) => {
    const [centroidX, centroidY] = path.centroid(arc)
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.5

    return (
      <g key={key} onMouseMove={(e) => onMouseMove?.(e, arc)}>
        <animated.path
          style={{cursor: onClickDatum ? 'pointer' : undefined}}
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum?.(arc)}
          onTouchStart={() => onClickDatum?.(arc)}
        />
        {!isHideText && hasSpaceForLabel && (
          <>
            <text
              fill='white'
              x={centroidX}
              y={centroidY}
              dy='.33em'
              fontSize={FONT_SIZE}
              textAnchor='middle'
              pointerEvents='none'
            >
              <tspan>{arc.data.label}</tspan>
            </text>
            <text
              fill='white'
              x={centroidX}
              y={centroidY + FONT_SIZE + FONT_SIZE * 0.1}
              dy='.33em'
              fontWeight='bolder'
              fontSize={FONT_SIZE}
              textAnchor='middle'
              pointerEvents='none'
            >
              {showValue && <tspan>{arc.data.value}</tspan>}
            </text>
          </>
        )}
      </g>
    )
  })
}

const FONT_SIZE = 14

const fromLeaveTransition = ({endAngle}: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})
const enterUpdateTransition = ({startAngle, endAngle}: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
})

const getKey = (datum: PieArcDatum<PieChartDatum>) => {
  return datum.data.key
}
