import {ChangeEvent, useCallback} from 'react'
import {SelectInput, SelectInputItem} from '../../inputs/SelectInput'
import {useTableFilterState} from '../useTableFilterState'

export interface GroupByFilterInputProps {
  items: SelectInputItem[]
  label?: string
  placeholder?: string
  endAdornment?: string
}

export const GroupByFilterInput = ({
  items,
  label,
  placeholder,
  endAdornment,
}: GroupByFilterInputProps) => {
  const {groupBy, setGroupBy} = useTableFilterState()

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setGroupBy(e.target.value || undefined)
    },
    [setGroupBy]
  )

  return (
    <SelectInput
      items={items}
      value={groupBy || ''}
      label={label}
      placeholder={groupBy ? 'None' : placeholder}
      onChange={handleOnChange}
      endAdornment={endAdornment}
    />
  )
}
