import {ReactNode, useCallback, useMemo, useState} from 'react'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../../models/GlobalSearchModel'
import {ControlledFilterPortalTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterPortalTable'
import {TicketTableRowActions} from './TicketTableRowActions'
import {useTicketTableActions} from '../../../hooks/useTicketTableActions'
import {TicketTableSelectionActions} from './TicketTableSelectionActions'
import {idExtractor} from '../../../../../utils/idExtractor'
import {EmailModal, InitialValuesProps} from '../../modals/EmailModal'
import {WalletModal} from '../../modals/WalletModal'
// import {useHistory} from 'react-router-dom'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {EVENT_CODE} from '../../../../../../config/env'
import {GroupedTableData} from '../../../../../components/tables/Table'
import {WhatsAppInitialValuesProps, WhatsAppModal} from '../../modals/WhatsAppModal'
import {SelectGroupByButtons} from '../../../pages/PortalTicketPage'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {usePortalTicketTableColumns} from './usePortalTicketTableColumns'
import {Spinner} from '../../../../../components/utils/Spinner'
import {Button} from '../../../../../components/inputs/Button'
export interface TicketTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<TicketPortalModel> | GroupedSearchModel<TicketPortalModel>
  onRefresh?: () => void
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  advancedGroupBy?: ReactNode
  leftToolbar?: ReactNode
  removedColumns?: string[]
  filters: FilterModel
  toPattabCodeh?: string
  expandedGroups?: TableRowId[]
  tabCode: string
  onExpandedGroupChange?: (groups: TableRowId[]) => void
  groupedItem?: SelectGroupByButtons | null
  productCategoryCode?: string
}

export const TicketPortalTable = ({
  data,
  onFilter,
  onRefresh,
  advancedFilters,
  advancedGroupBy,
  initialFilters,
  removedColumns,
  leftToolbar,
  filters,
  expandedGroups,
  onExpandedGroupChange,
  tabCode,
  groupedItem,
  productCategoryCode,
}: TicketTableProps) => {
  // const history = useHistory()
  const [selected, setSelected] = useState<TableRowId[] | string[]>([])
  const [openEmailModel, setOpenEmailModel] = useState(false)
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)
  const [openWalletModel, setOpenWalletModel] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState<TicketPortalModel[]>()

  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: 'ticket',
    defaults: {
      hiddenColumns: [
        'batchCode',
        'bookingCode',
        'customerName',
        'productType',
        'startedAt',
        'endedAt',
      ],
    },
  })

  const {
    isKeyLoading,
    isLoading,
    handleDownloadBulk,
    handleShareByEmailBulk,
    handleShareByWhatsAppBulk,
    addToWallet,
  } = useTicketTableActions({onRefresh})

  // const handleOnView = useCallback(
  //   (ticket: TicketPortalModel) => {
  //     history.replace(
  //       `/product-category/${productCategoryCode}/${tabCode}/ticket/${ticket?.code}/overview`
  //     )
  //   },
  //   [history, productCategoryCode, tabCode]
  // )

  const handleOnWallet = useCallback((ticket: TicketPortalModel) => {
    setSelectedTickets([ticket])
    setOpenWalletModel(true)
  }, [])

  const handleOnShareByEmail = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenEmailModel(true)
  }, [])

  const handleOnShareWhatsApp = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenWhatsAppModel(true)
  }, [])

  const rowActions = useCallback(
    (data: TicketPortalModel) => (
      <TicketTableRowActions
        loading={isKeyLoading(data.code)}
        data={data}
        // onView={handleOnView}
        onDownload={handleDownloadBulk}
        onWallet={handleOnWallet}
        onShareByWahtsApp={handleOnShareWhatsApp}
        onShareByEmail={handleOnShareByEmail}
        iconWidth='29px'
      />
    ),
    [isKeyLoading, handleDownloadBulk, handleOnWallet, handleOnShareWhatsApp, handleOnShareByEmail]
  )

  const groupRowActions = useCallback(
    (data: GroupedTableData<TicketPortalModel>) => {
      return (
        <div className='d-flex gap-3'>
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Download'
            style={{
              width: '29px',
            }}
          >
            {isKeyLoading(data.data[0].code) ? (
              <Spinner className='me-5' size='lg' />
            ) : (
              <KTSVG
                color='white'
                className='h-100 w-100'
                svgClassName='h-100 w-100'
                path='/media/svg/customer-poratl-actions/download.svg'
                onClick={() => handleDownloadBulk(data.data)}
              />
            )}
          </Button>
          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by whatsApp'
            style={{
              width: '29px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/whatsapp.svg'
              onClick={() => handleOnShareWhatsApp(data.data)}
            />
          </Button>

          <Button
            type='button'
            className='bg-transparent p-2'
            size='sm'
            tooltip='Share by email'
            style={{
              width: '29px',
            }}
          >
            <KTSVG
              color='white'
              className='h-100 w-100'
              svgClassName='h-100 w-100'
              path='/media/svg/customer-poratl-actions/mail.svg'
              onClick={() => handleOnShareByEmail(data.data)}
            />
          </Button>
        </div>
      )
    },
    [handleDownloadBulk, handleOnShareByEmail, handleOnShareWhatsApp, isKeyLoading]
  )

  const columns = usePortalTicketTableColumns({
    filters: filters,
    tabCode,
  })

  const tableSelectionItems = useMemo(() => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
      return Object.values(data.data).reduce<TicketPortalModel[]>((acc, group) => {
        acc.push(...group)

        return acc
      }, [])
    }
    return []
  }, [data])

  const tableColumns = useMemo(() => {
    return columns.filter((c) => {
      if (removedColumns) {
        return !removedColumns.includes(c.field)
      }
      return true
    })
  }, [columns, removedColumns])

  const handleSendEmail = useCallback(
    async (values: InitialValuesProps) => {
      if (values) {
        if (selectedTickets) {
          const response = await handleShareByEmailBulk(values, selectedTickets)
          if (response) return response
        }
      }
      onRefresh && onRefresh()
    },
    [selectedTickets, handleShareByEmailBulk, onRefresh]
  )

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialValuesProps) => {
      if (values) {
        if (selectedTickets) {
          const response = await handleShareByWhatsAppBulk(values, selectedTickets)
          if (response) return response
        }
      }
      onRefresh && onRefresh()
    },
    [onRefresh, selectedTickets, handleShareByWhatsAppBulk]
  )

  const handleSubmitAddtoWallet = useCallback(
    async (ticket: TicketPortalModel) => {
      if (ticket && EVENT_CODE) {
        const response = await addToWallet(ticket, EVENT_CODE)
        if (response) setOpenWalletModel(false)
      }
    },
    [addToWallet]
  )

  useOnChange(openEmailModel, () => {
    if (!openEmailModel) setSelectedTickets(undefined)
  })

  useOnChange(openWalletModel, () => {
    if (!openWalletModel) setSelectedTickets(undefined)
  })

  useOnChange(openWhatsAppModel, () => {
    if (!openWhatsAppModel) setSelectedTickets(undefined)
  })

  return (
    <>
      <ControlledFilterPortalTable<TicketPortalModel>
        onHiddenColumnsChange={setHiddenColumns}
        hiddenColumns={hiddenColumns}
        initialFilters={initialFilters}
        advancedFilters={advancedFilters}
        advancedGroupBy={advancedGroupBy}
        selectionAction={
          <TicketTableSelectionActions
            items={tableSelectionItems}
            selected={selected}
            onShareByEmail={handleOnShareByEmail}
            onShareByWhatsApp={handleOnShareWhatsApp}
            onDownload={handleDownloadBulk}
            isLoading={isLoading}
            className='rounded-0'
          />
        }
        leftToolbar={leftToolbar}
        onFilter={onFilter}
        idExtractor={idExtractor}
        columns={tableColumns}
        filters={filters}
        searchResults={data}
        actions={rowActions}
        groupRowActions={(data) => filters.groupBy && groupRowActions(data)}
        selection={selected}
        onSelectionChange={setSelected}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={onExpandedGroupChange}
        groupedItem={groupedItem}
      />
      {selectedTickets && (
        <EmailModal
          data={selectedTickets}
          setOpen={setOpenEmailModel}
          open={openEmailModel}
          onSubmit={handleSendEmail}
        />
      )}

      {selectedTickets && (
        <WhatsAppModal
          data={selectedTickets}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
        />
      )}
      {selectedTickets && (
        <WalletModal
          data={selectedTickets}
          setOpen={setOpenWalletModel}
          open={openWalletModel}
          onSubmit={handleSubmitAddtoWallet}
        />
      )}
    </>
  )
}
