import {useCallback, useEffect, useMemo, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import {useAlerts} from '../../../components/alerts/useAlerts'
import {BlobUtils} from '../../../utils/BlobUtils'
import {DeviceUtils} from '../../../utils/DeviceUtils'
import {PortalWhatsAppPdf} from '../components/PortalWhatsAppPdf'
import {PrintTicket} from '../redux/CustomerPortalCRUD'

interface RouteParams {
  ticketCode: string
}
export const PortalDownloadTicketByWhatsApp = () => {
  const [pdf, setPdf] = useState<string>()
  const {pushError} = useAlerts()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const {
    params: {ticketCode},
  } = useRouteMatch<RouteParams>()

  const getPdfFile = useCallback(async () => {
    if (ticketCode && ticketCode !== 'undefined') {
      try {
        const {data} = await PrintTicket(ticketCode)
        if (data) {
          const blobToUrl = URL.createObjectURL(data)
          setPdf(blobToUrl)
        }
      } catch (err) {
        pushError(err)
        setError('Please try again later!')
      } finally {
        setIsLoading(false)
      }
    }
  }, [pushError, ticketCode])

  const isIphone = useMemo(() => {
    return DeviceUtils.isiPhone()
  }, [])

  const downloadPdfFile = useCallback(async () => {
    if (ticketCode && ticketCode !== 'undefined') {
      try {
        setIsDownloadLoading(true)
        const {data, headers} = await PrintTicket(ticketCode)
        if (data) {
          let name = headers['content-disposition']
          name = name
            .replace('attachment;', '')
            .replace('filename=', '')
            .split('(')[0]
            .replaceAll('"', '')
            .trim()
          if (data) BlobUtils.downloadBlob(data, name)
        }
      } catch (err) {
        pushError(err)
      } finally {
        setIsDownloadLoading(false)
      }
    }
  }, [pushError, ticketCode])

  useEffect(() => {
    if (!isIphone) {
      getPdfFile()
    }
  }, [getPdfFile, isIphone])

  return (
    <PortalWhatsAppPdf
      isLoading={isLoading}
      isDownloadLoading={isDownloadLoading}
      error={error}
      downloadPdfFile={downloadPdfFile}
      pdf={pdf}
    />
  )
}
