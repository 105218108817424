import {useCallback, useEffect} from 'react'
import {useAlerts} from '../../../../components/alerts/useAlerts'
import {useSeatMapPortalReservationState} from '../../hooks/useSeatMapPortalReservationState'
import {SeatMapSelectionPortalInput} from './SeatMapSelectionPortalInput'
import {SeatMapValue} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {TicketPortalModel} from '../../../../models/ems/TicketModel'
import {useSafeStateUpdate} from '../../../../components/hooks/useSafeStateUpdate'
import {GetLocationByCode} from '../../../default/acs/redux/AcsCRUD'

interface TicketDetailSeatMapsProps {
  ticket?: TicketPortalModel | null
}
export const PortalTicketSeatMaps = ({ticket}: TicketDetailSeatMapsProps) => {
  const {
    resetState: resetSeatMapState,
    columns,
    rows,
    extra,
    isRightToLeft,
    isBottomToTop,
    setIsLoading,
    setOccupied,
    occupied,
  } = useSeatMapPortalReservationState()
  const {push, pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()

  const resetLocationByCode = useCallback(
    async (locationCode: string) => {
      const doneLoading = setIsLoading(locationCode)
      try {
        const {data} = await GetLocationByCode(locationCode)
        if (!data.seatMap) {
          push({
            message: `No seat maps available for ${data.name}`,
            variant: 'danger',
            timeout: 5000,
          })
        } else {
          safeUpdate(() => resetSeatMapState(data.seatMap))
          if (ticket) {
            let availableSeat = Object.assign({})
            availableSeat[ticket.seatRow] = [ticket.seatNo]
            setOccupied(new SeatMapValue(availableSeat))
          }
        }
      } catch (e) {
        pushError(e)
      } finally {
        doneLoading()
      }
    },
    [setIsLoading, push, safeUpdate, ticket, resetSeatMapState, setOccupied, pushError]
  )

  useEffect(() => {
    if (ticket && ticket.locationCode) {
      resetLocationByCode(ticket.locationCode)
    }
  }, [resetLocationByCode, ticket])

  return (
    <SeatMapSelectionPortalInput
      columns={columns}
      rows={rows}
      occupied={occupied}
      extra={extra}
      isRightToLeft={isRightToLeft}
      isBottomToTop={isBottomToTop}
    />
  )
}
