import {useMemo, useState} from 'react'
import {PoralProductWidgetModel} from '../../../../models/customer-portal/PortalWidgetModal'
import {SubProductsCard} from './SubProductsCard'
import {ProductDetails} from './ProductDetails'
import {useAppConfig} from '../../../app-config/hooks/useAppConfig'
import {Button} from '../../../../components/inputs/Button'
interface DashboardCardProps {
  product: PoralProductWidgetModel
  defaultImage?: string
}

export const DashboardCard = ({product, defaultImage}: DashboardCardProps) => {
  const {staticUrls} = useAppConfig()
  const [rotation, setRotation] = useState<string>()

  const vouchers = useMemo(() => {
    if (product?.type === 'voucher') {
      if (product?.products?.length) {
        return product.products.reduce<PoralProductWidgetModel>(
          (acc, item) => {
            acc.active += item.active
            acc.shared += item.shared
            acc.downloaded += item.downloaded
            acc.addedToWallet += item.addedToWallet
            acc.total += item.total
            acc.day = item.day || ''
            return acc
          },
          {
            code: 'voucherQWE',
            name: '',
            active: 0,
            shared: 0,
            downloaded: 0,
            addedToWallet: 0,
            total: 0,
            childProductTotal: 0,
            day: '',
            type: 'voucher',
          }
        )
      }
    }
    return null
  }, [product.products, product.type])

  const defaultProductImage = useMemo(() => {
    if (defaultImage) return defaultImage
    else {
      const productName = product.name.toLocaleLowerCase()
      if (productName.includes('Main Grandstand'.toLocaleLowerCase()))
        return '/media/products/dashboard-main-grandstand.png'
      else if (productName.includes('South Grandstand'.toLocaleLowerCase()))
        return '/media/products/dashboard-south-grandstand.png'
      else if (productName.includes('ABU DHABI HILL'.toLocaleLowerCase()))
        return '/media/products/dashboard-abu-dhabi-hill.png'
      else if (productName.includes('Marina Grandstand'.toLocaleLowerCase()))
        return '/media/products/dashboard-marina-grandstand.png'
      else if (productName.includes('North Grandstand'.toLocaleLowerCase()))
        return '/media/products/dashboard-north-grandstand.png'
      else if (productName.includes('West Grandstand'.toLocaleLowerCase()))
        return '/media/products/dashboard-west-grandstand.png'
      else return '/media/products/dashboard-default.png'
    }
  }, [defaultImage, product.name])

  const image = useMemo(() => {
    if (staticUrls.public) {
      return (
        <div
          className='position-relative overflow-hidden'
          style={{
            height: '135px',
          }}
        >
          <img
            height='100%'
            width='100%'
            // src={`${staticUrls.public}/${product.code}.png`}
            src={defaultImage}
            alt='product'
            onError={({currentTarget}) => {
              currentTarget.src = defaultProductImage

              currentTarget.onerror = null
            }}
            style={{
              objectFit: 'cover',
            }}
          />
          <div
            className='position-absolute top-50 start-50 translate-middle'
            style={{
              width: '85%',
            }}
          >
            <p className='text-center text-uppercase ff-admm-bold text-white fs-1 mb-0'>
              {product.name}
            </p>
          </div>
        </div>
      )
    }

    return (
      <div
        className='position-relative overflow-hidden'
        style={{
          height: '135px',
        }}
      >
        <img width='100%' src={defaultProductImage} alt='product' />
      </div>
    )
  }, [defaultImage, defaultProductImage, product.name, staticUrls.public])

  const rotate = useMemo(() => {
    return rotation
  }, [rotation])

  const cardBox = useMemo(() => {
    return (
      <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 px-3 pt-5'>
        <div className='card flip-card'>
          <div
            className='flip-card-inner'
            style={{
              transform: rotate,
              boxShadow: 'rgb(52 92 89 / 73%) 0rem 1rem 1rem -1.2rem',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              height: '342px',
            }}
          >
            <div className='flip-card-inner-front bg-body' style={{zIndex: rotation ? 0 : 10}}>
              <div className='rounded-0 card shadow-lg h-100 d-flex flex-column justify-content-between'>
                <div className='flex-grow-1'>
                  {image}
                  <div
                    style={{
                      height: '157px',
                    }}
                  >
                    <ProductDetails
                      product={product}
                      items={{
                        total: product.total,
                        shared: product.shared,
                        active: product.active,
                        addedToWallet: product.addedToWallet,
                        downloaded: product.downloaded,
                        childProductTotal: product.childProductTotal,
                      }}
                    />
                  </div>
                </div>

                <Button
                  className='rounded-0 bg-primary-cp-dark text-white ff-admm-bold fs-4'
                  fullWidth
                  onClick={rotation ? undefined : () => setRotation('rotateY(180deg)')}
                  style={{
                    height: '50px',
                  }}
                >
                  Show More
                </Button>
              </div>
            </div>
            <div
              className='flip-card-inner-back d-flex flex-column justify-content-between bg-body'
              style={{zIndex: rotation ? 10 : 0}}
            >
              <div
                className='overflow-auto'
                style={{
                  height: '292px',
                }}
              >
                {product.type === 'voucher' && vouchers ? (
                  <SubProductsCard product={[vouchers]} />
                ) : product.products ? (
                  <SubProductsCard product={product.products} />
                ) : null}
              </div>

              <Button
                className='rounded-0 bg-primary-cp-dark text-white ff-admm-bold fs-4'
                fullWidth
                onClick={rotation ? () => setRotation(undefined) : undefined}
                style={{
                  height: '50px',
                }}
              >
                Show Less
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }, [image, product, rotate, rotation, vouchers])

  return cardBox
}
