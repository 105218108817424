import {useCallback, useMemo, useState} from 'react'
import {DateRange} from '../../utils/DateRange'
import {Time} from '../../utils/Time'
import {useOnChange} from '../hooks/useOnChange'
import {DatePickerInput} from './DatePickerInput'
import {TimePickerInput} from './TimePickerInput'

export interface DateRangeInputProps {
  minDate?: Date | null
  maxDate?: Date | null
  isTimeslot?: boolean
  value: DateRange
  onChange: (newValue: DateRange) => void
  calendarIcon?: JSX.Element | null
  calendarClassName?: string
  inputClassName?: string
  disabled?:
    | boolean
    | {
        start?: boolean
        end?: boolean
      }
  minutesStep?: number
  classNames?: {
    startDate?: string
    endDate?: string
    startTime?: string
    endTime?: string
  }
  noMargin?: boolean
  isLabelUppercase?: boolean
}

export const DateRangeInput = ({
  minDate,
  maxDate,
  value,
  onChange,
  isTimeslot,
  disabled,
  classNames = {},
  minutesStep,
  calendarIcon,
  calendarClassName,
  inputClassName,
  noMargin,
  isLabelUppercase,
}: DateRangeInputProps) => {
  const [startTime, setStartTime] = useState(DEFAULT_START_TIME)
  const [endTime, setEndTime] = useState(DEFAULT_END_TIME)

  const handleDateChange = useCallback(
    (field: 'start' | 'end') => (newDate: Date | null) => {
      const newValue = value.clone()
      if (field === 'start') {
        if (newDate) {
          startTime.applyToDate(newDate)
          if (!newValue.getEnd()) {
            const endDate = new Date(newDate)
            endTime.applyToDate(endDate)
            newValue.setEnd(endDate)
          }
        }
        newValue.setStart(newDate)
      } else {
        if (newDate) {
          endTime.applyToDate(newDate)
        }
        newValue.setEnd(newDate)
      }
      onChange(newValue)
    },
    [startTime, endTime, value, onChange]
  )

  const handleTimePickerChange = useCallback(
    (field: 'start' | 'end') => (time: Time) => {
      if (field === 'start') {
        const startDate = value.getStartClone()
        if (startDate) {
          const newValue = value.clone()
          time.applyToDate(startDate)
          newValue.setStart(startDate)
          const newTime = Time.fromDate(newValue.getStartOrFail())
          onChange(newValue)
          setStartTime(newTime)
        } else {
          setStartTime(time)
        }
      } else {
        const endDate = value.getEndClone()
        if (endDate) {
          const newValue = value.clone()
          time.applyToDate(endDate)
          newValue.setEnd(endDate)
          const newTime = Time.fromDate(newValue.getEndOrFail())
          onChange(newValue)
          setEndTime(newTime)
        } else {
          setEndTime(time)
        }
      }
    },
    [onChange, value]
  )

  const startTimeValue = useMemo(() => {
    const startDate = value.getStartClone()
    if (startDate) {
      return Time.fromDate(startDate)
    }
    return startTime
  }, [startTime, value])

  const endTimeValue = useMemo(() => {
    const endDate = value.getEndClone()
    if (endDate) {
      return Time.fromDate(endDate)
    }
    return startTime
  }, [startTime, value])

  useOnChange(isTimeslot, () => {
    if (!isTimeslot) {
      setEndTime(DEFAULT_END_TIME)
      setStartTime(DEFAULT_START_TIME)
      const newValue = value.clone()
      const start = newValue.getStart()
      const end = newValue.getEnd()
      if (start) {
        DEFAULT_START_TIME.applyToDate(start)
      }
      if (end) {
        DEFAULT_END_TIME.applyToDate(end)
      }
      onChange(newValue)
    }
  })

  const [startDisabled, endDisabled] = useMemo(() => {
    let startDisabled = false
    let endDisabled = false

    if (typeof disabled === 'boolean') {
      startDisabled = disabled
      endDisabled = disabled
    } else if (disabled) {
      startDisabled = disabled.start || false
      endDisabled = disabled.end || false
    }

    return [startDisabled, endDisabled]
  }, [disabled])

  return (
    <>
      <div>
        <DatePickerInput
          label='Start Date'
          noMargin={noMargin}
          minDate={minDate || undefined}
          maxDate={value.getEnd() || maxDate || undefined}
          onChange={handleDateChange('start')}
          disabled={startDisabled}
          value={value.getStart()}
          className={classNames.startDate}
          calendarIcon={calendarIcon}
          calendarClassName={calendarClassName}
          isLabelUppercase={isLabelUppercase}
          inputClassName={inputClassName}
        />
        {isTimeslot && (
          <TimePickerInput
            label='Start Time'
            onChange={handleTimePickerChange('start')}
            value={startTimeValue}
            className={classNames.startTime}
            minutesStep={minutesStep}
            disabled={startDisabled}
          />
        )}
      </div>
      <div>
        <DatePickerInput
          noMargin={noMargin}
          label='End Date'
          minDate={value.getStart() || minDate || undefined}
          maxDate={maxDate || undefined}
          onChange={handleDateChange('end')}
          disabled={endDisabled}
          value={value.getEnd()}
          className={classNames.endDate}
          calendarIcon={calendarIcon}
          calendarClassName={calendarClassName}
          isLabelUppercase={isLabelUppercase}
          inputClassName={inputClassName}
        />
        {isTimeslot && (
          <TimePickerInput
            label='End Time'
            onChange={handleTimePickerChange('end')}
            value={endTimeValue}
            disabled={endDisabled}
            className={classNames.endDate}
            minutesStep={minutesStep}
          />
        )}
      </div>
    </>
  )
}

const DEFAULT_START_TIME = new Time(0, 0, 0)
const DEFAULT_END_TIME = new Time(23, 59, 59)
