import {FC, Suspense, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {RootState} from '../setup'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {AlertContainer} from './components/alerts/AlertContainer'
import {WebSocketProvider} from './components/utils/WebSocketProvider'
import AuthInit from './modules/default/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {SwalUtils} from './utils/SwalUtils'

type Props = {
  basename: string
}

const App: FC<Props> = ({basename}) => {
  // CHECK WORKER STATUS
  const isServiceWorkerUpdated: Boolean = useSelector<RootState>(
    ({worker}) => worker.serviceWorkerUpdated,
    shallowEqual
  ) as Boolean

  // WORKER REGISTRATION
  const serviceWorkerRegistration: any = useSelector<RootState>(
    ({worker}) => worker.serviceWorkerRegistration,
    shallowEqual
  ) as any

  useEffect(() => {
    async function swUpdate() {
      if (isServiceWorkerUpdated) {
        const response = await SwalUtils.applicationUpdate()
        if (response.isConfirmed) {
          if (serviceWorkerRegistration !== null) {
            const registrationWaiting = serviceWorkerRegistration.waiting
            if (registrationWaiting) {
              registrationWaiting.postMessage({type: 'SKIP_WAITING'})
              registrationWaiting.addEventListener('statechange', (e: any) => {
                if (e.target.state === 'activated') {
                  window.location.reload(false)
                }
              })
            }
          }
        }
      }
    }
    swUpdate()
  }, [isServiceWorkerUpdated, serviceWorkerRegistration])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <WebSocketProvider autoStart={false}>
                <Routes />
                <AlertContainer />
              </WebSocketProvider>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
