import {ReactNode, useMemo} from 'react'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../../../models/GlobalSearchModel'
import {ControlledFilterTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
import {idExtractor} from '../../../../../utils/idExtractor'
import {BookingModel} from '../../../../../models/ems/BookingModel'
import {ShipmentsStatusColumn} from './ShipmentsStatusColumn'
import {ShipmentsCodeColumn} from './ShipmentsCodeColumn'

export interface ShipmentsPortalTableProps {
  onFilter: (filter: FilterModel) => void
  data?: GlobalSearchModel<BookingModel> | GroupedSearchModel<BookingModel>
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  leftToolbar?: ReactNode
  removedColumns?: string[]
  filters: FilterModel
  expandedGroups?: TableRowId[]
  shipmentsTabCode?: string
  onExpandedGroupChange?: (groups: TableRowId[]) => void
}

export const ShipmentsPortalTable = ({
  data,
  onFilter,
  advancedFilters,
  initialFilters,
  removedColumns,
  leftToolbar,
  filters,
  expandedGroups,
  onExpandedGroupChange,
  shipmentsTabCode,
}: ShipmentsPortalTableProps) => {
  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: 'voucher',
    defaults: {
      hiddenColumns: ['startedAt', 'endedAt'],
    },
  })

  const columnsMemo = useMemo(() => {
    const columns: TableColumnOptions<BookingModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        hideable: true,
        render: ({data}) => ShipmentsCodeColumn({data, shipmentsTabCode}),
      },
      {
        field: 'awb',
        label: 'AWB',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'shipmentStatus',
        label: 'Status',
        sortable: true,
        hideable: true,
        render: ({data}) => <ShipmentsStatusColumn data={data} />,
      },
    ]
    return columns
  }, [shipmentsTabCode])

  const tableColumns = useMemo(() => {
    return columnsMemo.filter((c) => {
      if (removedColumns) {
        return !removedColumns.includes(c.field)
      }
      return true
    })
  }, [columnsMemo, removedColumns])

  return (
    <>
      <ControlledFilterTable<BookingModel>
        onHiddenColumnsChange={setHiddenColumns}
        hiddenColumns={hiddenColumns}
        initialFilters={initialFilters}
        advancedFilters={advancedFilters}
        leftToolbar={leftToolbar}
        onFilter={onFilter}
        idExtractor={idExtractor}
        columns={tableColumns}
        filters={filters}
        searchResults={data}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={onExpandedGroupChange}
      />
    </>
  )
}
