import type {FormikContextType} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {CheckboxInput, DatePickerInput, TextInput} from '../../../../../components/inputs'
import {DragDropFileInput} from '../../../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileType} from '../../../../../components/inputs/FileInput/FileInput'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikFileInputHelpers} from '../../../../../components/inputs/hooks/useFormikFileInputHelpers'
import {ISOCountryModel} from '../../../../../models/ISOCountryModel'
import moment from 'moment'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {useFormikMobileNumberInputHelpers} from '../../../../../components/inputs/useFormikMobileNumberInputHelpers'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {FieldsContainerWithBorderAndLabel} from '../components/FieldsContainerWithBorderAndLabel'
import {FieldWithLabelBox} from '../components/FieldWithLabelBox'
import {PopupFormContainer, PopupFormContainerProps} from './PopupFormContainer'
import {TermsAndConditionsCheckBox} from '../components/TermsAndConditionsCheckBox'
import {RulesAndPolicyCheckBox} from '../components/RulesAndPolicyCheckBox'
import {AutoSignCheckBox} from '../components/AutoSignCheckBox'
import {ViewImageModal} from '../../modals/ViewImageModal'
import {GuardianForm} from '../components/GuarddianForm'
import {SignatureForm} from '../components/SignatureForm'
import {DriverLicenseForm} from '../components/DriverLicenseForm'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import {CheckCustomerWaiverValidation} from '../../../redux/DigitalWaiverCRUD'
import {useDebounce} from '../../../../../components/hooks/useDebounce'
import {imagesCodesProps} from './TicketSubmitWaiverFormSign'
export const TicketSubmitWaiverForm = ({
  formik,
  isOpen,
  countries,
  ticket,
  isCloseButton,
  disabledFields,
  isGuardianPage,
  isQuickWaiver,
  onCancel,
  ...formProps
}: TicketSubmitWaiverProps) => {
  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  const imageInputHandler = useFormikFileInputHelpers(formik, FileType.IMAGE)
  const mobileNumberInput = useFormikMobileNumberInputHelpers(formik)
  const [imageToView, setImageToView] = useState<string>()
  const [customerValidationMessage, setCustomerValidationMessage] = useState<string | null>()
  const checkCustomerDebounced = useDebounce(1000)

  const maxBirthDate = useMemo(() => {
    const minAge = ticket?.productMinimumAge ? ticket.productMinimumAge : 14
    return moment().subtract(minAge, 'years').toDate()
  }, [ticket?.productMinimumAge])

  useEffect(() => {
    if (!formik.values.dob) {
      formik.setFieldValue('dob', maxBirthDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDriveYas = useMemo(() => {
    if (ticket) {
      const driveYasKeywords = ['drive yas', 'driveyas']
      return (
        ticket.productCategorySlug &&
        driveYasKeywords.includes(ticket.productCategorySlug?.toLowerCase())
      )
    }
  }, [ticket])

  const checkCustomerValidation = useCallback(async () => {
    if (ticket && formik.values.email && !isGuardianPage) {
      try {
        await CheckCustomerWaiverValidation(ticket.code, formik.values.email)
        setCustomerValidationMessage(null)
      } catch (err) {
        setCustomerValidationMessage(
          `Sorry, This account ${formik.values.email} doesn't reach the minimum number of experiences to submit for this ticket.`
        )
      }
    } else setCustomerValidationMessage(null)
  }, [formik.values.email, isGuardianPage, ticket])

  useOnChange(formik.values.email, () => {
    const isEmail = formik.values.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (isEmail && ticket?.productDigitalWaiverType === 'driver' && ticket.productExperienceType) {
      checkCustomerDebounced(async () => {
        checkCustomerValidation()
      })
    } else setCustomerValidationMessage(null)
  })

  const isGuardianNeeded = useMemo(() => {
    return checkIfNeedGuardian(formik.values.dob, ticket)
  }, [formik.values.dob, ticket])

  const handleShowFile = useCallback((file: FileInputValueCollection<ImageInputValue>) => {
    if (file) setImageToView(file.getFirst().url)
    else throw Error('No file selected')
  }, [])

  const isAgeLegal = useMemo(() => {
    const age = moment().diff(formik.values.dob, 'years')
    if (age < (ticket?.productMinimumAge || 14)) {
      return false
    } else return true
  }, [formik.values.dob, ticket?.productMinimumAge])

  // const isAgeLegal = useMemo(() => {
  //   const age = moment().diff(formik.values.dob, 'years')
  //   if (age < (ticket?.productMinimumAge || 14)) {
  //     if (formik.values.raceLicense) return true
  //     else return false
  //   } else return true
  // }, [formik.values.dob, formik.values.raceLicense, ticket?.productMinimumAge])

  // const isRaceLicense = useMemo(() => {
  //   const age = moment().diff(formik.values.dob, 'years')
  //   if (age < (ticket?.productMinimumAge || 14)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }, [formik, ticket?.productMinimumAge])

  // useOnChange(formik.values.dob, () => {
  //   if (!isRaceLicense) formik.setFieldValue('raceLicense', false)
  // })

  return (
    <>
      {imageToView && (
        <ViewImageModal
          imageURL={imageToView}
          isOpen={true}
          zoom={true}
          onClose={() => setImageToView(undefined)}
        />
      )}
      <PopupFormContainer
        isSubmitDisabled={formik.isSubmitting}
        customerValidationMessage={customerValidationMessage}
        onSubmit={formik.handleSubmit}
        onCancelButtonClick={onCancel}
        cancleButtonLabel='Reject'
        submitButtonLabel={
          isGuardianPage
            ? 'SUBMIT أرسل'
            : !isGuardianNeeded
            ? 'SUBMIT أرسل'
            : 'Submit & SEND TO guardian أرسل إلى الوصي'
        }
        {...formProps}
      >
        {customerValidationMessage && (
          <div
            className='text-danger text-center pt-0 pb-0  mb-5'
            style={{
              paddingTop: '0px !important',
              paddingBottom: '0px !important',
            }}
          >
            {customerValidationMessage}
          </div>
        )}
        <FieldsContainerWithBorderAndLabel legend='Personal information'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
            <FieldWithLabelBox englishLabel='Name of Individual' arabicLabel='اسم الشخص'>
              <TextInput
                noMargin
                className='h-100'
                inputClassName='h-100 bg-primary-dw-darker fs-3'
                inputWrapperClassName='h-100'
                fullWidth
                errorMessage={formik.errors.name}
                isTouched={formik.touched.name}
                placeholder='Enter your name'
                {...formik.getFieldProps('name')}
                disabled={disabledFields?.name || formik.isSubmitting}
              />
            </FieldWithLabelBox>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
            <FieldWithLabelBox englishLabel='Email' arabicLabel='البريد الاكتروني'>
              <TextInput
                noMargin
                fullWidth
                className='h-100'
                inputWrapperClassName='h-100'
                inputClassName='h-100 bg-primary-dw-darker fs-3'
                errorMessage={formik.errors.email}
                isTouched={formik.touched.email}
                placeholder='Enter your Email'
                {...formik.getFieldProps('email')}
                disabled={disabledFields?.email || formik.isSubmitting}
              />
            </FieldWithLabelBox>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
            <FieldWithLabelBox englishLabel='Mobile NO.' arabicLabel='الهاتف المتحرك'>
              <MobileNumberInput
                noMargin
                className='h-100 px-2'
                placeholder='+971551234567'
                inputClassName='h-100 bg-primary-dw-darker fs-3 border border-0'
                disabled={formik.isSubmitting}
                {...mobileNumberInput.getFieldProps('mobile')}
              />
            </FieldWithLabelBox>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
            <FieldWithLabelBox englishLabel='Emergency NO.' arabicLabel='هاتف الطوارئ'>
              <MobileNumberInput
                noMargin
                className='h-100 px-2'
                inputClassName='h-100 bg-primary-dw-darker fs-3'
                placeholder='+971551234567'
                disabled={formik.isSubmitting}
                {...mobileNumberInput.getFieldProps('phone')}
              />
            </FieldWithLabelBox>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
            <FieldWithLabelBox englishLabel='Date of birth' arabicLabel='تاريخ الميلاد'>
              <DatePickerInput
                maxDate={maxBirthDate}
                className='h-100'
                inputClassName='h-100 bg-primary-dw-darker fs-3'
                calendarClassName='h-100'
                noMargin
                value={formik.values.dob}
                disabled={formik.isSubmitting}
                onBlur={() => formik.setFieldTouched('dob', true)}
                {...dateInputHelpers.getProps('dob')}
              />
            </FieldWithLabelBox>
            {isGuardianNeeded && (
              <p className='text-primary mb-0 mt-2'>
                {`We apologize - you do not meet the minimum age requirements to participate, you must
                be over the age of ${ticket?.productMinimumAge || 19}.`}
              </p>
            )}

            {formik.errors.dob && formik.touched.dob && (
              <div
                className='fv-plugins-message-container mb-3'
                style={{
                  marginLeft: '200px',
                }}
              >
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dob}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6 mt-5'>
            <CheckboxInput
              label='I confirm that the personal information provided above is accurate and up-to-date.'
              checked={formik.values.agreement}
              className='m-0'
              disabled={
                !formik.values.name ||
                !formik.values.email ||
                !formik.values.mobile ||
                !formik.values.phone ||
                !formik.values.dob
              }
              {...formik.getFieldProps('agreement')}
            />

            {formik.errors.agreement && formik.touched.agreement && (
              <div className='fv-plugins-message-container mb-1'>
                <div className='fv-help-block'>
                  <span className='' role='alert'>
                    {formik.errors.agreement}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
            <CheckboxInput
              label='Do you have race license?'
              checked={formik.values.raceLicense}
              className='m-0'
              {...formik.getFieldProps('raceLicense')}
            />
          </div> */}

          {formik.values.dob && ticket?.productDigitalWaiverType === 'passenger' && (
            <div className='col-12'>
              <div className='row'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-6 mt-10'>
                  <div>
                    <DragDropFileInput
                      accept={FileType.PNG_JPG_JPEG}
                      onClickImageInside={() =>
                        formik.values.passportFront
                          ? handleShowFile(formik.values.passportFront)
                          : null
                      }
                      placeholder='UPLOAD Passenger’s ID / Passport 1st page'
                      placeholderArabicText='تحميل البطاقة الشخصية  للراكب/جواز السفر من الأمام'
                      classes={{
                        dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
                      }}
                      disabled={formik.isSubmitting}
                      thumbnailClassName='d-flex align-items-center gap-5'
                      icon={{
                        iconName: 'Plus',
                        iconType: 'Navigation',
                      }}
                      iconClassname='p-2 rounded-circle border border-white'
                      limit={1}
                      onBlur={() => formik.setFieldTouched('passportFront', true)}
                      {...imageInputHandler.getProps('passportFront')}
                    />
                    {formik.errors.passportFront && formik.touched.passportFront && (
                      <div className='fv-plugins-message-container mb-3'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.passportFront}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-6 mt-10'>
                  <div>
                    <DragDropFileInput
                      accept={FileType.PNG_JPG_JPEG}
                      onClickImageInside={() =>
                        formik.values.passportBack
                          ? handleShowFile(formik.values.passportBack)
                          : null
                      }
                      placeholder='UPLOAD Passenger’s ID / Passport 2nd page'
                      placeholderArabicText='تحميل البطاقة الشخصية  للراكب/جواز السفر من الخلف'
                      classes={{
                        dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
                      }}
                      disabled={formik.isSubmitting}
                      thumbnailClassName='d-flex align-items-center gap-5'
                      icon={{
                        iconName: 'Plus',
                        iconType: 'Navigation',
                      }}
                      iconClassname='p-2 rounded-circle border border-white'
                      limit={1}
                      onBlur={() => formik.setFieldTouched('passportBack', true)}
                      {...imageInputHandler.getProps('passportBack')}
                    />
                    {formik.errors.passportBack && formik.touched.passportBack && (
                      <div className='fv-plugins-message-container mb-3'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.passportBack}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </FieldsContainerWithBorderAndLabel>

        {formik.values.dob && (
          <>
            {isAgeLegal && (
              <>
                {ticket?.productDigitalWaiverType === 'driver' && (
                  <DriverLicenseForm
                    formik={formik}
                    countries={countries}
                    onView={handleShowFile}
                    ticket={ticket}
                  />
                )}

                {isGuardianNeeded && (
                  <GuardianForm formik={formik} ticket={ticket} onView={handleShowFile} />
                )}

                <div className='pb-5 mb-5'>
                  <div className='row'>
                    <div className='col-12 col-lg-6 d-flex gap-3 mb-5'>
                      <TermsAndConditionsCheckBox formik={formik} disabled={formik.isSubmitting} />
                    </div>
                    <div className='col-12 col-lg-6 d-flex gap-3'>
                      <RulesAndPolicyCheckBox formik={formik} disabled={formik.isSubmitting} />
                    </div>
                    {!isGuardianPage &&
                      !isGuardianNeeded &&
                      !isQuickWaiver &&
                      ticket &&
                      ticket.productDigitalWaiverType !== 'passenger' &&
                      !isDriveYas && (
                        <div className='col-12 mt-4'>
                          <AutoSignCheckBox
                            formik={formik}
                            disabled={formik.isSubmitting}
                            productCategorySlug={ticket.productCategorySlug}
                          />
                        </div>
                      )}
                  </div>
                </div>

                {(!isGuardianNeeded || isGuardianPage || isQuickWaiver) && (
                  <SignatureForm formik={formik} isOpen={isOpen} />
                )}
              </>
            )}
          </>
        )}
      </PopupFormContainer>
    </>
  )
}

export interface TicketSubmitFormValues {
  name: string
  email: string
  mobile: string
  phone: string
  dob: Date | null
  licenseCountryCode: string
  licenseIssueDate: Date | null
  licenseNumber: string
  raceLicense?: boolean
  licenseExpiryDate: Date | null
  licenseFront: FileInputValueCollection<ImageInputValue>
  licenseBack: FileInputValueCollection<ImageInputValue>
  guardianIdFront?: FileInputValueCollection<ImageInputValue>
  guardianIdBack?: FileInputValueCollection<ImageInputValue>
  guardianName?: string
  guardianEmail?: string
  guardianDOB?: Date | null
  guardianMobile?: string
  signature?: Blob | null
  termsAndConditions: boolean
  rulesAndPolicy: boolean
  passportFront?: FileInputValueCollection<ImageInputValue>
  passportBack?: FileInputValueCollection<ImageInputValue>
  autoSign?: boolean
  agreement?: boolean
  imagesCodes?: imagesCodesProps
}

export interface TicketSubmitWaiverProps extends Omit<PopupFormContainerProps, 'isSubmitDisabled'> {
  formik: FormikContextType<TicketSubmitFormValues>
  disabledFields?: Record<string, boolean>
  isOpen: boolean
  countries: ISOCountryModel[]
  ticket?: TicketWaiverModel | null
  isCloseButton?: boolean
  isGuardianPage?: boolean
  isQuickWaiver?: boolean
  onCancel?: () => void
}

export const checkIfNeedGuardian = (value: Date | null, ticket?: TicketWaiverModel | null) => {
  if (value && ticket) {
    const age = moment().diff(value, 'years')
    if (typeof ticket?.productAgeFrom === 'number' && typeof ticket?.productAgeTo === 'number') {
      return age >= ticket.productAgeFrom && age <= ticket.productAgeTo
    }
  }
  return false
}
