import {useMemo} from 'react'
import {
  PoralProductWidgetModel,
  PortalTicketsAliasProps,
} from '../../../../models/customer-portal/PortalWidgetModal'
import {DashboardCard} from './DashboardCard'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {DashboardProductChart} from './DashboardProductChart'

export interface ProductPerTotalModel {
  name: string
  total: number
  value: number
}

export interface DashboardCardsProps {
  products: PoralProductWidgetModel[]
  widgettcp03?: PortalTicketsAliasProps[]
  defaultImage?: string
}

export const DashboardCards = ({products, defaultImage, widgettcp03}: DashboardCardsProps) => {
  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const card = useMemo(() => {
    return (
      <>
        {!isMobile && products && widgettcp03 && (
          <DashboardProductChart products={products} widgettcp03={widgettcp03} />
        )}
        {products.map((product, idx) => {
          return <DashboardCard defaultImage={defaultImage} key={idx} product={product} />
        })}
      </>
    )
  }, [defaultImage, isMobile, products, widgettcp03])
  return <>{card}</>
}
