import {useCallback, useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {AuthNavigationSectionMenuItem} from '../../../../../config/drawer'
import {useEntityFilter} from '../../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../../components/hooks/useRootStateSelector'
import {Button} from '../../../../components/inputs/Button'
import {MetronicIcon} from '../../../../components/inputs/MetronicIcon'
import {NavHeader} from '../../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {usePoratlTabs} from '../../hooks/usePortalTabs'
import {RouteParams} from '../PortalPage'
import {actions} from '../../redux/CustomerPortalRedux'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import {useDebounce} from '../../../../components/hooks/useDebounce'
import {ShipmentsStatusColumn} from '../../components/tables/ShipmentsTable/ShipmentsStatusColumn'

export const PortalShipmentsDetailsCard = () => {
  const {
    params: {shipmentsTabCode, shipmentCode, tabCode},
  } = useRouteMatch<RouteParams>()
  const {setFilter: setFilterBookings} = useEntityFilter('customer-portal-booking')
  const refreshDebounced = useDebounce(300)
  const bookings = useRootStateSelector((state) => state.customerPortal.bookings)
  const dispatch = useDispatch()
  const {refreshShipmentstabs, subTabs, routeName} = usePoratlTabs()
  const history = useHistory()
  const refresh = useCallback(async () => {
    return dispatch(actions.bookings.search())
  }, [dispatch])

  useEffect(() => {
    refresh()
    if (shipmentCode) refreshShipmentstabs()
  }, [shipmentCode, refresh, refreshShipmentstabs])

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `${routeName}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [routeName, subTabs])

  useOnChange(tabCode, () => {
    setFilterBookings({
      filters: {
        search: shipmentCode,
      },
    })
    refreshDebounced(() => refresh())
  })

  const handleGoBack = useCallback(() => {
    history.replace(`/shipments-tab/${shipmentsTabCode}/${tabCode}`)
    setFilterBookings({})
  }, [history, setFilterBookings, shipmentsTabCode, tabCode])

  const details = useMemo(() => {
    if (bookings && Array.isArray(bookings.data) && bookings.data.length) {
      return (
        <div>
          <div className='mt-4'>
            <span className='text-white fw-bold me-2 text-uppercase'>Guest Name:</span>
            <span>{bookings?.data[0]?.customer?.name}</span>
          </div>
          <div className='mt-4'>
            <span className='text-white fw-bold me-2 text-uppercase'>Email:</span>
            <span>{bookings?.data[0]?.email}</span>
          </div>
          <div className='mt-4'>
            <span className='text-white fw-bold me-2 text-uppercase'>Event:</span>
            <span>{bookings?.data[0]?.event?.name}</span>
          </div>

          <div className='mt-4'>
            <span className='text-white fw-bold me-2 text-uppercase'>Product:</span>
            <span>{bookings?.data[0]?.products}</span>
          </div>

          <div className='mt-4'>
            <span className='text-white fw-bold me-2 text-uppercase'>Active status:</span>
            <span>{bookings?.data[0] && <ShipmentsStatusColumn data={bookings?.data[0]} />}</span>
          </div>
        </div>
      )
    }
    return null
  }, [bookings])

  return (
    <div>
      <Toolbar>
        <NavHeader className='text-uppercase fs-5' menus={subMenus} />
      </Toolbar>

      <div className='card p-5'>
        <div className='d-flex'>
          <Button
            size='sm'
            variant='default'
            color='white'
            onClick={handleGoBack}
            uppercase={false}
          >
            <MetronicIcon iconName='Arrow-left' iconType='Navigation' />
            Back
          </Button>
        </div>
        <div className='mt-5 d-flex justify-content-between'>
          <div className='d-flex flex-column justify-content-center'>
            <p className='fs-1 fw-bolder text-white mb-0'>Shipments</p>
          </div>
        </div>
        {details}
      </div>
    </div>
  )
}
