import clsx from 'clsx'
import {ReactNode, useMemo} from 'react'
import DatePicker from 'react-date-picker'

export interface DatePickerInputProps {
  className?: string
  value: Date | null
  onChange: (value: Date | null) => void
  onBlur?: () => void
  minDate?: Date
  maxDate?: Date
  label?: string | ReactNode
  disabled?: boolean
  noMargin?: boolean
  noClear?: boolean
  calendarIcon?: JSX.Element | null
  calendarClassName?: string
  isLabelUppercase?: boolean
  inputClassName?: string
}

export const DatePickerInput = ({
  className,
  value,
  onChange,
  onBlur,
  minDate,
  maxDate,
  label,
  disabled,
  noMargin,
  noClear,
  calendarIcon,
  calendarClassName,
  isLabelUppercase,
  inputClassName,
}: DatePickerInputProps) => {
  const locale = useMemo(() => {
    if (navigator?.language) {
      return navigator.language
    }
    return 'en-US'
  }, [])

  return (
    <div className={clsx({'mb-3': !noMargin}, className)}>
      {label && (
        <label className={clsx('form-label', isLabelUppercase && 'text-uppercase')}>{label}</label>
      )}
      <>
        <DatePicker
          disabled={disabled}
          locale={locale}
          className={clsx('form-date-input form-control-solid p-1', inputClassName)}
          value={value}
          minDate={minDate}
          onChange={onChange}
          maxDate={maxDate}
          clearIcon={noClear ? null : undefined}
          calendarIcon={calendarIcon}
          calendarClassName={calendarClassName}
          onCalendarOpen={onBlur}
        />
      </>
    </div>
  )
}
