import clsx from 'clsx'
import {Link} from '../../../../components/utils/Link'
import {Paper} from '../../../../components/utils/Paper'
import {BookingModel} from '../../../../models/ems/BookingModel'
import {ShipmentsStatusColumn} from '../tables/ShipmentsTable/ShipmentsStatusColumn'

export interface ShipmentCardProps {
  data: BookingModel
  className?: string
  tabCode?: string
  shipmentsTabCode?: string
}

export const ShipmentCard = ({data, className, shipmentsTabCode, tabCode}: ShipmentCardProps) => {
  return (
    <Paper className={clsx('p-5 d-flex flex-column justify-content-between', className)} rounded>
      <div
        className=' border p-3'
        style={{
          borderColor: 'gray',
          borderWidth: '0.5px',
          borderRadius: '5px',
        }}
      >
        <div>
          <div className='d-flex flex-row'>
            <span className='text-white me-2 text-uppercase'>Booking</span>
            <span className='me-2'>
              <Link to={`/shipments-tab/${shipmentsTabCode}/${tabCode}/shipment/${data.code}`}>
                {data.code}
              </Link>
            </span>
          </div>
          <div className='d-flex flex-row'>
            <span className='text-white me-2 text-uppercase'>AWB:</span>
            <span className='me-2'>{data.awb}</span>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-end'>
          <div>
            <span className='text-white me-2 text-uppercase'>status:</span>
            <span className='me-2'>
              <ShipmentsStatusColumn data={data} />
            </span>
          </div>
        </div>
      </div>
    </Paper>
  )
}
