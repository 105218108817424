import {useCallback, useEffect, useMemo, useState} from 'react'
import {Redirect, Route, Switch, useHistory, useRouteMatch} from 'react-router-dom'
import {PaneContainer} from '../../../../components/layouts/resizeable-panes/PaneContainer/PaneContainer'
import {Pane} from '../../../../components/layouts/resizeable-panes/Pane/Pane'
import {MetronicIconButton} from '../../../../components/inputs/MetronicIconButton'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {useOnChange} from '../../../../components/hooks/useOnChange'
import PortalHeader from '../../components/Headers/PortalHeader'
import {LinksTypes} from './EventPortalDetailTickets'
import {RouteParams} from '../PortalPage'
import {DownloadVoucher, GetVoucherList} from '../../redux/CustomerPortalCRUD'
import {useDispatch} from 'react-redux'
import {actions} from '../../redux/CustomerPortalRedux'
import {Toolbar} from '../../../../components/layouts/DefaultLayout/Toolbar'
import {NavHeader} from '../../../../components/layouts/DefaultLayout/NavHeader/NavHeader'
import {AuthNavigationSectionMenuItem} from '../../../../../config/drawer'
import {usePoratlTabs} from '../../hooks/usePortalTabs'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import {PortalVoucherPage} from '../PortalVoucherPage'
import {BannerImage} from '../../components/BannerImage'

export const PortalPageVoucherDetails = () => {
  const match = useRouteMatch<RouteParams>()
  const {refreshVouchertabs, subTabs} = usePoratlTabs()
  const {tabCode, voucherTabCode, voucherCode} = match.params
  const history = useHistory()
  const dispatch = useDispatch()
  const {down} = useBreakpoint()

  const isiPad = useMemo(() => down('md'), [down])
  const [pdf, setPdf] = useState<string>()
  const [currentVoucher, setCurrentVoucher] = useState<VoucherModel>()

  const voucherLinks: LinksTypes = useMemo(() => {
    let links = [
      {
        title: 'Overview',
        to: `/voucher-tab/${voucherTabCode}/${tabCode}/voucher/${voucherCode}/overview`,
      },
    ]

    return {
      links,
    }
  }, [tabCode, voucherCode, voucherTabCode])

  const getCurrentVoucher = useCallback(async () => {
    const {data} = await GetVoucherList({
      filters: {
        search: voucherCode,
      },
    })
    if (data && data.data && data.data.length) setCurrentVoucher(data.data[0])
  }, [voucherCode])

  useEffect(() => {
    getCurrentVoucher()
  }, [getCurrentVoucher])

  useEffect(() => {
    dispatch(actions.vouchers.search())
  }, [dispatch])

  const handleSplitPaneClose = useCallback(() => {
    history.push(`/voucher-tab/${voucherTabCode}/${tabCode}`)
  }, [history, tabCode, voucherTabCode])

  const getPdfFile = useCallback(async () => {
    if (currentVoucher) {
      const {data} = await DownloadVoucher({codes: [currentVoucher.code], isView: true})
      if (data) {
        const blobToUrl = URL.createObjectURL(data)
        setPdf(blobToUrl)
      }
    }
  }, [currentVoucher])

  useEffect(() => {
    getPdfFile()
  }, [getPdfFile])

  useOnChange(voucherTabCode, () => {
    if (voucherTabCode) refreshVouchertabs()
  })

  const subMenus = useMemo(() => {
    if (subTabs) {
      const allTabs: AuthNavigationSectionMenuItem[] = []
      subTabs.forEach((tab) => {
        allTabs.push({
          title: tab.name,
          to: `/voucher-tab/${voucherTabCode}/${tab.code}`,
          isSub: true,
        })
      })
      return allTabs
    }
    return []
  }, [subTabs, voucherTabCode])

  const overview = useMemo(() => {
    return isVoucherOverViewShow(match) ? (
      <div
        style={{
          height: '70vh',
        }}
      >
        <embed type='application/pdf' src={`${pdf}#toolbar=0`} width='100%' height='100%' />
      </div>
    ) : null
  }, [match, pdf])

  const innerPages = useMemo(() => {
    return currentVoucher ? (
      <>
        <PortalHeader links={voucherLinks.links} />
        {overview}
      </>
    ) : null
  }, [currentVoucher, overview, voucherLinks.links])

  const voucherPage = useMemo(() => {
    return voucherTabCode && tabCode ? (
      <PortalVoucherPage voucherTabCode={voucherTabCode} tabCode={tabCode} />
    ) : null
  }, [tabCode, voucherTabCode])

  return (
    <>
      <Switch>
        <Route path={`${match.path}`}>
          <>
            <Toolbar>
              <NavHeader className='text-uppercase fs-5' menus={subMenus} />
            </Toolbar>
            <BannerImage />
            <PaneContainer className='flex-grow-1' direction='horizontal'>
              <Pane minimumWidth={isiPad ? undefined : 200} flexBasis={isiPad ? '0%' : '35%'}>
                {voucherPage}
              </Pane>
              <Pane minimumWidth={isiPad ? undefined : 200} flexBasis={isiPad ? '100%' : '65%'}>
                <MetronicIconButton
                  size='sm'
                  iconType='Navigation'
                  iconName='Arrow-from-left'
                  onClick={handleSplitPaneClose}
                  variant='primary'
                  tooltip='Close Pane'
                  className='mb-1'
                />
                {innerPages}
              </Pane>
            </PaneContainer>
          </>
        </Route>
        <Redirect to={match.path} />
      </Switch>
    </>
  )
}

export const isVoucherOverViewShow = (match: any): boolean => {
  return match.path.includes('/voucher/:voucherCode/overview')
}
