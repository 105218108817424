import clsx from 'clsx'
import {CSSProperties, ReactNode, useMemo} from 'react'

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

export interface SelectInputItem {
  label: string
  value: string
}

export interface SelectInputProps extends SelectProps {
  label?: string | ReactNode
  items: SelectInputItem[]
  value: string
  noMargin?: boolean
  errorMessage?: string
  errorMessageClassName?: string
  isTouched?: boolean
  endAdornment?: string
  selectStyle?: CSSProperties
  allowInvalidValue?: boolean
  labelClassName?: string
  classes?: {
    input?: string
  }
}

export const SelectInput = ({
  label,
  items,
  placeholder,
  value,
  disabled,
  noMargin,
  className,
  isTouched,
  errorMessage,
  errorMessageClassName,
  endAdornment,
  selectStyle,
  allowInvalidValue,
  labelClassName,
  classes,
  ...inputProps
}: SelectInputProps) => {
  const isValueListed = useMemo(() => {
    return allowInvalidValue || value === '' || items.some((item) => item.value === value)
  }, [allowInvalidValue, items, value])

  const displayedPlaceholder = useMemo(() => {
    if (!isValueListed) {
      return 'Loading...'
    }
    return placeholder
  }, [isValueListed, placeholder])

  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null
    }
    return errorMessage ? 'is-invalid' : 'is-valid'
  }, [errorMessage, isTouched])

  return (
    <div
      className={clsx(
        {'mb-3 ': !noMargin},
        {
          'input-group-solid position-relative': Boolean(endAdornment),
        },
        className
      )}
    >
      {label && <label className={clsx('form-label w-100', labelClassName)}>{label}</label>}
      <select
        disabled={!isValueListed || disabled}
        className={clsx('form-select form-select-solid', classes?.input, validationClassName)}
        aria-label='Select Role'
        value={value}
        style={selectStyle}
        {...inputProps}
      >
        {placeholder && <option value=''>{displayedPlaceholder}</option>}
        {items.map((item) => (
          <option key={`${item.label}${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {isTouched && errorMessage && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span className={errorMessageClassName} role='alert'>
              {errorMessage}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
