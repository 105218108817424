import {FormikContextType} from 'formik'
import moment from 'moment'
import {useEffect, useMemo} from 'react'
import {DatePickerInput, TextInput} from '../../../../../components/inputs'
import {DragDropFileInput} from '../../../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileType} from '../../../../../components/inputs/FileInput/FileInput'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikFileInputHelpers} from '../../../../../components/inputs/hooks/useFormikFileInputHelpers'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'
import {useFormikMobileNumberInputHelpers} from '../../../../../components/inputs/useFormikMobileNumberInputHelpers'
import {TicketWaiverModel} from '../../../../../models/digital-waiver/TicketWaiverModel'
import {FieldsContainerWithBorderAndLabel} from './FieldsContainerWithBorderAndLabel'
import {FieldWithLabelBox} from './FieldWithLabelBox'
import {TicketSubmitFormValues} from '../TicketSubmitWaiverFormDrawer/TicketSubmitWaiverForm'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'

interface GuardianFormProps {
  formik: FormikContextType<TicketSubmitFormValues>
  ticket?: TicketWaiverModel | null
  onView?: (file: FileInputValueCollection<ImageInputValue>) => void
}

export const GuardianForm = ({formik, ticket, onView}: GuardianFormProps) => {
  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  const mobileNumberInput = useFormikMobileNumberInputHelpers(formik)
  const imageInputHandler = useFormikFileInputHelpers(formik, FileType.IMAGE)

  const maxGuardianBirthDate = useMemo(() => {
    const minAge = ticket?.productAgeTo ? ticket.productAgeTo + 1 : 21
    return moment().subtract(minAge, 'years').toDate()
  }, [ticket?.productAgeTo])

  useEffect(() => {
    if (!formik.values.dob) {
      formik.setFieldValue('guardianDOB', maxGuardianBirthDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FieldsContainerWithBorderAndLabel legend='Guardian Details'>
      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='Name of Individual' arabicLabel='اسم الشخص'>
          <TextInput
            noMargin
            className='h-100'
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            inputWrapperClassName='h-100'
            fullWidth
            disabled={formik.isSubmitting}
            errorMessage={formik.errors.guardianName}
            isTouched={formik.touched.guardianName}
            placeholder='Enter Guardian Name'
            {...formik.getFieldProps('guardianName')}
          />
        </FieldWithLabelBox>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='Email' arabicLabel='البريد الاكتروني'>
          <TextInput
            noMargin
            className='h-100'
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            inputWrapperClassName='h-100'
            fullWidth
            disabled={formik.isSubmitting}
            errorMessage={formik.errors.guardianEmail}
            isTouched={formik.touched.guardianEmail}
            placeholder='Enter Guardian Email'
            {...formik.getFieldProps('guardianEmail')}
          />
        </FieldWithLabelBox>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox arabicLabel='Date of birth' englishLabel='تاريخ الميلاد'>
          <DatePickerInput
            className='h-100'
            inputClassName='h-100 bg-primary-dw-darker fs-3'
            calendarClassName='h-100'
            noMargin
            disabled={formik.isSubmitting}
            maxDate={maxGuardianBirthDate}
            value={formik.values.guardianDOB}
            onBlur={() => formik.setFieldTouched('guardianDOB', true)}
            {...dateInputHelpers.getProps('guardianDOB')}
          />
        </FieldWithLabelBox>

        {formik.errors.guardianDOB && formik.touched.guardianDOB && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.guardianDOB}</span>
            </div>
          </div>
        )}
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-10'>
        <FieldWithLabelBox englishLabel='Mobile NO.' arabicLabel='الهاتف المتحرك'>
          <MobileNumberInput
            noMargin
            className='h-100 px-2'
            placeholder='+971551234567'
            inputClassName='h-100 bg-primary-dw-darker fs-3 border border-0'
            disabled={formik.isSubmitting}
            {...mobileNumberInput.getFieldProps('guardianMobile')}
          />
        </FieldWithLabelBox>
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-7 mb-lg-0'>
        <DragDropFileInput
          accept={FileType.IMAGE}
          classes={{
            dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
          }}
          icon={{
            iconName: 'Plus',
            iconType: 'Navigation',
          }}
          iconClassname='p-2 rounded-circle border border-white'
          limit={1}
          thumbnailClassName='d-flex align-items-center gap-5'
          onBlur={() => formik.setFieldTouched('guardianIdFront', true)}
          onClickImageInside={() =>
            formik.values.guardianIdFront ? onView?.(formik.values.guardianIdFront) : null
          }
          placeholder='UPLOAD Guardian’s ID or Driving License Front / Passport 1st'
          disabled={formik.isSubmitting}
          placeholderArabicText=' تحميل البطاقة الشخصية أو رخصة القيادة للوصي القانوني من الأمام / جواز السفر الصفحة الاولى '
          {...imageInputHandler.getProps('guardianIdFront')}
        />
        {formik.errors.guardianIdFront && formik.touched.guardianIdFront && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.guardianIdFront}</span>
            </div>
          </div>
        )}
      </div>

      <div className='col-12 col-sm-12 col-md-12 col-lg-6 mb-0'>
        <DragDropFileInput
          accept={FileType.IMAGE}
          classes={{
            dropArea: 'bg-primary-dw-darker h-250px border border-1 border-primary',
          }}
          icon={{
            iconName: 'Plus',
            iconType: 'Navigation',
          }}
          iconClassname='p-2 rounded-circle border border-white'
          limit={1}
          thumbnailClassName='d-flex align-items-center gap-5'
          onBlur={() => formik.setFieldTouched('guardianIdBack', true)}
          onClickImageInside={() =>
            formik.values.guardianIdBack ? onView?.(formik.values.guardianIdBack) : null
          }
          placeholder='UPLOAD Guardian’s ID or Driving License Front / Passport 2nd pages'
          disabled={formik.isSubmitting}
          placeholderArabicText='  تحميل البطاقة الشخصية أو رخصة القيادة للوصي القانوني من الخلف / جواز السفر الصفحة الثانية'
          {...imageInputHandler.getProps('guardianIdBack')}
        />
        {formik.errors.guardianIdBack && formik.touched.guardianIdBack && (
          <div className='fv-plugins-message-container mb-3'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.guardianIdBack}</span>
            </div>
          </div>
        )}
      </div>
    </FieldsContainerWithBorderAndLabel>
  )
}
