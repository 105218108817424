import {PortalBookingModelCreateParams} from '../../../models/customer-portal/BookingModel'
import {
  ReservationCreateParams,
  ReservationPortalCreateParams,
  ReservationPortalModel,
} from './../../../models/ems/ReservationMedel'
import {BookingModel} from './../../../models/ems/BookingModel'
import axios, {ResponseType} from 'axios'
import {SeatMapValueObject} from '../../../components/inputs/SeatMapInput/SeatMapValue'
import {LocationModel} from '../../../models/acs/LocationModel'
import {AuthModel} from '../../../models/customer-portal/AuthModel'
import {CustomerGroupModel} from '../../../models/customer-portal/CustomerModel'
import {PortalSearchModel} from '../../../models/customer-portal/PortalSearchModel'
import {
  CustomerModel,
  CustomerModelCreateFormData,
  CustomerWithParentModel,
} from '../../../models/CustomerModel'
import {EventModel} from '../../../models/ems/EventModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
import {
  TicketPortalModel,
  TicketModelChangeSeatParams,
  TicketModelFulfillParams,
  TicketModel,
} from '../../../models/ems/TicketModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FileInputValue} from '../../../components/inputs/FileInput/FileInputValue'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {BookingDetailModel} from '../../../models/customer-portal/BookingDetailModel'
import {WidgetModel} from '../../../models/customer-portal/WidgetModel'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'
import {TabProps} from '../pages/PortalPage'
import {PortalWidgetModal} from '../../../models/customer-portal/PortalWidgetModal'
import {MYPASS_URL} from '../../../../config/env'
import {MyPassSignUpPayload} from '../hooks/useFormMyPassSignupData'

const PORTAL_AUHT_BASE_PATH = '/portal-auth'
const VOUCHERS_BASE_PATH = '/vouchers'
const TICKETS_BASE_PATH = '/tickets'
const CUSTOMER_BASE_PATH = '/customer'
const LOCATION_BASE_PATH = '/location'
const BOOKING_BASE_PATH = '/bookings'
const EVENT_BASE_PATH = '/event'
const PRODUCT_BASE_PATH = '/product'
const BOOKING_PRODUCT_BASE_PATH = '/bookings/product'
const RESERVATION_BASE_PATH = '/reservation'

export interface EmailTypeParams {
  email: string
  type: string
}

export interface WalletParams {
  walletCode: string
  eventCode: string
  responseType?: ResponseType
}

export interface CustomerCodeParams {
  customerCode: string
}

interface ReassignSeatParams {
  reason: string
  locationCode: string
  seatRow: string
  seatNo: number
}

type TicketShareType = 'whatsapp' | 'email'

export interface ShareTicketProps {
  name?: string
  type: TicketShareType
  email?: string
  mobile?: string
  codes: string[]
}

interface ShareTicketModel {
  links: [{link: string; name: string}]
}

interface DownloadTicketProps {
  codes: string[]
  isView?: boolean
}

export interface MyPassParams {
  state: string
  code: string
  id_token: string
}

// LOGIN
export const AuthLogin = (username: string, password: string) =>
  axios.post<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/login`, {username, password})
export const RequestForgottenPassword = (email: string) =>
  axios.post<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/forgot-password`, {email})
export const VerifyCustomerAuthToken = () =>
  axios.get<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/verify-token`)
export const ResetPassword = (password: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/reset`, {password})
export const validateHash = (hash: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/validate-hash`, {hash})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    `${PORTAL_AUHT_BASE_PATH}/validate-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const loginWithHash = (username: string, password: string, hash: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/login-hash`, {username, password, hash})

// CUSTOMERS
export const GetCustomerList = (filter?: FilterModel) =>
  axios.post<PortalSearchModel<CustomerGroupModel>>(`${CUSTOMER_BASE_PATH}/list`, filter)

export const GetCustomerListFlatten = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerModel> | PortalSearchModel<CustomerModel>>(
    `${CUSTOMER_BASE_PATH}/list`,
    filter
  )

export const GetCustomerByEmailAndType = (payload: EmailTypeParams) =>
  axios.post<CustomerModel>(`${CUSTOMER_BASE_PATH}/email`, payload)

export const AddExistedCustomer = (payload: CustomerCodeParams) =>
  axios.post(`${CUSTOMER_BASE_PATH}/add`, payload)

export const PostCustomerFiles = async (data: FileInputValue[], customerCode: string) => {
  const formData = new FormData()
  data.forEach((file) => {
    const document = file.getFile()
    if (document) {
      formData.append('documents', document)
    }
  })
  await axios.put(`${CUSTOMER_BASE_PATH}/documents/upload/${customerCode}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const DeleteCustomerFiles = async (fileCodes: string[], customerCode: string) => {
  await axios.delete(`${CUSTOMER_BASE_PATH}/documents/${customerCode}`, {data: {codes: fileCodes}})
}

export const DeleteCustomer = (customerCode: string[] | string) =>
  axios.delete(`${CUSTOMER_BASE_PATH}/`, {
    data: {codes: Array.isArray(customerCode) ? customerCode : [customerCode]},
  })
export const GetCustomerByCode = (code: string) =>
  axios.get<CustomerWithParentModel>(`${CUSTOMER_BASE_PATH}/${code}`)

export const PostCustomer = (data: CustomerModelCreateFormData) =>
  axios.post(`${CUSTOMER_BASE_PATH}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const PutCustomer = (data: CustomerModelCreateFormData, customerCode: string) =>
  axios.put(`${CUSTOMER_BASE_PATH}/${customerCode}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const GetCustomerProductCatergory = (filter: FilterModel) =>
  axios.post<ProductCategoryModel[]>(`${CUSTOMER_BASE_PATH}/product-categories`, filter)

export const GetVoucherTabs = (filter: FilterModel) =>
  axios.post<TabProps[]>(`${VOUCHERS_BASE_PATH}/tabs`, {...filter.filters})

export const GetTicketProductCategoryTabs = (filter: FilterModel) =>
  axios.post<TabProps[]>(`${TICKETS_BASE_PATH}/tabs/`, {...filter.filters})

// TICKETS

export const GetTicketList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${TICKETS_BASE_PATH}/list`, filter)

export const ChangeTicketSeat = (ticketCode: string, payload: TicketModelChangeSeatParams) =>
  axios.put<Blob>(`${TICKETS_BASE_PATH}/${ticketCode}/change-seat`, payload, {
    responseType: 'blob',
  })

export const GetTicketByCode = (code: string) =>
  axios.get<TicketModel>(`${TICKETS_BASE_PATH}/${code}`)

export const DownloadTicket = ({codes, isView}: DownloadTicketProps) =>
  axios.post<Blob>(`${TICKETS_BASE_PATH}/download`, {codes, isView}, {responseType: 'blob'})

export const PrintTicket = (ticketCode: string) =>
  axios.get<Blob>(`/print/ticket/${ticketCode}`, {responseType: 'blob'})

export const DownloadVoucher = ({codes, isView}: DownloadTicketProps) =>
  axios.post<Blob>(`${VOUCHERS_BASE_PATH}/download`, {codes, isView}, {responseType: 'blob'})

export const PrintVoucher = (voucher: string) =>
  axios.get<Blob>(`/print/voucher/${voucher}`, {responseType: 'blob'})

export const CancelTicket = (ticketCode: string[], reason: string) =>
  axios.post<unknown>(`${TICKETS_BASE_PATH}/cancel`, {reason, codes: ticketCode})

export const FulfillTicket = (data: TicketModelFulfillParams) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${TICKETS_BASE_PATH}/fulfill`, data)

export const FulfillNonSeated = (bookingProductCode: string) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${TICKETS_BASE_PATH}/fulfill/non-seated`, {
    productCode: bookingProductCode,
  })

export const ShareTicket = (payload: ShareTicketProps) =>
  axios.post<ShareTicketModel>(`${TICKETS_BASE_PATH}/share`, payload)

export const ShareSeatMap = (
  productCode: string,
  sharingCustomerCode: string,
  customerShareTo: string,
  seats: SeatMapValueObject,
  type: string
) =>
  axios.post(`${TICKETS_BASE_PATH}/share`, {
    seats,
    productCode,
    sharingCustomerCode,
    customerCode: customerShareTo,
    type,
  })

export const PutReassignSeat = (payload: ReassignSeatParams, ticketCode: string) =>
  axios.put(`${TICKETS_BASE_PATH}/${ticketCode}`, payload)

// VOUCHER

export const GetVoucherList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VoucherModel>>(`${VOUCHERS_BASE_PATH}/list`, filter)

export const SearchVouchers = (filter?: FilterModel) =>
  axios.post(`${VOUCHERS_BASE_PATH}/list`, filter)

export const CancelVoucher = (voucherCode: string[], reason: string) =>
  axios.post<unknown>(`${VOUCHERS_BASE_PATH}/cancel`, {reason, codes: voucherCode})
export const DisableVoucher = (voucherCode: string[]) =>
  axios.post<unknown>(`${VOUCHERS_BASE_PATH}/disable`, {codes: voucherCode})

export const FulfillNonSeatedVoucher = (bookingProductCode: string) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${VOUCHERS_BASE_PATH}/fulfill`, {
    bookingProductCode,
  })
export const ShareVoucher = (payload: ShareTicketProps) =>
  axios.post<ShareTicketModel>(`${VOUCHERS_BASE_PATH}/share`, payload)

export const GetvailableSeatMaps = (
  locationCode: string,
  productCode: string,
  eventCode: string,
  customerCode: string
) =>
  axios.post(`/location/${locationCode}/availability`, {
    productCode,
    eventCode,
    customerCode,
  })

export const GetSeatsByLocationCode = (locationCode: string) =>
  axios.get<LocationModel>(`${LOCATION_BASE_PATH}/${locationCode}`)

export const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`${EVENT_BASE_PATH}/list`, filter)

export const GetEventByCode = (code: string) => axios.get<EventModel>(`${EVENT_BASE_PATH}/${code}`)

export const GetEventsPortal = () => axios.get<EventModel[]>(`${EVENT_BASE_PATH}`)

export const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`${PRODUCT_BASE_PATH}/list`, filter)

export const GetProductsBySlug = (filter?: FilterModel) =>
  axios.post<ProductModel[]>(`${CUSTOMER_BASE_PATH}${PRODUCT_BASE_PATH}`, filter)

export const GetLocationsBySlug = (filter?: FilterModel) =>
  axios.post<LocationModel[]>(`${CUSTOMER_BASE_PATH}${LOCATION_BASE_PATH}`, filter)

export const GetProductsBookingByEvent = (eventCode: string) =>
  axios.get<ProductModel[]>(`${PRODUCT_BASE_PATH}/${eventCode}/list`)

export const GetISOCountries = () => axios.get<ISOCountryModel[]>(`${CUSTOMER_BASE_PATH}/country`)

export const GetVoucherTemplateList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VoucherModel>>(`${VOUCHERS_BASE_PATH}/template/list`, filter)

// BOOKING
export const GetBookingList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingModel>>(`${BOOKING_BASE_PATH}/list`, filter)
export const GetBookingByCode = (code: string) =>
  axios.get<BookingModel>(`${BOOKING_BASE_PATH}/${code}`)
export const PostBooking = (data: PortalBookingModelCreateParams) =>
  axios.post(BOOKING_BASE_PATH, data)

export const PutBooking = (data: PortalBookingModelCreateParams, bookingCode: string) =>
  axios.put(`${BOOKING_BASE_PATH}/${bookingCode}`, data)
export const CancelBooking = (bookingCodes: string[], reason: string) =>
  axios.post<unknown>(`${BOOKING_BASE_PATH}/cancel`, {reason, codes: bookingCodes})

export const GetBookingProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingModel>>(`${BOOKING_PRODUCT_BASE_PATH}`, filter)

export const GetBookingDetail = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingDetailModel>>('/bookings/detail', filter)

// RESERVATION

export const GetReservationList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationPortalModel>>(`${RESERVATION_BASE_PATH}/list`, filter)

export const GetReservationByCode = (code: string) =>
  axios.get<ReservationPortalModel>(`${RESERVATION_BASE_PATH}/${code}`)

export const PostReservation = (data: ReservationPortalCreateParams) =>
  axios.post(RESERVATION_BASE_PATH, data)

export const PutReservation = (data: ReservationCreateParams, reservationCode: string) =>
  axios.put(`${RESERVATION_BASE_PATH}/${reservationCode}`, data)
export const releaseReservation = (ReservationCodes: string[], reason: string) =>
  axios.post<unknown>(`${RESERVATION_BASE_PATH}/release`, {reason, codes: ReservationCodes})

export const GetProductsReservationByEvent = (eventCode: string) =>
  axios.get<ProductModel[]>(`/product${RESERVATION_BASE_PATH}/${eventCode}`)

export const GetLocationsReservationByEvent = (eventCode: string) =>
  axios.get<LocationModel[]>(`/location${RESERVATION_BASE_PATH}/${eventCode}`)

export const GetAvailablReservationSeatMap = (locationCode: string, eventCode: string) =>
  axios.post(`/location/${locationCode}/reservation-availability`, {
    eventCode,
  })

export const GetReservationDetail = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingDetailModel>>('/reservation/details', filter)

// WIDGET

export const GetWalletQrCode = (walletCode: string) =>
  axios.get(`${TICKETS_BASE_PATH}/add-to-wallet/${walletCode}`)

export const PostAddToWallet = ({eventCode, walletCode, responseType}: WalletParams) =>
  axios.post(
    `${TICKETS_BASE_PATH}/add-to-wallet/${walletCode}`,
    {
      eventCode,
    },
    {responseType: responseType || undefined}
  )

export const GetVoucherWalletQrCode = (walletCode: string) =>
  axios.get(`${VOUCHERS_BASE_PATH}/add-to-wallet/${walletCode}`)

export const PostVoucherAddToWallet = ({eventCode, walletCode, responseType}: WalletParams) =>
  axios.post(
    `${VOUCHERS_BASE_PATH}/add-to-wallet/${walletCode}`,
    {
      eventCode,
    },
    {responseType: responseType || undefined}
  )

// WALLET

export const GetWidgets = (widgetCodes: (keyof WidgetModel)[], eventCode: string) =>
  axios.post<WidgetModel>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
  })

export const GetPoratlWidgets = (widgetCodes: (keyof PortalWidgetModal)[], eventCode: string) =>
  axios.post<PortalWidgetModal>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
  })

export const MyPassAuth = async (response: MyPassParams) => {
  return await axios.create().post(`${MYPASS_URL}/auth`, response)
}

export const MyPassExistEmail = async (email: string) => {
  return await axios
    .create()
    .get(`https://apis.farahexperiences.com/v1/identity/checkIfDuplicateEmail?email=${email}`)
}

export const MyPassSignup = async (response: MyPassSignUpPayload, pagestate: any) => {
  return await axios
    .create()
    .post(
      `https://apis.farahexperiences.com/v1/identity/findcreateusersB2C?tenantid=YMCB2C&lang=en&source=ipad&domain=${MYPASS_URL}/auth&pagestate=${pagestate}`,
      response
    )
}

export const GetCountries = async () => await axios.get(`/country`)
