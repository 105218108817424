import {useMemo} from 'react'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {PortalTicketsValuesProps} from '../../../../models/customer-portal/PortalWidgetModal'

interface DashboardProgressbarProps {
  WIDGETCP02: PortalTicketsValuesProps
}

export const DashboardProgressbar = ({WIDGETCP02}: DashboardProgressbarProps) => {
  const {down} = useBreakpoint()

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  return (
    <div className='col-12 px-3 mx-0'>
      <div className='d-flex gap-3 flex-wrap mt-3 '>
        <div
          className='flex-grow-1 position-relative bg-secondary-cp-dark'
          style={{
            height: isMobile ? '50px' : '70px',
          }}
        >
          <div
            className='bg-primary-cp-dark h-100'
            style={{
              width: `${WIDGETCP02.total ? (WIDGETCP02.shared * 100) / WIDGETCP02.total : 0}%`,
            }}
          ></div>
          {WIDGETCP02.shared > 0 && (
            <>
              <div
                className='text-white position-absolute top-50 start-25 translate-middle-y fs-2 ff-admm-bold'
                style={{
                  color: '#072529',
                }}
              >
                {`SHARED ${WIDGETCP02.shared ? WIDGETCP02.shared : 0}`}
              </div>
            </>
          )}
        </div>

        <div className='mx-0 text-start d-flex flex-column justify-content-center'>
          <div className='text-white ff-admm-bold fs-1 text-uppercase'>{`Total ${
            WIDGETCP02.total ? WIDGETCP02.total : 0
          }`}</div>
          <div className='text-white ff-admm-bold fs-5 text-uppercase'>{`REMAINING ${
            WIDGETCP02.shared && WIDGETCP02.total ? WIDGETCP02.total - WIDGETCP02.shared : 0
          }`}</div>
        </div>
      </div>
    </div>
  )
}
