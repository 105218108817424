import clsx from 'clsx'
import {Group} from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import Pie, {PieArcDatum} from '@visx/shape/lib/shapes/Pie'
import {useTooltip, useTooltipInPortal, defaultStyles} from '@visx/tooltip'
import {ReactNode, useCallback, useMemo, useRef} from 'react'
import {
  PieChartDatum,
  PieChartSegment,
} from '../../../../components/charts/PieChart/PieChartSegment'
import styles from '../../../../components/charts/PieChart/PieChart.module.scss'
import {TooltipContextProvider} from '../../../../components/charts/TooltipContext/TooltipContextProvider'
import {ColorLegend, ColorLegendItem} from '../../../../components/charts/ColorLegend/ColorLegend'
import {useBreakpoint} from '../../../../components/hooks/useBreakpoint'
import {useDefaultColorScalePreset} from '../../../../components/charts/hooks/useDefaultColorScalePreset'

export interface PieChartProps {
  datum: PieChartDatum[]
  tooltip?: ReactNode
}

export const PieChart = ({datum, tooltip}: PieChartProps) => {
  const tooltipPosition = useRef({x: 0, y: 0})
  const {containerRef, containerBounds, TooltipInPortal} = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  })

  const {down} = useBreakpoint()
  const isMobile = useMemo(() => {
    return down('xs')
  }, [down])

  const {showTooltip, tooltipData, hideTooltip, tooltipLeft, tooltipTop, tooltipOpen} =
    useTooltip<string>()

  const domain = useMemo(() => datum.map((datum) => datum.key), [datum])

  const getColor = useDefaultColorScalePreset({domain})

  const getDatumColor = useCallback(
    (datum: PieArcDatum<PieChartDatum>) => getColor(datum.data.key),
    [getColor]
  )

  const handleContainerMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const clientX = e.clientX
      const clientY = e.clientY
      const containerX = clientX - containerBounds.left
      const containerY = clientY - containerBounds.top
      tooltipPosition.current.x = containerX
      tooltipPosition.current.y = containerY
    },
    [containerBounds.left, containerBounds.top]
  )

  const handlePieSegmentMouseMove = useCallback(
    (e: unknown, datum: PieArcDatum<PieChartDatum>) => {
      showTooltip({
        tooltipData: String(datum.data.key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  const legends = useMemo((): ColorLegendItem[] => {
    return datum.map((datum) => ({
      color: getColor(datum.key),
      label: datum.label,
      key: String(datum.key),
    }))
  }, [datum, getColor])

  const handleLegendMouseMove = useCallback(
    (e: unknown, key: string | number) => {
      showTooltip({
        tooltipData: String(key),
        tooltipLeft: tooltipPosition.current.x,
        tooltipTop: tooltipPosition.current.y,
      })
    },
    [showTooltip]
  )

  return (
    <div
      className={clsx('w-100 d-flex flex-wrap', styles.root)}
      style={{
        height: '92%',
      }}
      ref={containerRef}
      onMouseMove={handleContainerMouseMove}
      onMouseOut={hideTooltip}
    >
      <div
        className='position-relative overflow-auto'
        style={{
          width: isMobile ? '100%' : '45%',
          height: isMobile ? '150px' : '95%',
        }}
      >
        <ColorLegend
          className={styles.legends}
          legends={legends}
          onMouseLeave={hideTooltip}
          onMouseMove={handleLegendMouseMove}
          classes={{
            label: 'text-secondary text-uppercase',
          }}
        />
      </div>
      <div
        style={{
          width: isMobile ? '100%' : '55%',
          height: isMobile ? '200px' : '95%',
          marginTop: isMobile ? '20px' : '',
        }}
      >
        <ParentSize>
          {({width, height}) => {
            const innerWidth = width
            const innerHeight = height
            const radius = Math.min(innerWidth, innerHeight) / 2
            const centerY = innerHeight / 2
            const centerX = innerWidth / 2

            return (
              <>
                <svg width={width} height={height}>
                  <Group top={centerY} left={centerX}>
                    <Pie
                      data={datum}
                      pieValue={pieValueAccessor}
                      outerRadius={radius}
                      cornerRadius={0}
                      innerRadius={radius / 8}
                      padAngle={0.03}
                    >
                      {(pie) => (
                        <PieChartSegment
                          {...pie}
                          animate
                          getColor={getDatumColor}
                          onMouseMove={handlePieSegmentMouseMove}
                          isHideText
                        />
                      )}
                    </Pie>
                  </Group>
                </svg>
              </>
            )
          }}
        </ParentSize>
      </div>
      <TooltipContextProvider value={{key: tooltipData}}>
        {tooltipOpen && (
          <TooltipInPortal
            left={tooltipLeft}
            top={tooltipTop}
            style={{...defaultStyles, backgroundColor: '#198996'}}
          >
            {tooltip}
          </TooltipInPortal>
        )}
      </TooltipContextProvider>
    </div>
  )
}

const pieValueAccessor = (datum: PieChartDatum) => {
  return datum.value
}
