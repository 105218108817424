import clsx from 'clsx'
import {CUSTOMER_TICKET_MAX_SHARE} from '../../../../../../config/env'
import {ButtonSize} from '../../../../../components/inputs/Button'
import {TableSelectionAction} from '../../../../../components/tables/actions/TableSelectionAction'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {Spinner} from '../../../../../components/utils/Spinner'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {idExtractor} from '../../../../../utils/idExtractor'
import {isTicketCancel} from '../../../hooks/useTicketTableActions'

export interface TicketTableSelectionActionsProps {
  items: TicketPortalModel[]
  onCancel?: (data: TicketPortalModel[]) => void
  onDownload?: (data: TicketPortalModel[]) => void
  onShareByEmail?: (data: TicketPortalModel[]) => void
  onShareByWhatsApp?: (data: TicketPortalModel[]) => void
  selected: TableRowId[]
  isLoading?: boolean
  size?: ButtonSize
  className?: string
}

export const TicketTableSelectionActions = ({
  items,
  onCancel,
  onDownload,
  onShareByEmail,
  onShareByWhatsApp,
  selected,
  isLoading,
  size,
  className,
}: TicketTableSelectionActionsProps) => {
  // const downloadableItems = useMemo(() => {
  //   return items.slice(0, 50)
  // }, [items])

  return (
    <>
      {onCancel && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onCancel}
          selected={selected}
          items={items}
          variant='primary'
          filter={isTicketCancel}
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Cancel Tickets
        </TableSelectionAction>
      )}

      {onDownload && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onDownload}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          {isLoading && <Spinner className='me-5' size='lg' />} Download Tickets
        </TableSelectionAction>
      )}

      {onShareByEmail && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByEmail}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by email
        </TableSelectionAction>
      )}

      {onShareByWhatsApp && (
        <TableSelectionAction
          idExtractor={idExtractor}
          onClick={onShareByWhatsApp}
          selected={selected}
          items={items}
          variant='primary'
          limitCount={CUSTOMER_TICKET_MAX_SHARE}
          disabled={isLoading}
          className={clsx('text-white', className)}
          size={size}
        >
          Share by whatsApp
        </TableSelectionAction>
      )}
    </>
  )
}
