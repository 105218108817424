import {useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {SelectInputItem} from '../../../components/inputs/SelectInput'
import {TableRowId} from '../../../components/tables/TableRow'
import {FilterModel} from '../../../models/FilterModel'
import {EVENT_CODE} from '../../../../config/env'
import {TicketPortalTable} from '../components/tables/TicketTable/TicketPortalTable'
import {actions} from '../redux/CustomerPortalRedux'
import {useOnChange} from '../../../components/hooks/useOnChange'
import {TicketCardGrid} from '../components/TicketCard/TicketCardGrid'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {FilterProductInput} from '../components/tables/AdvancedFilter/FilterProductInput'
import {FilterLocationInput} from '../components/tables/AdvancedFilter/FilterLocationInput'
import {TextInput} from '../../../components/inputs'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {FilterSeatNumberFromTo} from '../components/tables/AdvancedFilter/FilterSeatNumberFromTo'
import {MultiSelectToggleFilterInput} from '../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import moment from 'moment'
import {ProductCategoryModel} from '../../../models/ems/ProductCategoryModel'
import {RootState} from '../../../../setup'

interface PortalTicketPageProps {
  productCategoryCode: string
  productCategorySlug: string
  tabCode: string
  refreshCallBack?: () => void
}

export const PortalTicketPage = ({
  productCategoryCode,
  tabCode,
  productCategorySlug,
  refreshCallBack,
}: PortalTicketPageProps) => {
  const {down} = useBreakpoint()
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const {setFilter: setFilterTickets, filters: ticketFilters} =
    useEntityFilter('customer-portal-ticket')
  const dispatch = useDispatch()

  const isMobile = useMemo(() => {
    return down('sm')
  }, [down])

  const menuesRDX: ProductCategoryModel[] = useSelector<RootState>(
    (state) => state.customerPortalTabs.topTabs,
    shallowEqual
  ) as ProductCategoryModel[]
  const refreshDebounced = useDebounce(500)
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const ticketFilterMemo = useMemo(() => ticketFilters || {}, [ticketFilters])
  const refresh = useCallback(() => {
    return dispatch(actions.tickets.search())
  }, [dispatch])

  const handleTableRefresh = useCallback(() => {
    refreshCallBack?.()
    refreshDebounced(() => refresh())
  }, [refreshCallBack, refreshDebounced, refresh])

  const onFilterTicketHandler = useCallback(
    (filter: FilterModel) => {
      setFilterTickets({
        ...ticketFilters,
        ...filter,
        filters: {
          ...filter?.filters,
        },
      })
      refreshDebounced(() => refresh())
    },
    [setFilterTickets, ticketFilters, refreshDebounced, refresh]
  )

  const isGCCSPP = useMemo(() => {
    if (menuesRDX) {
      const found = menuesRDX.find(
        (item) =>
          item.code === productCategoryCode &&
          item.slug &&
          [
            'GC',
            'GCC',
            'SPP',
            'GC & SPP',
            'GCC & SPP',
            'Super Park Pass',
            'super park pass',
            'SUPER PARK PASS',
            'Golden Circle',
            'Golden circle',
            'golden circle',
            'Golden Circle Concert',
            'GOLDEN CIRCLE',
            'GOLDEN CIRCLE CONCERT',
          ].includes(item.slug)
      )
      if (found) return true
      else return false
    }
    return false
  }, [menuesRDX, productCategoryCode])

  const initialFilters = useMemo((): FilterModel => {
    return {
      filters: {
        statusPortal: tabCode,
        productCategorySlug,
        event: EVENT_CODE,
        productType: 'e-ticket',
      },
      groupBy: tabCode === 'active' ? 'batchId' : undefined,
      sortField: tabCode === 'active' ? 'batchId' : '',
      sortOrder: 'ASC',
      limit: isMobile ? 10 : 12,
    }
  }, [isMobile, productCategorySlug, tabCode])

  useOnChange(tabCode, () => {
    setFilterTickets({
      filters: {
        statusPortal: tabCode,
        productCategorySlug,
        event: EVENT_CODE,
        productType: 'e-ticket',
      },
      groupBy: !isGCCSPP && tabCode === 'active' ? 'batchId' : undefined,
      sortField: !isGCCSPP && tabCode === 'active' ? 'batchId' : undefined,
      sortOrder: 'ASC',
      limit: isMobile ? 10 : 12,
    })
    refreshDebounced(() => refresh())
  })

  const handleSeatRowChange = useCallback(
    (e) => {
      onFilterTicketHandler({filters: {...ticketFilters?.filters, seatRow: e.target.value}})
    },
    [onFilterTicketHandler, ticketFilters?.filters]
  )

  const handleSearchReservation = useCallback(
    (e) => {
      onFilterTicketHandler({
        filters: {...ticketFilters?.filters, search: '', bookingReservationNo: e.target.value},
      })
    },
    [onFilterTicketHandler, ticketFilters?.filters]
  )

  const handleSeatNumberFrom = useCallback(
    (value?: number) => {
      onFilterTicketHandler({
        filters: {...ticketFilters?.filters, seatFrom: value ? value.toString() : undefined},
      })
    },
    [onFilterTicketHandler, ticketFilters?.filters]
  )

  const handleSeatNumberTo = useCallback(
    (value?: number) => {
      onFilterTicketHandler({
        filters: {...ticketFilters?.filters, seatTo: value ? value.toString() : undefined},
      })
    },
    [onFilterTicketHandler, ticketFilters?.filters]
  )

  const seatFromValue = useMemo(() => {
    return parseInt(ticketFilters?.filters?.seatFrom?.toString() || '', 10)
  }, [ticketFilters?.filters?.seatFrom])

  const seatToValue = useMemo(() => {
    return parseInt(ticketFilters?.filters?.seatTo?.toString() || '', 10)
  }, [ticketFilters?.filters?.seatTo])

  const advancedFilters = useMemo(() => {
    return (
      <div className='d-flex flex-wrap flex-row w-100 gap-3'>
        <div className='w-200px'>
          <FilterProductInput
            classes={{
              input: 'search-cp-field',
            }}
            noMargin
            placeholder='Select product'
            label='Product'
            field='productOrParentProduct'
            filter={ticketFilters}
            productCategorySlug={productCategorySlug}
            icon={{
              iconType: 'Food',
              iconName: 'Bread',
              color: 'white',
            }}
          />
        </div>
        <div className='w-200px'>
          <FilterLocationInput
            classes={{
              input: 'search-cp-field',
            }}
            noMargin
            placeholder='Select section'
            label='Section'
            field='locationCode'
            filter={ticketFilters}
            productCode={ticketFilters?.filters?.productOrParentProduct}
            productCategorySlug={productCategorySlug}
            icon={{
              iconType: 'Design',
              iconName: 'Union',
              color: 'white',
            }}
          />
        </div>

        <MultiSelectToggleFilterInput
          endAdornment={<MetronicIcon iconType='Home' iconName='Clock' size='md' color='white' />}
          field='day'
          items={TICKET_DAYS}
          label='Day'
          className='min-w-200px'
          isLabelUpperCase
          inputClassName='search-cp-field'
        />
        <div className='w-150px'>
          <TextInput
            fullWidth
            label='Seat Row'
            className='filter-table-search-input mw-100'
            noMargin
            placeholder='Seat Row'
            value={ticketFilters?.filters?.seatRow?.toString() || ''}
            onChange={handleSeatRowChange}
            inputWrapperClassName='search-cp-field'
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='Home' iconName='Chair2' />
            }
          />
        </div>
        <div>
          <FilterSeatNumberFromTo
            from={seatFromValue}
            to={seatToValue}
            setSeatNumberFrom={(value) => handleSeatNumberFrom(value)}
            setSeatNumberTo={(value) => handleSeatNumberTo(value)}
            min={0}
            max={Infinity}
            style={{
              width: '110px',
            }}
            inputWrapperClassName='search-cp-field'
          />
        </div>

        <MultiSelectToggleFilterInput
          endAdornment={
            <MetronicIcon size='md' color='white' iconType='Text' iconName='Align-justify' />
          }
          field='statusActivity'
          items={TICKET_STATUS}
          label='Activity Status'
          className='min-w-100px'
          isLabelUpperCase
          inputClassName='search-cp-field'
        />
        <div className='w-250px'>
          <TextInput
            fullWidth
            label='Reservation'
            className='filter-table-search-input mw-100'
            noMargin
            placeholder='Search by reservation No'
            value={ticketFilters?.filters?.bookingReservationNo?.toString() || ''}
            onChange={handleSearchReservation}
            inputWrapperClassName='search-cp-field'
            endAdornment={
              <MetronicIcon size='md' color='white' iconType='General' iconName='Search' />
            }
          />
        </div>
      </div>
    )
  }, [
    handleSearchReservation,
    handleSeatNumberFrom,
    handleSeatNumberTo,
    handleSeatRowChange,
    productCategorySlug,
    seatFromValue,
    seatToValue,
    ticketFilters,
  ])

  useOnChange(tickets, () => {
    if (tickets && !Array.isArray(tickets)) {
      const firstRow = Object.keys(tickets.data)[0]
      setExpandedGroups([firstRow])
    }
  })

  return (
    <>
      {isMobile ? (
        <div className='d-sm-block d-xs-block d-md-none'>
          {productCategoryCode && (
            <TicketCardGrid
              className='d-md-none'
              onRefresh={refresh}
              data={tickets}
              filters={ticketFilterMemo}
              onFilter={onFilterTicketHandler}
              tabCode={tabCode}
              productCategoryCode={productCategoryCode}
              productCategorySlug={productCategorySlug}
            />
          )}
        </div>
      ) : (
        <div className='d-none d-md-block'>
          {productCategoryCode && (
            <TicketPortalTable
              expandedGroups={expandedGroups}
              initialFilters={initialFilters}
              onExpandedGroupChange={setExpandedGroups}
              filters={ticketFilterMemo}
              data={tickets}
              tabCode={tabCode}
              onFilter={onFilterTicketHandler}
              onRefresh={handleTableRefresh}
              advancedFilters={advancedFilters}
              productCategoryCode={productCategoryCode}
            />
          )}
        </div>
      )}
    </>
  )
}

const TICKET_STATUS: SelectInputItem[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
]

const TICKET_DAYS: SelectInputItem[] = [
  {
    label: 'Thursday',
    value: moment('17-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Friday',
    value: moment('18-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Saturday',
    value: moment('19-11-2022', 'DD-MM-YYYY').toISOString(),
  },
  {
    label: 'Sunday',
    value: moment('20-11-2022', 'DD-MM-YYYY').toISOString(),
  },
]

export interface SelectGroupByButtons extends SelectInputItem {
  icon?: string
}
