import {Redirect, Route, Switch} from 'react-router-dom'
import {useCustomerAuth} from '../../modules/customer-portal/hooks/useCustomerAuth'
import {ForgotPassword} from '../../modules/customer-portal/components/forms/ForgotPassword'
import {useMemo} from 'react'
import PortalPage from '../../modules/customer-portal/pages/PortalPage'
import {PortalPageTicketDetails} from '../../modules/customer-portal/pages/sub-pages/PortalPageTicketDetails'
import {PortalPageVoucherDetails} from '../../modules/customer-portal/pages/sub-pages/PortalPageVoucherDetails'
import {PortalPageShipmentsDetails} from '../../modules/customer-portal/pages/sub-pages/PortalPageShipmentsDetails'
import {PortalTicketDetailsCard} from '../../modules/customer-portal/pages/sub-pages/PortalTicketDetailsCard'
import {PortalVoucherDetailsCard} from '../../modules/customer-portal/pages/sub-pages/PortalVoucherDetailsCard'
import {PortalShipmentsDetailsCard} from '../../modules/customer-portal/pages/sub-pages/PortalShipmentsDetailsCard'
import {PortaldashboardPage} from '../../modules/customer-portal/pages/PortalDashboardPage'
import {PortalUserGuidePage} from '../../modules/customer-portal/pages/PortalUserGuidePage'

export const PrivateRoutes = () => {
  const auth = useCustomerAuth()

  const redirectRoutes = useMemo(() => {
    let link = '/error/404'
    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }
  return (
    <Switch>
      <Route path='/dashboard'>
        <PortaldashboardPage />
      </Route>
      <Route path='/product-category/:productCategoryCode/:tabCode/ticket/:ticketCode/seat'>
        <PortalPageTicketDetails />
      </Route>
      <Route path='/product-category/:productCategoryCode/:tabCode/ticket/:ticketCode/overview'>
        <PortalPageTicketDetails />
      </Route>

      <Route path='/product-category/:productCategoryCode/:tabCode/ticket/:ticketCode'>
        <PortalTicketDetailsCard />
      </Route>

      <Route path='/product-category/:productCategoryCode/:tabCode'>
        <PortalPage />
      </Route>
      <Route path='/product-category/:productCategoryCode'>
        <PortalPage />
      </Route>

      <Route path='/product-category'>
        <PortalPage />
      </Route>
      <Route path='/voucher-tab/:voucherTabCode/:tabCode/voucher/:voucherCode/overview'>
        <PortalPageVoucherDetails />
      </Route>
      <Route path='/voucher-tab/:voucherTabCode/:tabCode/voucher/:voucherCode'>
        <PortalVoucherDetailsCard />
      </Route>
      <Route path='/voucher-tab/:voucherTabCode/:tabCode'>
        <PortalPage />
      </Route>
      <Route path='/voucher-tab/:voucherTabCode'>
        <PortalPage />
      </Route>
      <Route path='/voucher-tab'>
        <PortalPage />
      </Route>

      <Route path='/shipments-tab/:shipmentsTabCode/:tabCode/shipment/:shipmentCode/ticket'>
        <PortalPageShipmentsDetails />
      </Route>

      <Route path='/shipments-tab/:shipmentsTabCode/:tabCode/shipment/:shipmentCode'>
        <PortalShipmentsDetailsCard />
      </Route>
      <Route path='/shipments-tab/:shipmentsTabCode/:tabCode'>
        <PortalPage />
      </Route>
      <Route path='/shipments-tab/:shipmentsTabCode'>
        <PortalPage />
      </Route>
      <Route path='/shipments-tab'>
        <PortalPage />
      </Route>

      <Route path='/user-guide'>
        <PortalUserGuidePage />
      </Route>

      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect to='/product-category' />
      <Redirect to='/voucher-tab' />
      <Redirect to='/shipments-tab' />
      <Redirect to='/dashboard' />
      {redirectRoutes}
    </Switch>
  )
}
